import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowBack, IoIosLock } from "react-icons/io";
import axios from "axios";
import RankTableData from "../Components/RankTableData";

function Rank() {
  const [rank, setRank] = useState([]);

  const getRank = () => {
    axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PUBLIC + "rank",
    })
      .then((res) => {
        console.log(res.data.Success);
        setRank(res.data.Success);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getRank();
  }, []);

  const [currentRank, setCurrentRank] = useState(0);

  const nextRank = () => {
    setCurrentRank((prevRank) => (prevRank + 1) % rank.length);
  };

  const prevRank = () => {
    setCurrentRank((prevRank) => (prevRank - 1 + rank.length) % rank.length);
  };


  return (
    <>
      <div className={`flex flex-col items-center mt-5`}>
        <div className="w-2/3 relative bg-colorWhitesmoke rounded-lg shadow-neumorphic1  py-3 px-2">
          <button
            className="absolute bottom-0 top-0 left-0 mt-2 ml-2"
            onClick={prevRank}
          >
            <IoIosArrowBack size={35} />
          </button>
          <div className="grid grid-cols-6 gap-2">
            <div className="col-span-4 mx-7">
              <div className="text-2xl font-bold">Rank & Rewards</div>

              <h1>Team_Business : {rank[currentRank]?.Team_Business}</h1>
              <h1>Package_Required : {rank[currentRank]?.Package_Required}</h1>
              <h1>Direct_Required : {rank[currentRank]?.Direct_Required}</h1>
              <h1>Reward : {rank[currentRank]?.Reward}</h1>
            </div>
            <div
              className={`col-span-2 rounded-2xl w-full p-2  shadow-neumorphic relative ${
                [1, 2, 3].includes(currentRank)
                  ? "bg-textColor"
                  : "bg-neonPinkShadow/20"
              }`}
            >
              <div className="flex justify-center">
                <img
                  src={rank[currentRank]?.Image}
                  alt={`rank-${currentRank + 1}`}
                  className="h-20"
                />
              </div>
              <div className="flex justify-between items-center">
                <h1>{rank[currentRank]?.Rank}</h1>
                <p>{rank[currentRank]?.ID}</p>
              </div>
              {[1, 2, 3].includes(currentRank) && (
                <div className="absolute inset-0 flex items-center justify-center bg-blur backdrop-blur-sm bg-white/30 ">
                  <IoIosLock size={50} />
                </div>
              )}
            </div>
          </div>
          <button
            className="absolute top-0 bottom-0 right-0 mt-2 mr-2 "
            onClick={nextRank}
          >
            <IoIosArrowForward size={35} />
          </button>
        </div>
      </div>
      <RankTableData/>
    </>
  );
}

export default Rank;


