import React, { useState } from "react";
import Botmainpagecopy from "./Botmainpagecopy";
import BotmainpagecopyTwo from "./BotmainpagecopyTwo";

function SwitchinBot() {
  const [activeView, setActiveView] = useState("grid");

  const handleGridClick = () => {
    setActiveView("grid");
  };

  const handleListClick = () => {
    setActiveView("list");
  };

  

  return (
    <div> 
      {/* <div className="flex justify-end items-center gap-3 container">
        <IoGrid
          size={20}
          onClick={handleGridClick}
          style={{ cursor: "pointer" }}
        />
        <FaList
          size={20}
          onClick={handleListClick}
          style={{ cursor: "pointer" }}
        />
      </div> */}

      {activeView === "grid" ? <Botmainpagecopy data={{handleGridClick , handleListClick}} /> : <BotmainpagecopyTwo data={{handleGridClick , handleListClick}} />}
    </div>
  );
}

export default SwitchinBot;
