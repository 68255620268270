import React, { useEffect, useState } from "react";
import Binance from "../assets/images/binance.png";
import Logo from "../assets/black.png";
import Lottie from "react-lottie";
import * as animationData from "../assets/Loitte/exchange.json";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { Checkbox } from "antd";
import img1 from "../assets/Api/BINANCEFUTURES.png";
import img2 from "../assets/Api/BINANCESPOT.png";
import img5 from "../assets/Api/BYBITFUTURES.png";
import img6 from "../assets/Api/BYBITSPOT.png";
import img3 from "../assets/Api/KUCOINFUTURES.png";
import img4 from "../assets/Api/KUCOINSPOT.png";
import img11 from "../assets/coins/BINANCEFUTURES.png";
import img21 from "../assets/coins/BINANCESPOT.png";
import img51 from "../assets/coins/BYBITFUTURES.png";
import img61 from "../assets/coins/BYBITSPOT.png";
import img31 from "../assets/coins/KUCOINFUTURES.png";
import img41 from "../assets/coins/KUCOINSPOT.png";
import ConnectContext from "./ConnectContext";
import { useContext } from "react";


function ConnectionLoder() {
  const location = useLocation();
  const connectloctState = location.state;
  const [connect, setConnect] = useState(connectloctState);
  const [showAlert, setShowAlert] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
   const [exchangeData, setExchangeData] = useState(null);
  const [exchImg, setExchImg] = useState(null);
  const { parentState, updateParentState } = useContext(ConnectContext);

  const navigate = useNavigate();

  const handleSuccessButtonClick = (text) => {
    Swal.fire({
      title: "Success!",
      text: text,
      icon: "success",
      confirmButtonText: "OK",
      customClass: {
        confirmButton: "custom-ok-button-class", // Define your custom class
      },
      buttonsStyling: false,
    }).then(() => {
      navigate("/dashboard/botmain");
    });
  };

  const exchange = location.state ? location.state.exchangeName || "" : "";
  const types = location.state ? location.state.exchangeTypes || "" : "";

  useEffect(() => { 
    // if (location.state && location.state.exchangeData) {
    //   const { exchangeData: exchangeDataFromLocation, filteredImages } =
    //     location.state;

    //   const exchangeImg = exchangeDataFromLocation.filter(
    //     (item) => item.img === filteredImages
    //   );

    //   if (exchangeImg.length > 0) {
    //     setExchImg(exchangeImg[0]);
    //   }

    //   setExchangeData(exchangeDataFromLocation);
    // }
    setExchImg(location?.state?.logo);
  }, [location]);

  if (location.state) {
    const destinationImageName = "imgSrc2"; // Replace this with the desired key
    const imgnewValue = location.state[destinationImageName] || ""; // Access the value dynamically
  }

  

  const imgSrc2 = location.state ? location.state.imgSrc2 || "" : "";

  const ViewBot_info = async (e) => {
    updateParentState("Clicked");

    const jwt = localStorage.getItem("jwt");
    const postdata = {
      ...connect,
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_API + "create_api",
      data: postdata,
    }).then((response) => {
      console.log("ViewBot_info ==", response);
      const res = response?.data;
      if (res?.Success) {
        toast(res?.Success);
        handleSuccessButtonClick(res?.Success)
      } else {
        toast.error(res?.Error);
        window.location.href = "/dashboard/api_settings/Connectionfailed";
      }
    })
      .catch((err) => {
        console.log("err", err)
        toast.error(err.response.data.Error);
      });
  };

   const handleCheckboxChange = (e) => {
     setIsChecked(e.target.checked); // Update the isChecked state when the checkbox changes
   };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const images = [
    { name: "BINANCEFUTURES", img: img1, imgnew: img11 },
    { name: "BINANCESPOT", img: img2, imgnew: img21 },
    { name: "KUCOINSPOT", img: img4, imgnew: img31 },
    { name: "KUCOINFUTURES", img: img3, imgnew: img41 },
    { name: "BYBITFUTURES", img: img5, imgnew: img51 },
    { name: "BYBITSPOT", img: img6, imgnew: img61 },
  ];

  const targetNames =
    "BINANCEFUTURES,BINANCESPOT,KUCOINSPOT,KUCOINFUTURES,BYBITFUTURES,BYBITSPOT"; // Replace with the names you want to filter by
  const targetNamesArray = targetNames.split(",");

  // Find the selected image based on the exchange name

  // Find the selected image based on the exchange name
  const selectedImage = images.find((item) => item.name === exchange);
const projectName = process.env.REACT_APP_DEMO || "Default Project";

  let imgnewImage = null;

  if (selectedImage && targetNamesArray.includes(exchange)) {
    imgnewImage = selectedImage.imgnew;
  }
  return (
    <>
      <div className="flex items-center justify-center mt-6">
        <div className="text-center w-full xl:w-2/3 mt-3  bg-white md:bg-gradient-to-r  from-colorPrimary  to-colorPrimary text-black  shadow-neumorphic shadow-black rounded-3xl p-3">
          <h4 className="text-lg font-bold">Connect to Binance</h4>

          <div className="flex justify-evenly  mt-0 md:mt-10">
            <div className="md:w-600 w-full flex items-center md:items-start">
              <img
                src={Logo}
                className="md:w-[100px] w-16 h-16 md:h-[100px] lg:mx-20  bg-colorPrimary md:bg-white rounded-full p-4 shadow-neumorphic"
              />
              {/* <div className="md:w-[100px] flex justify-center items-center w-16 h-16 md:h-[100px] lg:mx-20  bg-colorPrimary md:bg-white rounded-full p-4 shadow-neumorphic">
                <div className="text-center  font-semibold">Company logo</div>
              </div> */}
              <Lottie
                options={defaultOptions}
                height={100}
                width={100}
                className=" bg-transparent  w-[77%] h-[36%] m-0"
              />
              <img
                src={exchImg}
                className="md:w-[100px] w-16 h-16 md:h-[100px] lg:mx-20   bg-colorPrimary md:bg-white rounded-full p-2 shadow-neumorphic"
              />
            </div>
          </div>
          <div className="flex justify-center  p-3 text-white mt-2 md:mt-10 my-6">
            <div className="text-start bg-colorPrimary text-black rounded-lg  shadow-neumorphic  shadow-black py-3 px-7">
              <p className="text-lg mt-2 font-semibold">
                Before Proceeding complate the following mandatory steps :
              </p>
              <li>
                Whitelist all the specified IP addresses when connecting to the
                API.
              </li>
              <li>
                Ensure you have a minimum balance of 15 USDT in your{" "}
                {connect.exchange_type} trading account or sub-account.
                {/* Have at least 15 USDT in your{" "}
                {connect.exchange_type} trading account / sub- account */}
              </li>

              <li>
                Activate {connect.exchange_type} mode when creating an API on
                the exchange.
              </li>
              <li>
                When creating the API on the exchange, do not enable withdrawal
                options.
              </li>
            </div>
          </div>
          <Checkbox
            id="tradeCheckbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          >
            I intend to use this API to enable {projectName} to execute trades
            based on its decisions.
          </Checkbox>

          <div className="flex justify-center me-6">
            <button
              type="button"
              className="px-6 pt-1 pb-1 m-3 neumorphic rounded-full font-semibold hover:bg-white hover:text-black hover:border-neonPurple hover:border-2 active text-white"
            >
              <Link to="/dashboard/api_settings">Cancel</Link>
            </button>
            <button
              id="connectButton"
              type="button"
              disabled={!isChecked}
              className={`px-6 pt-1 pb-1 m-3 neumorphic text-white rounded-full font-semibold active hover:bg-white hover:text-black hover:border-neonPurple hover:border-2 ${
                !isChecked ? "cursor-not-allowed" : ""
              }`}
              onClick={ViewBot_info}
            >
              Connect
            </button>

            {/* <button onClick={() => handleSuccessButtonClick('hurrryyyyyy !!!!!!')}>Show Success Alert</button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ConnectionLoder;
