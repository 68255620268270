import React, { useState } from "react";
import { Drawer } from "antd";
import { PiInfo } from "react-icons/pi";

const DrawerTooltip = (props) => {
  
  console.log("props ==>>" , props)

  const [drawerState, setDrawerState] = useState({
    visible: false,
  });

  const toggleDrawer = () => {
    setDrawerState({
      visible: !drawerState.visible,
    });
  };
console.log(props)
  const { tooltips } = props;

  return (
    <div>
      <div onClick={() => toggleDrawer()}>
        <PiInfo
          size={15}
          className={`cursor-help hover:scale-110 transition-all transition-5s ${
            drawerState.visible ? "bg-colorPrimary" : ""
          }`}
        />
      </div>

      <Drawer
        placement="right"
        onClose={() => toggleDrawer()}
        visible={drawerState.visible}
        width={300}
        headerStyle={{ display: "none" }}
        style={{ border: "0" }}
      >
        <div className="bg-colorPrimary h-auto min-h-full text-center">
          <h2 className="text-lg font-bold text-black bg-colorPrimary py-2">
            Help
          </h2>
          <div>
            {tooltips.map((tooltip, index) => (
              <div
                key={index}
                className={`text-black border-2 mb-6 pb-4 pt-3 rounded-2xl mx-2 ${
                  drawerState.visible
                    ? "bg-colorSecondaryDark text-white"
                    : "bg-colorPrimary"
                } shadow-neumorphic`}
              >
                <h4 className="text-xl font-normal mb-2 pl-2 text-primaryColor text-left">
                  {tooltip.title}:
                </h4>
                <p
                  className={`p-2 rounded-md text-black border-2  mx-2 border-neonPurple  leading-4 text-justify text-xs ${
                    drawerState.visible
                      ? "shadow-neumorphic bg-white"
                      : ""
                  } `}
                >
                  {tooltip.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default DrawerTooltip;
