import React from 'react';
import {  Tour } from 'antd';
const TourComponent = ({setOpen,steps,open}) => {

  return (
    <>
     <div className='hidden lg:block tour-button-md-up'>
     <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
     </div>
    </>
  );
};
export default TourComponent;