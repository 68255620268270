import React, { useEffect, useRef, useState } from 'react'
import { PiHandCoinsFill } from "react-icons/pi";
import { FaMedal } from "react-icons/fa6";
import { SiLevelsdotfyi } from "react-icons/si";
import { TbLayoutGridAdd } from "react-icons/tb";
import { FaFilterCircleDollar } from "react-icons/fa6";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { Col, Modal} from 'antd';
import banner from '../assets/dashboard/banner.webp';
import bannerLogo from '../assets/dashboard/Mainicon.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CardContent } from "@material-ui/core";
import copy from "copy-to-clipboard";
import { Carousel } from 'antd';
import { FaCopy} from "react-icons/fa";
import { IoIosLock } from "react-icons/io";
import { Player } from '@lordicon/react';
import DirectReferralTable from '../Components/DirectReferralTable';
import MatrixqualDashboard from './MatixqualDashboard';
import TourComponent from '../Layout/TourComponent';
import Male from "../assets/Profile/ProfileMale/1.webp";
import Female from "../assets/Profile/Profilefemale/1.webp";
import Bot from "../assets/Profile/Profilebull/1.webp";
import { FcApproval } from "react-icons/fc";
import { HandleSession } from '../Components/HandleSession';


const ICON = require('../assets/Loitte/referral.json');
const MainDashboard = () => {
    const [waltData, setWaltData] = useState()
    const [rankData, setRankData] = useState([])
    const [curentRank, setCurentRank] = useState()
    const [selctRank, setSelctRank] = useState([])
    const [profile1, setProfile1] = useState("");
    const [level, setLevel] = useState([]);
    const [current, setCurrent] = useState("");
    const [currentLevel, setCurrentLevel] = useState([])
    const [income, setIncome] = useState([]);
    const [matching, setMatching] = useState({});
    const [currentMarix, setCurrentMarix] = useState()
    const [packData, setPackData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedImage, setSelectedImage] = useState(-1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [avatarStatus, setAvatarStatus] = useState("");

    const jwt = localStorage.getItem("jwt");

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const [opentour, setOpentour] = useState(false);

    const steps = [
        {
            title: 'Step 1',
            description:<div> 
                <div><span className='font-semibold'>Sponsor Name: </span>This section highlights the name of the sponsor who sponsors you.
            </div>
            <div><span className='font-semibold'>Referral Link:</span> This section includes a unique URL assigned to the referrer.
            </div>
            </div>,
            target: () => ref1.current,
        },
        {
            title: 'Step 2',
            description: <div>
                <div>A set of wallets where users can monitor their entire earnings and holdings.</div>
            </div>,
            target: () => ref2.current,
        },
        {
            title: 'Step 3',
            description: <div>
                <div>Users can monitor their Active Package in this area, which includes their active package details, the price of the bot, and the validity period in days.</div>
            </div>,
            target: () => ref3.current,
        },
        {
            title: 'Step 4',
            description: <div>
                <div>This section displays User’s current rank, qualified level, matching income, and active matrix.</div>
            </div>,
            target: () => ref4.current,
        },
        {
            title: 'Step 5',
            description: <div>
                <div>This section presents the downline rank details.</div>
            </div>,
            target: () => ref5.current,
        },
        {
            title: 'Step 6',
            description: <div>
                <div>This section presents details of directly referred users and their achievements.</div>
            </div>,
            target: () => ref6.current,
        },
    ];

    const itemsPerPage = 5;

    const navigate = useNavigate()
    const playerRef = useRef(null);

    useEffect(() => {
        playerRef.current?.playFromBeginning();
    }, [])

    useEffect(() => {
        const data = rankData?.filter(item => item?.Rank == curentRank?.Rank)
        setSelctRank(data)
    }, [rankData, curentRank])

    useEffect(() => {
        const getlevel = level?.filter(item => item?.Level === current?.Level)
        setCurrentLevel(getlevel)
    }, [level, current])
    
    const walletCard = [
        {
            title: "Deposit Wallet",
            price: waltData?.Wallet_Balance,
            icon: <PiHandCoinsFill size={55} className=' text-black' />
        },
        {
            title: "Rank and Reward",
            price: waltData?.Rank_Reward_Balance,
            icon: <FaMedal size={40} className=' text-black' />
        },
        {
            title: "Level Earning",
            price: waltData?.Level_Earning_Balance,
            icon: <SiLevelsdotfyi size={40} className=' text-black' />
        },
        {
            title: "Matrix Earning",
            price: waltData?.Matrix_Earning_Balance,
            icon: <TbLayoutGridAdd size={50} className=' text-black' />
        },
        {
            title: "Matching Income",
            price: waltData?.Matching_Income_Balance,
            icon: <FaFilterCircleDollar size={40} className=' text-black' />
        },
        {
            title: "Withdraw",
            price: waltData?.Withdrawal_Balance,
            icon: <FaMoneyCheckDollar size={50} className=' text-black' />
        },
    ]

    const [downLine, setDownLine] = useState("")

    const getDownline = () => {
        const jwt = localStorage.getItem('jwt')
        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_PATH + '/rank/total_users_per_rank',
            data: { jwt: jwt }
        }).then(res => {
            setDownLine(res?.data?.Success)
        }).catch(err => {
            console.log("getDownline", err)
            HandleSession(err)
        })
    }

    useEffect(() => {
        getDownline()
    }, [])

    const bgBanner = `url(${banner})`;

    const walletData = () => {
        const jwt = localStorage.getItem('jwt')

        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_Wallet + 'wallet_details',
            data: { jwt: jwt }
        }).then(res => {
            setWaltData(res?.data?.Success)
        }).catch(err => {
            console.log(err)
        })
    }

    const currentRank = () => {
        const jwt = localStorage.getItem('jwt')

        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_PATH + '/rank/current_rank',
            data: { jwt: jwt }
        }).then(res => {
            setCurentRank(res?.data?.Success)
        }).catch(err => {
            console.log(err)
        })
    }

    const rank = async () => {
        const jwt = localStorage.getItem('jwt')

        const response = await axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_PUBLIC + 'rank',

        }).then(res => {
            setTimeout(() => {
                setRankData(res?.data?.Success)
            })
        }).catch(err => {
            console.log(err)
        })
    }


    useEffect(() => {
        currentRank()
        rank()
        walletData()
    }, [])

    const View_Profile1 = async () => {
        const postdata = {
            jwt: jwt,
        };

        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
            data: postdata,
        })
            .then(async (res) => {
                const response = await res;
                if (response) {
                    setProfile1(response?.data?.Success);
                } else {
                    toast.error(response?.data?.Error);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.Error);
            });
    };

    useEffect(() => {
        View_Profile1();
    }, []);


    const View_Level = async (checked) => {
        const postdata = {
        };

        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_PUBLIC + "level",
            data: postdata,
        })
            .then(async (response) => {
                const res = await response.data;
                setLevel(res.Success);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    useEffect(() => {
        View_Level();
    }, []);

    const View_CurrentLevel = async () => {
        const jwt = localStorage.getItem("jwt");
        const postdata = {
            jwt: jwt,
        };

        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_Level + "current_level",
            data: postdata,
        })
            .then(async (response) => {
                const res = await response.data;
                setCurrent(res.Success);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const View_QualiLevel = async () => {
        const jwt = localStorage.getItem("jwt");
        const postdata = {
            jwt: jwt,
        };

        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_Level + "qualified_level",
            data: postdata,
        })
            .then(async (response) => {
                const res = await response.data;
                // setQuali(res.Success.Level_Earning_Qual);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };


    useEffect(() => {
        View_Level();
        View_CurrentLevel();
        View_QualiLevel();
    }, []);


    const View_Income = async () => {
        try {
            const postdata = {
                project: process.env.REACT_APP_PROJECT,
            };

            const response = await axios.post(
                process.env.REACT_APP_API_PUBLIC + "matching_income",
                postdata,
                { headers: { "Content-Type": "application/json" } }
            );
            setIncome(response.data.Success);
        } catch (err) {
            console.log("err", err);
        }
    };

    const View_QualiMatching = async () => {
        try {
            const jwt = localStorage.getItem("jwt");
            const postdata = { jwt };

            const response = await axios.post(
                process.env.REACT_APP_API_Matching + "latest_qualified_matching_income",
                postdata,
                { headers: { "Content-Type": "application/json" } }
            );

            const qualificationLevel = response.data.Success;
            setMatching({ [qualificationLevel]: true }); 
        } catch (err) {
            console.log("err", err);
        }
    };

    

    useEffect(() => {
        const filteredMatrix = income.filter(item => matching[item.Level]);
        setCurrentMarix(filteredMatrix);
    }, [income,currentMarix]);
    

    useEffect(() => {
        View_Income();
        // View_QualiMatching();
    }, []);


    const [matrix, setMatrix] = useState([]);
    const [qualiMatrix, setQualiMatrix] = useState({});


    const View_Matrix = async () => {
        const postdata = {
            project: process.env.REACT_APP_PROJECT,
        };

        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_PUBLIC + "matrix",
            data: postdata,
        })
            .then(async (response) => {
                const res = await response.data;
                setMatrix(res.Success);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const View_QualiMatrix = async (matrixName) => {
        try {
            const jwt = localStorage.getItem("jwt");
            const postdata = {
                jwt: jwt,
                // matrix_name: matrixName,
            };

            const response = await axios.post(
                // "https://prod.advilite.info/matrix/all_matrix_qualified",
                process.env.REACT_APP_API_Matrix + "all_matrix_qualified",
                postdata,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            setQualiMatrix(response.data.Success);
            //  setMatrixData(response.data.Success);
            console.log(response.data.Success, "response.data.Success")
        } catch (err) {
            console.log("err", err);
        }
    };

    const getSubscriptionPAckage = () => {
        const jwt = localStorage.getItem('jwt')
        const postData = {
            jwt: jwt,
            page_no: 1,
            page_size: 20
        }

        const response = axios({
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API_SUB + "subscription_status",
            data: postData
        }).then(res => {

            if (typeof res?.data?.Success == "object") {
                setPackData(res?.data?.Success)
            }

        }).catch(err => {
            console.log(err)
        })
    }

    const [dirctUser, setDirctUSer] = useState([])


    const DirectUser = () => {
        const jwt = localStorage.getItem('jwt')
        setLoading(true)
        const response = axios({
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API_Level + 'direct_user_details',
            data: { jwt: jwt }
        }).then(res => {
            setDirctUSer(res?.data?.Success)
            setLoading(false)
        }).catch(err => {
            console.log("DirectUser", err)
            setLoading(false)
        })
    }

    useEffect(() => {
        View_Matrix();
        setTimeout(() => {
            getSubscriptionPAckage()
        }, 1000)
        setTimeout(() => {
            DirectUser()
        }, 1000)
    }, []);

    useEffect(() => {
        View_QualiMatching();
        matrix.forEach((item) => {
            View_QualiMatrix(item.Matrix_Name);
        });
    }, [matrix]);


    const RankMatrix = [
        {
            title: "Current Rank",
            Achivment: `Reward - $${selctRank[0]?.Reward}`,
            img: `/images/DashBoard/Rank/${curentRank?.Rank.replace(' ', "_") + ".webp"}`,
            position: curentRank?.Rank
        },
        {
            title: "Level Qualified",
            Achivment: `Level - ${currentLevel[0]?.Level}`,
            img: `/images/DashBoard/Level/${currentLevel[0]?.Level + ".webp"}`,
            // label: "reward 4%",
            // position: `PACKAGE Qualified ${currentLevel[0]?.Subscription_Required}`
        },
        {
            title: "Matching Income",
            Achivment: `Level - ${currentMarix?.Level}`,
            img: `/images/DashBoard/Rank/${currentMarix?.Rank_Required?.replace(' ', "_") + ".webp"}`,
            labelTwo: `BOUNS - ${currentMarix?.Percent}%`,
            position: currentMarix?.Rank_Required
        },
    ]
console.log("currentMarix",currentMarix)
    const [copyText, setCopyText] = useState("");
    const handleCopyText = (e) => {
        setCopyText(e.target.value);
    };

    const copyToClipboard = () => {
        copy(
            window.location.origin +
            "/signup?ref_by=" +
            (profile1?.Username ? profile1?.Username : "")
        );
        toast(
            `You have copied "${window.location.origin +
            "/signup?ref_by=" +
            (profile1?.Username ? profile1?.Username : "")
            }"`
        );
    };

    const carouselRef = React.createRef();
    const next = () => {
        carouselRef.current.next();
    };

    const prev = () => {
        carouselRef.current.prev();
    };

    const allMatricesFalse = Object.values(qualiMatrix).every(status => status === false);

    const trueMatrices = matrix.filter(item => qualiMatrix[item.Matrix_Name]);

    const images = [
        { name: "Male", url: Male },
        { name: "Female", url: Female },
        { name: "Bot", url: Bot },
    ];

    const View_Avatar = async (imageName) => {
    
        const postdata = {
            jwt: jwt,
        };
        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_avatar",
            data: postdata,
        })
            .then(async (res) => {
                const response = await res.data.Success;
                setAvatarStatus(response); 
                if(response === ""){
                    setIsModalOpen(true)
                }
            })
            .catch((err) => {
                console.log(err?.response?.data?.Error);
            });
    };


    const View_ProfileImage = async (imageName) => {
        setSelectedImage(imageName);

        const jwt = localStorage.getItem("jwt");
        const postdata = {
            jwt: jwt,
            avatar: imageName,
        };
        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_PATH_USER_ODECENT + "set_avatar",
            data: postdata,
        })
            .then(async (res) => {
                const response = await res;
                if (response) {
                    toast(response?.data?.Success);
                    setIsModalOpen(false);
                    setOpentour(true);
                } else {
                    toast.error(response?.data?.Error);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.Error);
            });
    };

    useEffect(() => {
        View_Avatar()
    }, [avatarStatus]);
 
    return (
        <>
            {/* {avatarStatus === "" && ( */}
                <Modal title="Select Avatar" footer={false} open={isModalOpen} className=''>
                    <div className=''>
                        <div className='flex '>
                            {images?.map((image, index) => (
                                <Col span={8} key={index}>
                                    <div className="image-option">
                                        <img
                                            className="images-avatar"
                                            src={image.url}
                                            alt={`Image ${index + 1}`}
                                            onClick={() => View_ProfileImage(image.name)}
                                        />
                                        {selectedImage === image.name && ( // Show the check icon only if the current image is selected
                                            <div className='absolute top-0 right-0 text-green-500'>
                                                <FcApproval size={24} />
                                            </div>
                                        )}
                                        <div className='text-center'>{image.name}</div>
                                    </div>
                                </Col>
                            ))}
                        </div>
                    </div>
                    {/* <div className='flex justify-end items-center mt-5'>
                    <button onClick={handleOk} className='hover:bg-neonPurple hover:text-white border rounded-lg px-5 py-1'>Submit</button>
                </div> */}
                </Modal>
            {/* )} */}

            <div className='px-2 md:px-8'>
                <div className='container grid grid-cols-1 lg:grid-cols-12 lg:gap-6'>
                    <div className=' col-span-8'>
                        <div
                            className={`h-[200px] hidden md:block rounded-2xl`}
                            style={{
                                backgroundImage: bgBanner,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            }}
                        >
                            <div className='hidden md:flex justify-between px-14'>
                                <div className=' flex flex-col justify-between py-6'>
                                    <div>
                                        <p className='font-italian capitalize text-sm font-medium text-white' >your dashboard, your rules</p>
                                        <p className=' uppercase text-2xl font-medium text-white'>Enter the world of automated trading</p>
                                    </div>
                                    <div>
                                        <button onClick={() => { navigate("/dashboard/subscriptions") }} className=' text-colorDark bg-white py-1 px-4 rounded-xl font-medium'>
                                            Active Bot Now
                                        </button>
                                    </div>
                                </div>
                                <div className=' flex items-center h-[200px]'>
                                    <img src={bannerLogo} alt="" className=' h-[180px]' />
                                </div>
                            </div>
                        </div>

                        <div ref={ref2} className='h-auto md:h-[200px] border-[1px] border-black rounded-2xl mb-2 md:mb-0 md:mt-6 md:flex gap-8 py-1 md:py-3 px-4 '>
                            <div className=''>
                                <p className=' py-2 font-semibold'>Wallets:</p>
                            </div>
                            <div className='w-full md:w-11/12 grid grid-cols-2 md:grid-cols-3 gap-3'>
                                {
                                    walletCard.map(item => {
                                        return (
                                            <div className='block md:flex items-center gap-6 px-1 md:px-5 bg-gradient-to-r from-[rgba(142,90,251,0.15)] via-[rgba(53,47,234,0.15)] to-[rgba(46,206,253,0.15)] rounded-xl'>
                                                <div>
                                                    <p className='hidden md:block'>{item?.icon}</p>
                                                </div>
                                                <div className='block text-center md:text-left py-2 md:py-0'>
                                                    <p className='text-xs md:text-sm font-semibold' >{item?.title}</p>
                                                    <p className=' font-medium text-neonPurpleShadow'>$ {item?.price}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>



                        <div ref={ref4} className='  grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-6 mt-6'>
                            {
                                RankMatrix.map(item => {
                                    return (
                                        <div className='flex justify-between px-7 border-[1px] border-black rounded-2xl py-4 relative' key={item.id}>
                                            <div className='flex items-center justify-center'>
                                                <div>
                                                    <p className='text-xl font-medium capitalize'>{item.title}</p>
                                                    <p className='text-base leading-5 font-medium uppercase'>{item.position}</p>
                                                    <p className='text-sm font-semibold text-colorDark uppercase'>{item.Achivment}</p>
                                                    {item?.label &&
                                                        <p className='text-sm my-4 font-semibold capitalize'>{item.label}</p>
                                                    }
                                                    {item?.labelTwo &&
                                                        <p className='border border-black text-center my-2 rounded-lg'>{item.labelTwo}</p>
                                                    }
                                                </div>
                                            </div>
                                            {(item.Achivment.includes('undefined') || item.Achivment.trim() === '') && (
                                                <div className="absolute inset-0 text-white rounded-2xl flex items-center bg-blur backdrop-blur-sm bg-black/20 justify-center">
                                                    <div className='flex flex-col font-semibold justify-center items-center text-white'>
                                                        <IoIosLock size={50} />
                                                        <p>No Current {item.title} </p>
                                                    </div>
                                                </div>
                                            )}
                                            <div className='flex justify-center items-center'>
                                                <img src={item?.img} className='h-[140px]' alt="" />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className=' border-[1px] pt-0   border-black rounded-2xl px-2 md:px-0 relative'>
                                <Carousel autoplay ref={carouselRef} dots={false} >
                                    {trueMatrices.length === 0 ? (
                                        <div className='col-span-5 flex items-center justify-center h-full md:h-[180px]'>
                                            <div className="absolute inset-0 text-white rounded-2xl flex items-center bg-blur backdrop-blur-sm bg-black/20 justify-center">
                                                <div className='flex flex-col font-semibold justify-center items-center text-white'>
                                                    <IoIosLock size={50} />
                                                    <p>No Current Matrix </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        trueMatrices.map(item => (
                                            <>
                                                <MatrixqualDashboard item={item} />

                                            </>

                                        ))
                                    )}

                                </Carousel>
                            </div>

                        </div>

                    </div>
                    <div className=' col-span-4 mt-4 md:mt-0'>
                        <div ref={ref1} className='h-auto md:h-[200px] border-[1px] border-black  rounded-2xl py-5 px-4 flex justify-between flex-col'>
                            <div>
                                <p className='text-sm font-semibold pb-1 pl-1'>Sponsor Name</p>
                                <div className=' border-[1px] border-black text-sm py-3 px-6 rounded-xl text-gray-500'>
                                    Sponsor : {profile1?.Sponsor}
                                </div>
                            </div>
                            <div>
                                <div className='flex items-center my-2 justify-between'>
                                    <p className='text-sm font-semibold pb-1 pl-1'>Referral Link</p>
                                    <Player
                                        ref={playerRef}
                                        icon={ICON}
                                    />
                                </div>
                                <div className="">
                                    <div className="">
                                        <CardContent className="copy py-0 px-0">
                                            <div className=" border-[1px] border-black text-sm py-3 px-6 rounded-xl text-gray-500 flex justify-between">
                                                <input
                                                    className="text-sm w-[200px] md:w-[350px]"
                                                    value={
                                                        window.location.origin +
                                                        "/signup?ref_by=" +
                                                        (profile1?.Username ? profile1?.Username : "")
                                                    }
                                                    onChange={handleCopyText}
                                                    color="#fff"
                                                />
                                                <button
                                                    onClick={copyToClipboard}
                                                    className="border-none hover:bg-active"
                                                >
                                                    <FaCopy className="text-neonPurple" />
                                                </button>
                                            </div>
                                        </CardContent>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div ref={ref3} className=' h-[200px] mt-6 rounded-3xl'>
                            {
                                packData?.length === 0 ? (
                                    <div className='relative h-[200px] rounded-3xl' style={{
                                        backgroundImage: `url(/images/DashBoard/Package/BOT50.webp)`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }}>
                                        <div className="absolute inset-0 text-white flex items-center bg-blur backdrop-blur-sm bg-white/20 justify-center">
                                            <div className=' flex flex-col justify-center items-center '>
                                                <IoIosLock size={50} />
                                                <p> No Packages found</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <Carousel autoplay ref={carouselRef} dots={false}>
                                        {
                                            packData.map((item, index) => {
                                                const bgImg = `/images/DashBoard/Package/${item?.Name + ".webp"}`
                                                return (
                                                    <div className='rounded-3xl' key={index}>
                                                        <div className='w-[310px] h-[200px] lg:w-[400px] xl:w-[487px] md:h-[200px] md:w-[487px] rounded-3xl'
                                                            style={{
                                                                backgroundImage: `url(${bgImg})`,
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundSize: 'cover',
                                                            }}>
                                                            <div className=' p-4 flex flex-col justify-between h-full'>
                                                                <div>
                                                                    <p className=' text-white font-medium text-xl pt-2 '>Your Active Package</p>
                                                                </div>
                                                                <div>
                                                                    <p className=' text-white font-semibold text-xl'>Bot-${(item?.Name).replace('BOT', '')}</p>
                                                                    <p className=' text-xs text-white font-light '>Validity {item?.Days_Left} Days</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>
                                )
                            }
                        </div>


                        <div ref={ref5} className=' h-[364px] border-[1px] border-black  rounded-2xl mt-6'>
                            <p className=' text-center py-1'>Downline Rank Details</p>
                            <div className=' px-4'>
                                <table className="min-w-full bg-colorTable py-3 bg-opacity-90 text-colorText rounded-2xl ">
                                    <thead className=' border-y-[1px] border-black'>
                                        <tr className="font-semibold text-sm">
                                            <th className="py-2 font-semibold text-left pl-2 text-sm text-colorSecondary bg-colorPrimaryDark w-1/2">
                                                Rank Name
                                            </th>
                                            <th className="py-2 font-semibold text-left pl-2  text-sm text-colorSecondary bg-colorPrimaryDark w-1/2">
                                                No. Of User
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            downLine && downLine?.map(item => {
                                                return (
                                                    <tr className=" mx-3 text-xs font-medium text-gray-400 border-b-[1px] border-gray-400 " >
                                                        <td className="py-1 pl-2">
                                                            <div className=" uppercase">{item?.Name}</div>
                                                        </td>
                                                        <td className="py-1 pl-2">
                                                            <div className="">{item?.Total_Users}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div >

                <div ref={ref6} className='container h-auto'>
                    <div className='pb-4 border-[1px] border-black rounded-2xl mt-2 mb-4'>
                        <div className='bg-gradient-to-r from-neonPurple via-neonPurpleShadow to-neonBlue text-white text-center text-xl py-1 font-medium rounded-t-2xl'>
                            Direct Referral
                        </div>

                        <div className=' h-[200px] overflow-y-auto'>
                            <DirectReferralTable data={{ subData: dirctUser, loading: loading }} />
                        </div>
                    </div>
                </div>

                <div className='hidden lg:block'>
                    <TourComponent setOpen={setOpentour} open={opentour} steps={steps} />
                </div>
            </div >
        </>
    )
}

export default MainDashboard