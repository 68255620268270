import React, { useEffect, useState } from 'react'
import { RiCheckboxCircleLine } from "react-icons/ri";
import { RxCrossCircled } from "react-icons/rx";
import Female from "../assets/Profile/Profilefemale/1.webp";
import Female2 from "../assets/Profile/Profilefemale/2.webp";
import Female3 from "../assets/Profile/Profilefemale/3.webp";
import Female4 from "../assets/Profile/Profilefemale/4.webp";
import Female5 from "../assets/Profile/Profilefemale/5.webp";
import Female6 from "../assets/Profile/Profilefemale/6.webp";
import Male from "../assets/Profile/ProfileMale/1.webp";
import Male2 from "../assets/Profile/ProfileMale/2.webp";
import Male3 from "../assets/Profile/ProfileMale/3.webp";
import Male4 from "../assets/Profile/ProfileMale/4.webp";
import Male5 from "../assets/Profile/ProfileMale/5.webp";
import Male6 from "../assets/Profile/ProfileMale/6.webp";
import Bot from "../assets/Profile/Profilebull/1.webp";
import Bot2 from "../assets/Profile/Profilebull/2.webp";
import Bot3 from "../assets/Profile/Profilebull/3.webp";
import Bot4 from "../assets/Profile/Profilebull/4.webp";
import Bot5 from "../assets/Profile/Profilebull/5.webp";
import Bot6 from "../assets/Profile/Profilebull/6.webp";
import { Col, Image, Modal, Row, Switch, Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Auth from './Auth';

function ProfileDetails({ View_Profile, profile, }) {

    console.log(profile?.Avatar, "Avatar");
    const jwt = localStorage.getItem("jwt");

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isUploadButtonClicked, setIsUploadButtonClicked] = useState(false);

    const handleUploadButtonClick = () => {
        setIsUploadButtonClicked(true);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const View_ProfileImage = async (image) => {
        localStorage.setItem("selectedImage", image);
        setSelectedImage(image);
        const jwt = localStorage.getItem("jwt");
        const postdata = {
            jwt: jwt,
            profile_image: image,
        };

        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_PATH_USER_ODECENT + "update_profile_image",
            data: postdata,
        })
            .then(async (res) => {
                const response = await res;
                if (response) {
                    toast(response?.data?.Success);
                    setModalVisible(false);
                    View_Profile();
                } else {
                    toast.error(response?.data?.Error);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.Error);
            });
    };

    let avatarImages = [];
    if (profile?.Avatar === "Female") {
        avatarImages = [Female, Female2, Female3, Female4, Female5, Female6];
    } else if (profile?.Avatar === "Bot") {
        avatarImages = [Bot, Bot2, Bot3, Bot4, Bot5, Bot6];
    } else if (profile?.Avatar === "Male") {
        avatarImages = [Male, Male2, Male3, Male4, Male5, Male6];
    }

    useEffect(() => {
        setSelectedImage(profile?.Profile_Image || avatarImages[0]);
    }, [profile?.Profile_Image, avatarImages]);
    useEffect(() => {
        if (profile?.Avatar === "Female") {
            setSelectedImage(Female);
        } else if (profile?.Avatar === "Bot") {
            setSelectedImage(Bot);
        } else if (profile?.Avatar === "Male") {
            setSelectedImage(Male);
        }
    }, [profile?.Avatar]);


    if (!profile) {
        return <div>Loading profile...</div>;
    }

    return (
        <div>
            <div className=" w-full">
                {" "}
                <div className=" flex-col justify-center px-2 lg:px-12  gap-x-6 gap-y-0 items-center h-full ">
                    <div className="text-3xl text-center py-5  text-neonPurpleShadow font-bold">
                        {profile.Username}
                    </div>
                    <div className="grid grid-cols-6 gap-10">
                        <div className="col-span-6 md:col-span-2 ">
                            <div className="h-[200px] md:w-[200px] w-full">
                                {profile.Profile_Image || selectedImage ? (
                                    <div className='flex justify-center items-center'>
                                        <Image
                                            width={200}
                                            src={profile.Profile_Image || selectedImage}
                                        />
                                    </div>
                                ) : (
                                    <span>No profile image available</span>
                                )}
                                <Tooltip title="Change Profile Picture">
                                    <div className='flex justify-center items-center'>
                                        <button
                                            className="py-1 mt-4  border border-[#ccc] text-sm px-5 rounded-lg"
                                            icon={<UploadOutlined />}
                                            onClick={handleUploadButtonClick}
                                        >
                                            Change Avatar
                                        </button>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>

                        <div className="col-span-6 md:col-span-4 px-10">

                            <div className="flex justify-between pt-4  items-center gap-2">
                                <div className="text-sm font-semibold ">Sponsor :</div>
                                <div className="text-sm text-colorSecondaryDark">
                                    {profile.Sponsor}
                                </div>
                            </div>
                            <div className="flex justify-between pt-4  items-center gap-2">
                                <div className="text-sm font-semibold ">Name :</div>
                                <div className="text-sm text-colorSecondaryDark">
                                    {profile.Name}
                                </div>
                            </div>
                            <div className="flex justify-between pt-4  items-center gap-2">
                                <div className="text-sm font-semibold ">EmailID :</div>
                                <div className="text-sm text-colorSecondaryDark">
                                    {profile.Email}
                                </div>
                            </div>
                            <div className="flex justify-between pt-4  items-center gap-2">
                                <div className="text-sm font-semibold ">
                                    TelegramID :
                                </div>
                                <div className="text-sm text-colorSecondaryDark">
                                    {profile.Telegram ? profile.Telegram : ""}
                                </div>
                            </div>
                            <div className="flex justify-between pt-4 items-center gap-2">
                                <div className="text-sm font-semibold">
                                    KYC Status:
                                </div>
                                {profile.KYC_Status === "COMPLETE" ? (
                                    <RiCheckboxCircleLine
                                        size={20}
                                        className="text-[green]"
                                    />
                                ) : profile.KYC_Status === "INCOMPLETE" ? (
                                    <Link to="/dashboard/KycNew">
                                        <button className="text-red-500">INCOMPLETE</button>
                                    </Link>
                                ) : (
                                    <div className="text-red-500">PENDING</div>
                                )}
                            </div>
                            <div className="flex justify-between pt-4 items-center gap-2">
                                <div className="text-sm font-semibold ">Status :</div>
                                <div className="text-sm text-colorSecondaryDark">
                                    {profile.Status ? (
                                        <RiCheckboxCircleLine
                                            size={20}
                                            className="text-[green]"
                                        />
                                    ) : (
                                        <RxCrossCircled size={20} className="text-[red]" />
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-between pt-4  items-center gap-2">
                                <div className="text-sm font-semibold ">2FA :</div>
                                <div className="text-sm text-colorSecondaryDark">
                                    <Auth profile={profile.TOTP_Activation} View_Profile={View_Profile} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal
                        visible={modalVisible}
                        onCancel={closeModal}
                        footer={null}
                        centered
                    >
                        <Row gutter={[16, 16]}>
                            {avatarImages?.map((image, index) => (
                                <Col span={8} key={index}>
                                    <div className="image-option">
                                        <img
                                            className="images-avatar"
                                            src={image}
                                            alt={`Image ${index + 1}`}
                                            onCancel={closeModal}
                                            onClick={() => View_ProfileImage(image)}
                                        />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default ProfileDetails