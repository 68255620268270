import React from 'react'

function MatixqualDashboard({item}) {
    return (
        <div className='w-full ' >
            <div className='flex items-center  justify-between relative' key={item.id}>
                <div className='grid grid-cols-5 py-1 md:py-0 items-center '>
                    <div className='col-span-3 md:col-span-2 text-start'>
                        <div className='text-xl font-medium capitalize'>Active Matrix</div>
                        <div className='text-sm font-semibold text-colorDark uppercase'>
                            Matrix {item?.ID.toString().replace("5", "A").replace("6", "B").replace("7", "C").replace("8", "D")}
                        </div>
                        <p className='text-sm font-semibold capitalize'>PACKAGE - $ {(item?.Matrix_Name ? item?.Matrix_Name : "unknown").replace('BOT', "")}</p>
                    </div>
                    <div className='hidden md:block col-span-0 md:col-span-1'></div>
                                            {/* <div className='col-span-1'>j</div> */}
                                             {/* <div className='col-span-1'>j</div> */}
                    <div className='col-span-2 text-end'>
                        <img src={`/images/DashBoard/Matrix/${item?.Image.replace("png", "webp")}`} className='h-[140px]' alt="" />
                    </div>
                    </div>
                </div>
            </div>
            )
}

            export default MatixqualDashboard