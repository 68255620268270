import React, { useEffect, useState } from "react";
import axios from "axios";
import MAtchingTrans from "./MatchingTrans";

function RankQualTrans(props) {
  const { matchingqualData, pageSize, page, totalPages, setPage, setpageSize } =
    props.data;

    const [Matching, setMatching] = useState([]);
    const [current, setCurrent] = useState(null);

    const getmatching = () => {
      axios({
        method: "POST",
        headers: {
          accept: "application/json",
        },
        url: process.env.REACT_APP_API_PUBLIC + "matching_income",
      })
        .then((res) => {
          setMatching(res.data.Success);
        })
        .catch((err) => console.log(err));
    };

    const View_Currentmatching = async () => {
      const jwt = localStorage.getItem("jwt");
      const postdata = {
        jwt: jwt,
      };

      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_Matching + "qualified_matching_income",
        data: postdata,
      })
        .then(async (response) => {
          const res = await response.data;
          setCurrent(res.Success.Matching_Income_Qual);
        })
        .catch((err) => {
          console.log("err", err);
        });
    };

    useEffect(() => {
      getmatching();
      View_Currentmatching();
    }, []);

  const filteredLevels = Matching.filter(
    (item) => current && current[`L${item.Level.replace("L", "")}`]
  );

  const sortedLevels = filteredLevels.sort(
    (a, b) =>
      parseInt(b.Level.replace("L", "")) - parseInt(a.Level.replace("L", ""))
  );

  const latestQualifiedMatchingName = sortedLevels[0]; 
 
  return (
    <>
     
      <div>
        {latestQualifiedMatchingName ? (
          <div className="flex flex-col items-center mt-5">
            <img
              src={`/images/TableFull & Final/Matching/${latestQualifiedMatchingName.Image.replace(
                "png",
                "webp"
              )}`}
              alt={`item-image-${latestQualifiedMatchingName.ID}`}
            />
          </div>
        ) : (
          <div className="text-center text-gray-500 mt-5">
            No qualified Matching Income
          </div>
        )}
      </div>
      <MAtchingTrans
        data={{
          matchingqualData: matchingqualData,
          page: page,
          pageSize: pageSize,
          totalPages: totalPages,
          setPage: setPage,
          setpageSize: setpageSize,
        }}
      />
    
    </>
  );
}

export default RankQualTrans;
