import React, { useEffect, useState } from "react";
import {
  UserOutlined,
} from "@ant-design/icons";
import { TbBrandTelegram } from "react-icons/tb";
import {
  BsRobot, BsTrophy
} from "react-icons/bs";

import {
  Menu,
  Space,
  message,
  Dropdown,
  Avatar,
} from "antd";
import { FaCopy, FaQuestion, FaWallet } from "react-icons/fa";
import { Drawer } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import Sidebar from "./Sidebar";
import axios from "axios";
import { BiLogOutCircle } from "react-icons/bi";
import { toast } from "react-toastify";
import { HiMiniWallet } from "react-icons/hi2";
import copy from "copy-to-clipboard";
import { CardContent } from "@material-ui/core";
import Paymentmodel from "../Pages/Paymentmodel";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { TbTransfer } from "react-icons/tb";
import {
  AiFillApi,
  AiFillWindows,
  AiOutlineApartment,
} from "react-icons/ai";
import {
  PiAlignBottomDuotone,
  PiChartLineUpBold,
  PiPackage,
} from "react-icons/pi";
import { RiMapPinTimeLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { GiLevelEndFlag } from "react-icons/gi";
import { SiProbot } from "react-icons/si";
import { FaBots } from "react-icons/fa6";
import logo from "../assets/whiteOdecent.png";


function Navbar({ profileUpdated, handleProfileUpdate }) {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [dashboard, setDashboard] = useState("");
  const Navigate = useNavigate();

  const location = useLocation();
  const jwt = localStorage.getItem("jwt");

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const customCloseIcon = <span style={{ color: "red" }}>X</span>;

  useEffect(() => {
    if (profileUpdated) {
      // Hit the API or do any necessary action related to navbar
      // For example, refresh navbar content
      // After handling the profile update, reset the profileUpdated state
      handleProfileUpdate(false);
    }
  }, [profileUpdated]);

  const sidebarItems = [
    {
      path: "/dashboard/mainpage",
      title: "Dashboard",
      icon: <AiFillWindows />,
    },
    // {
    //   path: "/dashboard/subscriptions",
    //   title: "Subscriptions",
    //   icon: <PiPackage />,
    // },
    {
      path: "/dashboard/subscriptions",
      title: "Subscriptions",
      icon: <PiPackage />,
    },
    {
      path: "/dashboard/wallet",
      title: "Wallet",
      icon: <HiMiniWallet />,
    },
    {
      path: "/dashboard/transactiontable",
      title: "Transactions",
      icon: <TbTransfer />,
    },
    {
      path: "/dashboard/bonus",
      title: "Achievements",
      icon: <FaWallet />,
    },
    {
      path: "/dashboard/transactionbonus",
      title: "Reward",
      icon: <BsTrophy />,
    },

    {
      // path: "/chart",
      title: "Charts",
      icon: <PiChartLineUpBold />,
      subItems: [

        {
          path: "/dashboard/levelchart",
          title: "Level Chart",
          icon: <GiLevelEndFlag />,
        },
        {
          path: "/dashboard/matrixchart",
          title: "Matrix Chart",
          icon: <AiOutlineApartment />,
        },
        // {
        //   path: "/dashboard/incomechart",
        //   title: "Income Chart",
        //   icon: <AiOutlineRise />,
        // },
      ],
    },
    {
      // path: "/bots",
      title: "Bots",
      icon: <BsRobot />,
      subItems: [
        {
          path: "/dashboard/api_settings",
          title: "API Credentials",
          icon: <AiFillApi />,
        },
        {
          path: "/dashboard/botmain",
          title: "Bots ",
          icon: <FaBots />,
        },
        {
          path: "/dashboard/activebot",
          title: "Active Bot",
          icon: <SiProbot />,
        },
        {
          path: "/dashboard/trades",
          title: "Trades",
          icon: <PiAlignBottomDuotone />,
        },
        // {
        //   path: "/dashboard/report",
        //   title: "Reports",
        //   icon: <TbAlignBoxBottomCenter />,
        // },
      ],
    },


    {
      path: "/dashboard/KycNew",
      title: "Kyc",
      icon: <IoMdCheckmarkCircleOutline />,
    },

    {
      path: "/dashboard/logs",
      title: "Timeline",
      icon: <RiMapPinTimeLine />,
    },
    // {
    //   path: "/dashboard/supportpage",
    //   title: "Support",
    //   icon: <MdOutlineSupportAgent />,
    // },
    {
      path: "/dashboard/FAQ",
      title: "FAQ",
      icon: <FaQuestion />,
    },
    {
      path: "/dashboard/profile",
      title: "Profile",
      icon: <CgProfile />,
    },
    // {
    //   path: "/dashboard/guide",
    //   title: "Guide",
    //   icon: <AiFillHeart />,
    // },
  ];

  const onClose = () => {
    console.log();
    setOpen(false);
  };

  const logout = () => {
    localStorage.clear();
    toast("Logout Successfully.");
    window.location.href = "/";
    // Navigate('/')
  };

  const items = [
    {
      label: (
        <Link to="/dashboard/profile" className="flex items-center">
          <UserOutlined className="text-lg mr-2" />
          My Profile
        </Link>
      ),
      key: "0",
    },

    {
      type: "divider",
    },
    {
      label: (
        <button
          className="w-full flex items-center justify-between rounded-lg px-2 py-1 active text-colorPrimary"
          onClick={logout}
        >
          <BiLogOutCircle size={25} className="mr-2" />
          Logout
        </button>
      ),
      key: "3",
    },
  ];

  const [profile, setProfile] = useState("");

  useEffect(() => {
    View_Profile();
  }, [profileUpdated]);

  useEffect(() => {
    setInterval(() => {
      View_Profile();
    }, 300000);
  }, [profileUpdated]);

  const View_Profile = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setProfile(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
        if (response?.data?.Error === "Session Expired") {
          localStorage.clear();
          window.location.href = "/";
          Navigate("/");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.Error === "Session Expired") {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/";
            Navigate("/");
            message.error(err?.response?.data?.Error);
          }, 100);
        }
        if (err?.response?.data?.Error === "Invalid Authentication") {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/";
            Navigate("/");
            message.error(err?.response?.data?.Error);
          }, 100);
        }
      });
  }
  

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseDrawer = () => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  };

  const telegram = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT_TELE + "generate_telegram_link",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response?.data.Success;
        window.open(res, "_blank");
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
      });
  };

  const projectName = process.env.REACT_APP_DEMO || "Default Project";
  const [copyText, setCopyText] = useState("");
  const handleCopyText = (e) => {
    setCopyText(e.target.value);
  };

  const copyToClipboard = () => {
    copy(
      window.location.origin +
      "/signup?ref_by=" +
      (profile?.Username ? profile?.Username : "")
    );
    toast(
      `You have copied "${window.location.origin +
      "/signup?ref_by=" +
      (profile?.Username ? profile?.Username : "")
      }"`
    );
  };

  const [wallet, setWallet] = useState("");

  const View_Wallet = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_Wallet + "wallet_details",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response.data;
        setWallet(res.Success);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    View_Wallet();
  }, []);


  return (
    <>
      <div>
        <nav className="bg-white z-50 h-[58.2px]  w-full shadow-sm shadow-neonPurpleShadow border-gray-200 dark:bg-white">
          <div className=" flex flex-wrap md:block md:flex-nowrap w-full  md:flex-row items-center justify-between ">
            {/* mobileview--------------->>>>>>>>>>>> */}

            <div
              className={`md:hidden flex active w-36 md:w-56 justify-center  items-center text-black   
             `}
            >
              <div className="text-lg font-bold">  <img src={logo} alt="logo" className="" /></div>
            </div>

            <div className=" md:hidden flex justify-center items-center py-1  gap-2">
              <div className="text-center mt-auto "></div>
              {!profile?.TelegramID ? (
                <div>
                  <button
                    onClick={telegram}
                    className="text-sm text-neonPurple "
                  >
                    <TbBrandTelegram
                      size={35}
                      className="text-neonPurple border-2 border-neonPurple/20 px-1 py-0 rounded-lg"
                    />
                  </button>
                </div>
              ) : null}

              <Paymentmodel />

              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <a
                  onClick={(e) => e.preventDefault()}
                  className="md:w-fit cursor-pointer mx-auto hidden items-center justify-between rounded-full px-2 py-1 bg-white border border-solid hover:bg-gray-100 text-colorSecondaryDark"
                >
                  <Space>
                    <Avatar
                      src={profile?.Profile_Image}
                      className="rounded-full w-10 h-10"
                    />
                  </Space>
                </a>
              </Dropdown>
            </div>

            <button
              data-collapse-toggle="navbar-default"
              type="button"
              onClick={showDrawer}
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            <div className=" grid grid-cols-12  w-auto min-w-fit 2xl:w-full items-center ">
              <div
                className="hidden col-span-6  md:flex justify-between items-center"
                id="navbar-default"
              >
                <div className="ml-10 text-base md:text-md lg:text-base font-semibold text-colorSecondaryDark">
                  Welcome {profile?.Username}!
                </div>
                {
                  location?.pathname !== "/dashboard/mainpage" &&
                  <div className="hidden md:flex justify-center items-center py-2 md:gap-5">
                    {" "}
                    <div className="flex justify-center items-center mx-2">
                      <CardContent className="copy py-0 px-0">
                        <div className=" flex align-center border border-[#ccc] border-dotted py-1 px-2 rounded-lg">
                          <input
                            // className={classes.copy}
                            className="text-sm w-[350px]"
                            value={
                              window.location.origin +
                              "/signup?ref_by=" +
                              (profile?.Username ? profile?.Username : "")
                            }
                            onChange={handleCopyText}
                            color="#fff"
                          />
                          <button
                            // className={classes.display}
                            onClick={copyToClipboard}
                            className="border-none hover:bg-active"
                          >
                            <FaCopy className="text-neonPurple" />
                          </button>
                        </div>
                      </CardContent>
                    </div>
                  </div>
                }

              </div>

              <div className="hidden col-span-6 md:flex justify-end items-center py-2 md:gap-3">
                <div className="text-center mt-auto "></div>
                {
                  location?.pathname !== "/dashboard/mainpage" &&
                  <>
                    <div className="ml-12 text-base md:text-md lg:text-base font-semibold text-colorSecondaryDark">
                      Sponsor: {profile?.Sponsor}
                    </div>
                    <div className="border-2 border-neonPurple rounded-lg px-2 py-1">
                      <div className="text-sm  md:text-xs lg:text-sm text-neonPurple font-semibold">
                        Deposit :{" "}
                        <span className="text-black font-normal">
                          ${" "}
                          {wallet?.Wallet_Balance}
                        </span>
                      </div>
                      {/* <p className="text-[green] text-sm md:text-xs lg:text-sm">
                    ${" "}
                    {dashboard?.Wallet_Balance?.toFixed(3).replace(
                      /\B(?=(\d{3})+(?!\d)(\.))/g,
                      ","
                    )}
                  </p> */}
                    </div>
                  </>
                }

                {/* <div className=" ">
                  <TbBrandTelegram className="text-neonPurple" /> */}
                {!profile?.TelegramID ? (
                  <div>
                    <button
                      onClick={telegram}
                      className="text-sm  text-neonPurple border-2 border-neonPurple px-2 py-1 rounded-lg"
                    >
                      <div className="flex justify-center items-center gap-1">
                        <TbBrandTelegram className="text-neonPurple" />
                        Telegram
                      </div>
                    </button>
                  </div>
                ) : null}

                {/* </div> */}
                <Paymentmodel />
                <div className="">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    trigger={["click"]}
                  >
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="md:w-fit cursor-pointer mx-auto flex items-center justify-between  px-2  bg-white   hover:bg-gray-100 text-colorSecondaryDark"
                    >
                      <Space>
                        {/* <UserOutlined />
                      Username */}

                        <Avatar
                          // width={70}
                          // height={70}
                          // value={profile.Profile_Image}
                          src={profile?.Profile_Image}
                          className="rounded-full w-10 h-10"
                        />
                      </Space>
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* Drawer component */}

        <Drawer
          title={<div className="text-sm  my-3 text-center flex-none text-white lg:flex-grow font-semibold md:hidden block">
            Welcome {profile?.Username}!
          </div>}
          headerStyle={{ background: "#001529", color: "#fff" }}
          placement="right"
          onClose={onClose}
          closeIcon={customCloseIcon}
          open={open}
          className="justify-end"
        >


          <Menu
            selectedKeys={[location?.pathname]}
            mode="vertical"
            theme="dark"
            className="w-full p-0 h-screen"
          >
            <div className="sidebar-wrapper">
              <div className="sidebar text-white">
                <ul>
                  {sidebarItems?.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item  ${location.pathname === item.path ? "active" : ""
                        }`}
                    >
                      <Link to={item.path} onClick={handleCloseDrawer}>
                        <div
                          onClick={() => {
                            if (item.subItems) {
                              if (openSubMenu === index) {
                                setOpenSubMenu(null);
                                // onClose();
                              } else {
                                setOpenSubMenu(index);
                              }
                            }

                            if (!item.subItems) {
                              console.log("item.subItems", item.subItems);
                              setTimeout(() => {
                                onClose();
                              }, 300);
                            }
                          }}
                          className={`items-center flex w-full justify-start px-5 ${location.pathname === item.path
                            ? `md:rounded-xl rounded-none hover:md:shadow-[0px_0px_2px_#eeeeee] md:shadow-md md md:shadow-colorPrimary/50 md:border-1 border-transparent md:hover:border-colorPrimary cursor-pointer  md:h-12 h-12 transition-all flex justify-center items-center w-full mx-auto md:bg-transparent bg-colorPrimary/30`
                            : ""
                            }`}
                        >
                          <div className="flex items-center justify-between  w-full">
                            <div className="flex items-center  justify-evenly py-3 ">
                              <span className="item-icon  text-black mb-1">
                                {item.icon}
                              </span>
                              <span className=" uppercase item-title ml-5">
                                {item.title}
                              </span>
                            </div>
                            <div>
                              {item.subItems && (
                                <span
                                  className={`arrow-icon  ${openSubMenu === index ? "open" : ""
                                    }`}
                                >
                                  {/* &#9660; */}
                                  {openSubMenu === index ? "\u25B2" : "\u25BC"}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                      {item.subItems && (
                        <ul
                          className={`sub-menu border-gradient text-center ${openSubMenu === index ? "open" : ""
                            }`}
                        >
                          {item.subItems.map((subItem, subIndex) => (
                            <li
                              key={subIndex}
                              className={`items-center flex w-full justify-center p-5 h-5 hover:text-colorPrimary cursor-pointer ${location.pathname === subItem.path
                                ? `md:rounded-xl rounded-none hover:md:shadow-[0px_0px_2px_#eeeeee] md:shadow-md md md:shadow-colorPrimary/50 md:border-1 border-transparent md:hover:border-colorPrimary cursor-pointer  md:h-12 h-12 transition-all flex  items-center w-full mx-auto md:bg-transparent bg-colorPrimary/30`
                                : ""
                                }`}
                            >
                              <Link to={subItem.path} onClick={onClose}>
                                {subItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
                <div className="footer bottom-4 fixed flex flex-col w-[80%] justify-center items-center -mt-14  text-center">
                  <div className="text-center mx-auto py-4">
                    <button
                      className="active font-semibold text-white py-5 px-4 rounded-md mx-auto"
                      onClick={logout}
                    >
                      Logout
                    </button>
                  </div>
                  <div className="text-center">
                    <p className="text-black">
                      © All right reserved @FinGenius 2024
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Menu>
        </Drawer>
      </div>
    </>
  );
}

export default Navbar;
