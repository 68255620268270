import { Link, NavLink, Navigate } from "react-router-dom";
import {
  FaAngleDown,
  FaQuestion,
  FaWallet,
} from "react-icons/fa";
import {
  AiFillApi,
  AiFillWindows,
  AiOutlineApartment,
} from "react-icons/ai";
import {  BsRobot, BsTrophy } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { Avatar, message } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { IoMdCheckmarkCircleOutline, IoMdLogOut } from "react-icons/io";
import { PiAlignBottomDuotone, PiChartLineUpBold, PiPackage } from "react-icons/pi";
import { RiMapPinTimeLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { GiLevelEndFlag } from "react-icons/gi";
import { SiProbot } from "react-icons/si";
import { FaBots } from "react-icons/fa6";
import { TbTransfer } from "react-icons/tb";
import { HiMiniWallet } from "react-icons/hi2";
import  logo  from "../assets/blackOdecent.png";



const SideBar = ({ children, openSide, setopenSide }) => {
  const [activeLink, setActiveLink] = useState();
  const toggle = () => setopenSide(!openSide);
  const [openSections, setOpenSections] = useState({
    chart: true, 
    bots: false, 
  });

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const [opentour, setOpentour] = useState(true);

  const steps = [
      {
          title: 'Wallets',
          description: 'Put your files here.',
          target: () => ref1.current,
      },
      {
          title: 'Sponsor',
          description: 'Save your changes.',
          target: () => ref2.current,
      },
      {
          title: 'Packages',
          description: 'Click to see other actions.',
          target: () => ref3.current,
      },
      {
          title: 'Archievement',
          description: 'Save your changes.',
          target: () => ref4.current,
      },
      {
          title: 'Downline Rank Deatils',
          description: 'Click to see other actions.',
          target: () => ref5.current,
      },
      {
          title: 'Direct Referral',
          description: 'Click to see other actions.',
          target: () => ref6.current,
      },
  ];


const routes = [
  {
    path: "/dashboard/mainpage",
    name: "Dashboard",
    icon: <AiFillWindows />,
  },
  // {
  //   path: "/dashboard/subscriptions",
  //   name: "Subscriptions",
  //   icon: <PiPackage />,
  // },
  {
    path: "/dashboard/subscriptions",
    name: "Subscriptions",
    icon: <PiPackage />,
  },
  {
    path: "/dashboard/wallet",
    name: "Wallet",
    icon: <HiMiniWallet />,
  },
  {
    path: "/dashboard/transactiontable",
    name: "Transactions",
    icon: <TbTransfer />,
  },
  {
    path: "/dashboard/bonus",
    name: "Achievements",
    icon: <FaWallet />,
  },
  {
    path: "/dashboard/transactionbonus",
    name: "Reward",
    icon: <BsTrophy />,
  },
  
  {
    path: "/chart",
    name: "Charts",
    icon: <PiChartLineUpBold />,
    subRoutes: [
    
      {
        path: "/dashboard/levelchart",
        name: "Level Chart",
        icon: <GiLevelEndFlag />,
      },
      {
        path: "/dashboard/matrixchart",
        name: "Matrix Chart",
        icon: <AiOutlineApartment />,
      },
      // {
      //   path: "/dashboard/incomechart",
      //   name: "Income Chart",
      //   icon: <AiOutlineRise />,
      // },
    ],
  },
  {
    path: "/bots",
    name: "Bots",
    icon: <BsRobot />,
    subRoutes: [
      {
        path: "/dashboard/api_settings",
        name: "API Credentials",
        icon: <AiFillApi />,
      },
      {
        path: "/dashboard/botmain",
        name: "Bots ",
        icon: <FaBots />,
      },
      {
        path: "/dashboard/activebot",
        name: "Active Bot",
        icon: <SiProbot />,
      },
      {
        path: "/dashboard/trades",
        name: "Trades",
        icon: <PiAlignBottomDuotone />,
      },
      // {
      //   path: "/dashboard/report",
      //   name: "Reports",
      //   icon: <TbAlignBoxBottomCenter />,
      // },
    ],
  },


  {
    path: "/dashboard/KycNew",
    name: "Kyc",
    icon: <IoMdCheckmarkCircleOutline />,
  },

  {
    path: "/dashboard/logs",
    name: "Timeline",
    icon: <RiMapPinTimeLine />,
  },
  // {
  //   path: "/dashboard/supportpage",
  //   name: "Support",
  //   icon: <MdOutlineSupportAgent />,
  // },
  {
    path: "/dashboard/FAQ",
    name: "FAQ",
    icon: <FaQuestion />,
  },
  {
    path: "/dashboard/profile",
    name: "Profile",
    icon: <CgProfile />,
  },
  // {
  //   path: "/dashboard/guide",
  //   name: "Guide",
  //   icon: <AiFillHeart />,
  // },
];


  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const projectName = process.env.REACT_APP_PROJECT || "Profitsla";

  const [profile, setProfile] = useState("");

  const refreshInterval = 3600000;

  useEffect(() => {
    View_Profile();

    const intervalId = setInterval(() => {
      View_Profile();
    }, refreshInterval);

    return () => clearInterval(intervalId);
  }, []);

  const View_Profile = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setProfile(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
        if (response?.data?.Error === "Session Expired") {
          localStorage.clear();
          window.location.href = "/";
          Navigate("/");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.Error === "Session Expired") {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/";
            Navigate("/");
            message.error(err?.response?.data?.Error);
          }, 100);
        }
      });
  };

  const logout = () => {
    localStorage.clear();
    toast("Logout Successfully");
    window.location.href = "/";
  };

  return (
    <>
      <div
        className={`md:flex  md:flex-col shadow-lg shadow-neonBlue shadow-neumorphic flex-row fixed left-0 right-0 bottom-0   md:h-screen h-[75px] hidden md:bg-colorPrimary border-[#ccc]  rounded-r-2xl  lg:mt-32  ${openSide
            ? "md:w-[224px] "
            : "md:w-[45px] flex items-center justify-center "
          }`}
      >
        <div className="main-container ">
          <motion.div
            animate={{
              width: openSide ? "224px" : "45px",

              transition: {
                duration: 0.5,
                type: "spring",
                damping: 10,
              },
            }}
            className={`sidebar`}
          >
            <div className="top_section ">
              <AnimatePresence>
                {openSide && (
                  <motion.h1
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo"
                  >
                    {/* Odecent */}
                     <img src={logo} alt="logo" className="" />
                  </motion.h1>
                )}
              </AnimatePresence>

              {/* <motion.div
                animate={
                  openSide
                    ? {
                      rotate: -90,
                    }
                    : { rotate: 0 }
                }
              >
                <FaAngleDown onClick={toggle} />
              </motion.div> */}
            </div>

            {/* <div className="border border-[#ccc6]"></div> */}

            <section className="routes">
              {routes.map((route, index) => {
                if (route.subRoutes) {
                  // Determine if the current section is open or closed based on the state
                  const isOpen = openSections[route.name.toLowerCase()] || false;
                  return (
                    <SidebarMenu
                      key={index}
                      setopenSide={setopenSide}
                      route={route}
                      showAnimation={showAnimation}
                      openSide={openSide}
                      isOpen={isOpen}
                      setActiveLink={setActiveLink}
                    />
                  );
                } else {
                  // For individual links, check if it's the "Charts" or "Bots" section and apply default open/close behavior
                  const isOpen = route.name.toLowerCase() === 'charts' ? openSections.chart : openSections.bots;
                  return (
                    <NavLink
                      to={route.path}
                      key={index}
                      className={`link ${route.path === activeLink ? "" : "transition-all rounded-tl-lg rounded-bl-lg hover:scale-110 transition-5s cursor-pointer"}`}
                      onClick={() => {
                        setActiveLink(route.path);
                        setopenSide(true);
                      }}
                    >
                      <div className="icon">{route.icon}</div>
                      <AnimatePresence>
                        {openSide && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                }
              })}


            </section>

            <footer className="fixed bottom-0 ">
              <div className="flex items-center justify-center rounded-md py-1  from-neonPurple via-neonPurpleShadow to-neonBlue gap-3 w-full px-1.5">
                {openSide && (
                  <>
                    <Avatar
                      src={profile.Profile_Image}
                      className="rounded-full w-10 h-10"
                    />
                    <div className="flex-grow text-black text-base md:text-sm font-semibold text-center">
                      {profile.Username}!
                    </div>
                  </>
                )}
                <button
                  className={`text-[red] ${openSide ? "" : ""}`}
                  onClick={logout}
                >
                  <IoMdLogOut size={20} className="text-[red]" />
                </button>
              </div>

              <div className="text-center text-xs my-1">
                {openSide && (
                  <p className="text-black">
                    © All right reserved
                    <span className="font-bold">@{projectName}</span> 2024
                  </p>
                )}
              </div>
            </footer>
          </motion.div>

          <main>{children}</main>
        </div>
      </div>
    </>
  );
};

export default SideBar;
