import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosLock } from "react-icons/io";
import { HandleSession } from "./HandleSession";

function MatchingIncomeCard() {
  const [income, setIncome] = useState([]);
  const [Quali, setQuali] = useState({});

  const View_Income = async () => {
    try {
      const postdata = {
        project: process.env.REACT_APP_PROJECT,
      };

      const response = await axios.post(
        process.env.REACT_APP_API_PUBLIC + "matching_income",
        postdata,
        { headers: { "Content-Type": "application/json" } }
      );
      setIncome(response.data.Success);
    } catch (err) {
      HandleSession();
    }
  };

  const View_QualiMatching = async () => {
    try {
      const jwt = localStorage.getItem("jwt");
      const postdata = { jwt };

      const response = await axios.post(
        process.env.REACT_APP_API_Matching + "qualified_matching_income",
        postdata,
        { headers: { "Content-Type": "application/json" } }
      );
      setQuali(response.data.Success.Matching_Income_Qual);
    } catch (err) {
      HandleSession(err);
    }
  };

  useEffect(() => {
    View_Income();
    View_QualiMatching();
  }, []);

  return (
    <>
      <div className="container">
      <div className='text-center font-semibold text-black py-4 text-2xl'>Your Current Achievement</div>
        <div className="mx-2 grid grid-cols-1 lg:grid-cols-2 gap-3 mt-5">
          {income?.map((pack) => (
            <div
              key={pack.ID}
              className="rounded-2xl h-auto md:h-[290px] justify-center items-center"
            >
              <div className="relative transition-all transition-10s cursor-pointer">
                <img
                  src={`/images/Full & Final/Matching Bonus/${pack.Image}`}
                  alt={`package-${pack.ID}`}
                />
                {!Quali[pack.Level] && (
                  <div className="absolute inset-0 text-white flex items-center bg-blur backdrop-blur-sm bg-white/20 justify-center">
                    <IoIosLock size={50} />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default MatchingIncomeCard;
