

import React, { useState , useEffect} from "react";
import SwipeableViews from "react-swipeable-views";
import DepositTran from "../Components/DepositTran";
import axios from "axios";
import Api from "../Components/Api";
import Timeline from "./Tmeline";
import ModuleTree from "./Module";


const Logs = () => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);
  
  const [depositData, depositTotalPages] = Api({
    pageName: "Login_Logs",
    pageSize: pageSize,
    page: page,
  });

  const handleChangeIndex = (newIndex) => {
    setIndex(newIndex);
  };


  return (
    <>
      <div className="mx-5">
        <Timeline
          data={{
            depositData: depositData,
            page: page,
            pageSize: pageSize,
            totalPages: depositTotalPages,
            setPage: setPage,
            setpageSize: setpageSize,
          }}
        />

      </div>
    </>
  );
};

export default Logs;
