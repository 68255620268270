import React from 'react'
import { Tooltip } from 'antd';


function WalletBalance({ wallet }) {
  
  const walletData = [
    {
      name: 'Deposit Wallet',
      Current: wallet?.Wallet_Balance !== undefined ? wallet?.Wallet_Balance : "0",
      Lifetime: wallet?.Total_Wallet_Deposit !== undefined ? wallet?.Total_Wallet_Deposit : "0",
    },
    {
      name: 'Withdrawal',
      Current: wallet?.Withdrawal_Balance !== undefined ? wallet?.Withdrawal_Balance : "0",
      Lifetime: wallet?.Total_Withdrawal !== undefined ? wallet?.Total_Withdrawal : "0",
    },
    {
      name: 'Level Earning',
      Current: wallet?.Level_Earning_Balance !== undefined ? wallet?.Level_Earning_Balance : "0",
      Lifetime: wallet?.Total_Level_Earning !== undefined ? wallet?.Total_Level_Earning : "0",
    },
    {
      name: 'Rank Reward',
      Current: wallet?.Rank_Reward_Balance !== undefined ? wallet?.Rank_Reward_Balance : "0",
      Lifetime: wallet?.Total_Rank_Reward !== undefined ? wallet?.Total_Rank_Reward : "0",
    },
    {
      name: 'Matching Income',
      Current: wallet?.Matching_Income_Balance !== undefined ? wallet?.Matching_Income_Balance : "0",
      Lifetime: wallet?.Total_Matching_Income !== undefined ? wallet?.Total_Matching_Income : "0",
    },
    {
      name: 'Matrix Earning',
      Current: wallet?.Matrix_Earning_Balance !== undefined ? wallet?.Matrix_Earning_Balance : "0",
      Lifetime: wallet?.Total_Matrix_Earning !== undefined ? wallet?.Total_Matrix_Earning : "0",
    },
  ]


  return (
    <>
      <div className=' bg-colorPrimary shadow-neumorphic1 border-gradient'>
        <div className='pt-2 pb-2 pl-10'>
          <p className=' text-xl pt-2 font-medium'>Wallets</p>
        </div>
        <div className=' grid grid-cols-1 md:grid-cols-3 gap-y-5 gap-x-10 py-3 pb-8 px-2 md:px-20'>
          {
            walletData.map((item, index) => {
              return (
                <div key={index} className=' bg-gradient-to-r from-[rgba(142,90,251,0.15)] via-[rgba(53,47,234,0.15)] to-[rgba(46,206,253,0.15)] py-2 px-4 rounded-xl p-4 shadow-lg transition-all duration-300 hover:scale-110 '>
                  <div className='text-xl font-semibold text-center text-blue-700 pt-2'>{item?.name}</div>
                  <div className='flex justify-between py-4'>
                    <div className='w-1/2 border-2 border-r-blue-700 text-gray-600 font-medium' >
                      <Tooltip title="Current Balance" color={"purple"}>
                        ${item?.Current}
                      </Tooltip>
                    </div>
                    <div className='w-1/2 text-end text-gray-600 font-medium'>
                      <Tooltip title="Lifetime Earning" color={"purple"}>
                        ${item?.Lifetime}
                      </Tooltip>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  );
}

export default WalletBalance