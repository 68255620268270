import React, { useEffect, useState } from 'react';
import RankCard from './RankCard';
import axios from 'axios';
import { HandleSession } from './HandleSession';

function Ranktab() {
  const [rankData, setRankData] = useState({
    rank: [],
    current: '',
    Quali: [],
    avatar: '',
  });
  const jwt = localStorage.getItem('jwt');

  const fetchData = async () => {
    try {
      const [rankResponse, currentRankResponse, qualiRankResponse, avatarResponse] = await Promise.all([
        axios.post(process.env.REACT_APP_API_PUBLIC + 'rank', { project: process.env.REACT_APP_PROJECT }),
        axios.post(process.env.REACT_APP_API_PATH + '/rank/current_rank', { jwt }),
        axios.post(process.env.REACT_APP_API_PATH + '/rank/qualified_rank', { jwt }),
        axios.post(process.env.REACT_APP_API_PATH_USER_ODECENT + 'view_avatar', { jwt }),
      ]);

      setRankData({
        rank: rankResponse.data.Success,
        current: currentRankResponse.data.Success,
        Quali: qualiRankResponse.data.Success.Rank_Reward_Qual,
        avatar: avatarResponse.data.Success,
      });
    } catch (error) {
      HandleSession(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { rank, current, Quali, avatar } = rankData;

  return (
    <div className='h-auto max-h-screen'>
      <div className='items-center mx-5'>
        <div className='text-center font-semibold text-black py-4 text-md lg:text-2xl'>Your Current Achievement</div>
      </div>
      <div>
        {(avatar === 'Male' || avatar === 'Female' || avatar === 'Bot') && (
          <RankCard rank={rank} current={current} Quali={Quali} path={`${avatar}`} type='Rank' />
        )}
      </div>
    </div>
  );
}

export default Ranktab;
