import { Empty, Table } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";

function WithdrwalTable(props) {
  const { subData, pageSize, page, totalPages, setPage, setpageSize } =
    props.data;

  const columns = [
    {
      title: "DATE",
      dataIndex: "Datetime",
      key: "Datetime",
    },
    {
      title: "TRANS ID",
      dataIndex: "TransID",
      key: "TransID",
    },

    {
      title: "WALLET ADDRESS",
      dataIndex: "Wallet_Address",
      key: "Wallet_Address",
    },
    {
      title: "WALLET TYPE",
      dataIndex: "Wallet_Type",
      key: "Wallet_Type",
    },

    {
      title: "NETWORK",
      dataIndex: "Network",
      key: "Network",
    },

    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      render: (text) => (
        <span style={{ color: text === "SUCCESS" ? "green" : "red" }}>
          {text}
        </span>
      ),
    },
  ];

  const rows = [];

  if (subData) {
    for (let i = 0; i < subData.length; i++) {
      const dateParts = subData[i]?.Datetime?.split(" ");
      if (dateParts && dateParts.length === 2) {
        rows.push({
          key: i,
          Datetime: subData[i]?.Datetime,
          TransID: subData[i]?.TransID,
          Wallet_Address: subData[i]?.Wallet_Address,

          Wallet_Type: subData[i]?.Wallet_Type?.replace("_", " "),
          Network: subData[i]?.Network,
          Status: subData[i]?.Status,
        });
      }
    }
  }

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setpageSize(newPageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => { };

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  if (isMobile) {
    return (
      <>
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />

        {rows.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full">
            {rows.map((record) => (
              <div
                key={record.TransID}
                className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
              >
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Date Time:</div>
                  <div className="card-data">{record.Datetime}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">TransID:</div>
                  <div className="card-data">{record.TransID}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Wallet Address:</div>
                  <div className="card-data">
                    <div className="card-data">{record.Wallet_Address}</div>
                  </div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Wallet Type:</div>
                  <div className="card-data">{record.Wallet_Type}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Network:</div>
                  <div className="card-data">{record.Network}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Status:</div>
                  <div className="card-data">
                    <span
                      style={{ color: record.Status === "SUCCESS" ? "green" : "red" }}
                    >
                      {record.Status}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
            <Empty  />
          </div>
        )}


        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </>
    );
  }



  return (
    <div>
      <div className=" w-full bg-colorPrimary ">
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <Table
          className="table-newres my-2"
          columns={columns}
          dataSource={rows}
          onChange={onChange}
          pagination={false}
        // pagination={{
        //   pageSize,
        //   page,
        //   showSizeChanger: false,
        //   position: ["topRight", "bottomRight"],
        // }}
        />
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
}

export default WithdrwalTable;
