import React, { useEffect, useState } from "react";
import { Empty, Table, Tooltip, message } from "antd";
import { FaRegCopy, FaRegEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { RiShareForward2Fill } from "react-icons/ri";
import CustomPagination from "./Pagination";
import { toast } from "react-toastify";



const PaymentTran = (props) => {
  const { subData, pageSize, page, totalPages, setPage, setpageSize } =
    props?.data;

  const navigate = useNavigate();

  const handlesubmit = (record) => {
    window.open(subData.URL, '_blank');
    console.log(subData.URL, "jjhj");
    if (subData && subData.URL) {
      window.open(subData.URL, '_blank');
      console.log(subData.URL, "jjhj");
    } else {
      console.log("URL is undefined or null");
    }
  };
  
  
  const columns = [
    {
      title: "DATE",
      dataIndex: "Datetime",
      key: "Datetime",
    },
    {
      title: "TRANSID",
      dataIndex: "TransID",
      key: "TransID",
    },
    {
      title: "GATEWAY",
      dataIndex: "Gateway",
      key: "Gateway",
    },
    {
      title: "WALLET ADDRESS",
      dataIndex: "Receiver_Wallet_Address",
      key: "Receiver_Wallet_Address",
      render: (text) => (
        <span>
          {text && `${text.slice(0, 4)}...${text.slice(-4)}`}
          <button onClick={() => copyToClipboard(text)}>
            <FaRegCopy />
          </button>
        </span>
      ),
    },
    {
      title: "ASSET",
      dataIndex: "Coin",
      key: "Coin",
    },
    {
      title: "AMOUNT",
      dataIndex: "USD",
      key: "USD",
      render: (text) => `$ ${text}`,
    },
    {
      title: "PRICE",
      dataIndex: "Price",
      key: "Price",
    },
    // {
    //   title: "URL",
    //   dataIndex: "URL",
    //   key: "URL",
    // },
    {
      title: "STATUS",
      dataIndex: "Status",
      render: (text, record) => (
        <div className="flex justify-between items-center">
        <span className={text === "SUCCESS" ? "text-[green]" : "text-[red]"}>
          {text === "PENDING" ? (
            <span>
              {text}
              {record && record.Receiver_Wallet_Address && (
                <Tooltip title="View Status">
                  <Link to={record.URL} target="_blank">
                  <button >
                    <RiShareForward2Fill className="ml-1 text-black " />
                  </button>
                  </Link>
                </Tooltip>
              )}
            </span>
          ) : (
            text
          )}
        </span>
        </div>
      ),
    },
  ];

  const rows = Array.isArray(subData)
    ? subData.map((item, index) => ({
      key: index,
      Datetime: item?.Datetime,
      Status: item?.Status,
      TransID: item?.TransID,
      Gateway: item?.Gateway,
      URL: item?.URL,
      IP: item?.IP,
      Receiver_Wallet_Address: item?.Receiver_Wallet_Address,
      Coin: item?.Coin?.replace(/_/g, " "),
      Amount: "$" + parseFloat(item?.Amount?.USDT) || 0,
      Price: item?.Price,
      USD: item?.USD,
    }))
    : [];

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.setAttribute("readonly", "");
    textArea.style.position = "absolute";
    textArea.style.left = "-9999px";

    document.body.appendChild(textArea);
    textArea.select();

    try {
      const success = document.execCommand("copy");
      if (success) {
        toast("Copied");
      } else {
        message.error("Copy failed. Please try again.");
      }
    } catch (err) {
      message.error("Copy failed. Please try again.");
    }

    document.body.removeChild(textArea);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };


  const handlePageSizeChange = (newPageSize) => {
    setpageSize(newPageSize);
  };


  const isMobile = useMediaQuery({ maxWidth: 1050 });

  if (isMobile) {
    return (
      <>
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />

        {rows.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full">
            {rows.map((record) => (
              <div
                key={record.TransID}
                className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
              >
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Date Time:</div>
                  <div className="card-data">{record.Datetime}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">TransID:</div>
                  <div className="card-data">
                    {record.TransID}
                  </div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Wallet Address:</div>
                  <div className="card-data">
                    {`${record.Receiver_Wallet_Address?.slice(
                      0,
                      4
                    )}...${record.Receiver_Wallet_Address?.slice(-4)}`}{" "}
                    <button
                      onClick={() =>
                        copyToClipboard(record.Receiver_Wallet_Address)
                      }
                    >
                      <FaRegCopy className="ml-4" />
                    </button>
                  </div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Asset:</div>
                  <div className="card-data">{record.Coin}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Amount:</div>
                  <div className="card-data">$ {record.USD}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Price:</div>
                  <div className="card-data">$ {record.Price}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold ">Status:</div>
                  <div className="card-data  ">
                    {record.Status === "SUCCESS" ? (
                      <span className="text-[green]">{record.Status}</span>
                    ) : (
                      <span className="text-[red] flex justify-center items-center">
                        {record.Status}
                        {record.Status === "PENDING" && (
                          <>
                            {record.Receiver_Wallet_Address && (
                              <Tooltip title="View Status">
                                 <Link to={record.URL} target="_blank">
                                <button >
                                  <RiShareForward2Fill
                                    size={25}
                                    className="ml-1 text-black rounded-full bg-colorPrimary shadow-neumorphicnew p-1"
                                  />
                                </button>
                                </Link>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
            <Empty />
          </div>
        )}


        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </>
    );
  }



  // ... (remaining code)

  return (
    <div className=" mx-auto w-full">
      <CustomPagination
        totalPages={totalPages}
        currentPage={page}
        pageSize
        onPageChange={onPageChange}
        onPageSizeChange={handlePageSizeChange}
      />
      <Table
        columns={columns}
        className="my-2"
        pagination={false}
        dataSource={rows}
      />
      <CustomPagination
        totalPages={totalPages}
        currentPage={page}
        pageSize
        onPageChange={onPageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </div>
  );
};
export default PaymentTran;
