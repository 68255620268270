import { Button, Empty, Modal, Switch, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SkeltorLoader from "./SkeltorLoader";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";

function ActiveBotTable(props) {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [record, setRecord] = useState();
  const { selectedBotID, type, coin } = props;
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [visible, setVisible] = useState(false);

  const showModal1 = () => {
    setIsModalVisible1(true);
  };

  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  const showModal = (record) => {
    setVisible(true);
    setRecord(record);
  };

  const handleOk = () => {
    setVisible(false);
    handleDelete();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [
    filterexchange,
    filter,
    typedata,
    apiname,
    botname,
    filterbotData,
    filterData,
  ] = props.data;

  const simpleData = [];

  const simpleColumns = [
    {
      title: "Date Time",
      dataIndex: "DateTime",
      width: 350,
    },
    {
      title: "Bot Name",
      dataIndex: "BotName",
      width: 200,
    },
    {
      title: "API Name",
      dataIndex: "API_Name",
      width: 200,
    },
    {
      title: "Exchange",
      dataIndex: "Exchange",
      width: 200,
    },
    {
      title: "Exchange Type",
      dataIndex: "Exchange_Type",
      width: 200,
    },
    {
      title: "Investment Type",
      dataIndex: "Investment_Risk",
      width: 200,
    },
    {
      title: "Symbol",
      dataIndex: "Symbol",
      width: 200,
    },
    {
      title: "Invest",
      dataIndex: "Total_Investment",
      width: 200,
    },
    {
      title: "Telegram Alert",
      dataIndex: "Telegram_Alert",
      key: "Telegram_Alert",
      editable: true,
      className: "w-7",
      width: 200,
      render: (text, record) => (
        <>
          <div className="justify-center items-center ">
            <div>
              <Switch
                className={record.Telegram_Alert ? "off" : "on"}
                checked={record.Telegram_Alert}
                checkedChildren={<div className="">ON</div>}
                unCheckedChildren={<div className="text-white">OFF</div>}
                onChange={(checked) => handleSwitchChangetele(record, checked)}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      editable: true,
      className: "w-7",
      width: 200,
      render: (text, record) => (
        <>
          <div className="justify-center items-center ">
            <div>
              <Switch
                className={record.Status === "ACTIVE" ? "off" : "on"}
                checked={record?.Status === "ACTIVE"}
                checkedChildren={<div className=" "> Start</div>}
                unCheckedChildren={<div className="text-white  ">Pause</div>}
                onChange={(checked) => handleSwitchChange(record, checked)}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      key: "Delete",
      width: 100,
      render: (text, record) => (
        <Button
          type="danger"
          onClick={() => showModal(record)}
          //   onClick={() => handleDelete(record)}
        >
          {/* <MdOutlineDeleteOutline /> */}
          <MdDelete className=" text-lg" />
        </Button>
      ),
    },
    ,
  ];

  if (filteredData) {
    let filteredRows = filteredData;

    for (let i = 0; i < filteredRows?.length; i++) {
      let Invest;
      if (filteredRows[i]?.Exchange_Type === "FUTURES") {
        Invest = filteredRows[i]?.InvestL + filteredRows[i]?.InvestS;
      } else {
        Invest = filteredRows[i]?.Invest;
      }

      simpleData?.push({
        key: i,
        DateTime: filteredRows[i]?.DateTime,
        BotName: filteredRows[i]?.BotName,
        Exchange: filteredRows[i]?.Exchange,
        Exchange_Type: filteredRows[i]?.Exchange_Type,
        Type: filteredRows[i]?.Type,
        Symbol: filteredRows[i]?.Symbol,
        Investment_Risk: filteredRows[i]?.Investment_Risk,
        Total_Investment: filteredRows[i]?.Total_Investment,
        Balance: filteredRows[i]?.Balance,
        ChatID: filteredRows[i]?.ChatID,
        Status: filteredRows[i]?.Status,
        API_Name: filteredRows[i]?.API_Name,
        VarBotID: filteredRows[i]?.VarBotID,
        Telegram_Alert: filteredRows[i]?.Telegram_Alert,
      });
    }
  }

  const onChange = (pagination) => {
    // setpagesize(pagination.pageSize);
    setPage(pagination.current);
    setSize(pagination.size);
  };

  async function handleFilterpage() {
    const jwt = localStorage.getItem("jwt");

    let postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      page_name: "ActiveBot",
      page_size: size,
      exchange: filter,
      exchange_type: type,
      type: typedata,
      api_name:
        selectedBotID && selectedBotID.length > 0 ? selectedBotID : apiname,
      bot_id: botname,
      // bot_name: filterbotData,
      // bot_name: filterbotData.length > 0 ? filterbotData : [],
      bot_name: filterbotData,
      // symbol: coin,
      symbol: coin && coin.length > 0 ? coin : filterData,

      // symbol: coin.length > 0 ? coin : [],
    };

    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_FIL + "total_pages",
      data: postData,
    })
      .then((res) => {
        //  const filteredSymbols = response?.data?.Data?.filter(
        //    (item) => item.Exchange_Type === filterexchange
        //  );
        // setFilteredData(res?.data?.Success);
       setTotalPages(res?.data?.Success);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // toast.error(err.response.data.Error);
      });
  }

  const trades_transactions = async () => {
    const jwt = localStorage.getItem("jwt");

    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      exchange: filter,
      exchange_type: type,
      type: typedata,
      api_name:
        selectedBotID && selectedBotID.length > 0 ? selectedBotID : apiname,
      bot_id: botname,
      // bot_name: filterbotData.length > 0 ? filterbotData : [],
      bot_name: filterbotData,
      // bot_name: filterbotData,
      // symbol: coin,
      symbol: coin && coin.length > 0 ? coin : filterData,

      // symbol: coin.length > 0 ? coin : [],
      page_no: page,
      page_size: size,
    };

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT + "bot_list",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response?.data?.Success;
        //    const filteredSymbols = response?.data?.Data?.filter(
        //   (item) => item.Exchange_Type === filterexchange
        // );
        const sortedData = res?.sort(
          (a, b) => new Date(b?.DateTime) - new Date(a?.DateTime)
        );

        setFilteredData(sortedData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
        setLoading(false);
        if (err?.response?.data?.Error === "No bots yet!") {
          // Set filteredData to an empty array
          setFilteredData([]);
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await trades_transactions();
      await handleFilterpage();
    };

    fetchData();
  }, [
    filterexchange,
    filter,
    typedata,
    apiname,
    botname,
    filterbotData,
    filterData,
    page,
    size,
    coin,
    selectedBotID,
  ]);

  const handleSwitchChange = (record, checked) => {
    const newStatus = checked ? "ACTIVE" : "PAUSED";

    if (checked) {
      botStart(record);
    } else {
      botStop(record);
    }

    const updatedDataSource1 = filteredData.map((item) =>
      item.API_Name === record.API_Name && item.VarBotID === record.VarBotID
        ? { ...item, Status: newStatus }
        : item
    );
    setFilteredData(updatedDataSource1);
  };

  const botStart = (record) => {
    const token = localStorage.getItem("jwt");
    const postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: token,
      bot_id: record?.VarBotID,
      api_name: record?.API_Name,
    };
    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH + "start",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);
        trades_transactions();
      })
      .catch((err) => console.log(err));
  };

  const botStop = (record) => {
    const token = localStorage.getItem("jwt");
    const postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: token,
      bot_id: record?.VarBotID,
      api_name: record?.API_Name,
    };
    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH + "pause",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);
        trades_transactions();
      })
      .catch((err) => toast.error(err?.res?.data?.Error));
  };

  const handleDelete = () => {
    const token = localStorage.getItem("jwt");
    const postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: token,
      bot_id: record?.VarBotID,
      api_name: record?.API_Name,
    };

    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH + "stop",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);
        trades_transactions();
      })
      .catch((err) => console.log(err));
  };

  const handleSwitchChangetele = (record, checked) => {
    const newStatus = checked ? "true" : "false";

    const updatedDataSource1 = filteredData.map((item) =>
      item.API_Name === record.API_Name && item.VarBotID === record.VarBotID
        ? { ...item, Telegram_Alert: newStatus }
        : item
    );
    setFilteredData(updatedDataSource1);
    const jwt = localStorage.getItem("jwt");
    const postData = {
      jwt: jwt,
      api_name: record.API_Name,
      status: newStatus,
      project: process.env.REACT_APP_PROJECT,
      bot_id: record.VarBotID,
    };

    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_BOT + "telegram_alert_status",
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data;
        if (res?.Success) {
          toast(res?.Success);
          trades_transactions();
        } else if (res.Error) {
          toast.error(res.Error);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.Error);
        if (err?.response?.data?.Error === "Session Expired") {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/";
            Navigate("/");
            toast.error(err?.response?.data?.Error);
          }, 100);
        }
      });
  };

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  const pageSizeOptions = [30, 50, 100];

  const onPageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (size) => {
    setSize(size);
  };

  // const anyUnchecked =
  //   (Array.isArray(coin) && coin.length === 0) ||
  //   (Array.isArray(selectedBotID) && selectedBotID.length === 0);

  // if (anyUnchecked || filteredData.length === 0) {
  //   return <Empty description="No active bot!" />;
  // }

  return (
    <div>
      <div className="mt-2 container  mx-6  table-newres ">
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <Modal
          title="Delete Confirmation"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={<span className=" text-black">OK</span>}
          cancelText="Cancel"
          footer={false}
        >
          <p>Are you sure you want to delete this Bot?</p>
          <div className=" flex justify-end mt-4">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out"
              onClick={handleDelete}
            >
              OK
            </button>
          </div>
        </Modal>
        <div className=" my-2 table-newres ">
          {loading ? (
            <div className="w-full h-40 rounded-tr-3xl rounded-tl-3xl bg-colorPrimary shadow-neumorphic ">
              <div className="w-full h-14 bg-colorSecondaryDark ">
                <SkeltorLoader />
              </div>
            </div>
          ) : (
            <Table
              columns={simpleColumns}
              dataSource={simpleData}
              pageSize={size}
              currentPage={page}
              pagination={false}
              className="mt-14 lg:mt-2"
            />
          )}
        </div>
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
}

export default ActiveBotTable;
