import React, { useEffect, useState } from 'react';
import axios from 'axios';
import RankCard from './RankCard';
import { HandleSession } from './HandleSession';

function LevelTab() {
  const [levelData, setLevelData] = useState({
    level: [],
    current: '',
    Quali: [],
  });
  const jwt = localStorage.getItem('jwt');

  const fetchData = async () => {
    try {
      const [levelResponse, currentLevelResponse, qualiLevelResponse] = await Promise.all([
        axios.post(process.env.REACT_APP_API_PUBLIC + 'level'),
        axios.post(process.env.REACT_APP_API_Level + 'current_level', { jwt }),
        axios.post(process.env.REACT_APP_API_Level + 'qualified_level', { jwt }),
      ]);

      setLevelData({
        level: levelResponse.data.Success,
        current: currentLevelResponse.data.Success,
        Quali: qualiLevelResponse.data.Success.Level_Earning_Qual,
      });
    } catch (error) {
      HandleSession(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { level, current, Quali } = levelData;

  return (
    <div>
      <div className='text-center font-semibold text-black py-4 text-lg md:text-2xl'>Your Current Achievement</div>
      <div className="">
        <RankCard rank={level} current={current} Quali={Quali} path={"Level Bonus"} type={"Level"}/>
      </div>
    </div>
  );
}

export default LevelTab;
