import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { HiOutlineCheckBadge } from 'react-icons/hi2';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

function ButtonApi() {
  const [apilistdata, setapilistdata] = useState([]);
  const [Packageview1, setPackageview1] = useState([]);
  const [botData, setBotData] = useState([]);
  const [profile, setProfile] = useState("");
  const location = useLocation();


  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    View_Profile();
  }, []);

  const View_Profile = async () => {

    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setProfile(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
        if (response?.data?.Error === "JWT Expired") {
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
        if (err?.response?.data?.Error === "JWT Expired") {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/";
          }, 5000);
        }
      });
  };

  const ViewPackageView = async () => {


    const postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_SUB + "view_subscription",
      data: postData,
    })
      .then(async function (response) {
        if (response?.data?.Details) {
          setPackageview1(response?.data?.Details);
          console.log(Packageview1, "Packageview1")
        }
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
      });
  };

  const view_totp = async () => {
    const token = localStorage.getItem("jwt");
    const postData = { jwt: jwt, project: process.env.REACT_APP_PROJECT };
    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_API + "api_list",
      data: postData,
    })
      .then(async function (res) {
        const response = res?.data;
        setapilistdata(response.Success);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const get_bot_list = () => {
    const token = localStorage.getItem("jwt");
    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH + "bot_list",
      data: {
        project: process.env.REACT_APP_PROJECT,
        jwt: token,
      },
    })
      .then((res) => {
        setBotData(res?.data?.Success);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    ViewPackageView();
    get_bot_list();
    view_totp();
  }, []);

  const pausedPaperApiCount = apilistdata?.filter(
    (api) => api.Status === "ACTIVE" && api.Type === "PAPER"
  ).length;

  const pausedPaperApiCount1 = apilistdata?.filter(
    (api) => api.Status === "ACTIVE" && api.Type === "LIVE"
  ).length;

  const pausedPaperBotCount = botData.filter(
    (bot) => bot.Status === "ACTIVE" && bot.Type === "PAPER"
  ).length;

  const pausedPaperBotCount1 = botData.filter(
    (bot) => bot.Status === "ACTIVE" && bot.Type === "LIVE"
  ).length;

  const items1 = [
    {
      label: "Subscription",
      icon: Packageview1?.Name ? (
        <>
          <div className="flex items-center">
            <HiOutlineCheckBadge size={20} className="text-green-400" />
            <div className="text-xs">{Packageview1?.Name}</div>
          </div>
        </>
      ) : (
        <Link to="/dashboard/subscriptions">
          <span className="border  border-slate-500 text-red-400 bg-white hover:scale-105 transition-all duration-500 flex items-center gap-1 hover:border-red-400 rounded-md px-3 py-1 cursor-pointer text-xs">
            Update
          </span>
        </Link>
      ),
    },
    {
      label: "Telegram ID",
      content: profile?.TelegramID ? (
        <>
          <HiOutlineCheckBadge size={20} className="text-green-400" />
        </>
      ) : (
        <span className="border  border-slate-500 text-red-400 bg-white hover:scale-105 transition-all duration-500 flex items-center gap-1 hover:border-red-400 rounded-md px-3 py-1 cursor-pointer text-xs">
          Pending
        </span>
      ),
    },
    {
      label: `LIVE API `,
      name1: `(${pausedPaperApiCount1 ? pausedPaperApiCount1 : "0"}/${Packageview1?.Live_API || "0"
        })`,
      // buttonText: "Update",
      // url: "/dashboard/subscriptions",
    },
    {
      label: `PAPER API `,
      name1: `(${pausedPaperApiCount ? pausedPaperApiCount : "0"}/${Packageview1?.Paper_API || "0"
        })`,
      // buttonText: "Update",
      // url: "/dashboard/subscriptions",
    },
  ];

  const items2 = [
    {
      label: "Subscription",
      icon: Packageview1?.Name ? (
        <>
          <div className="flex items-center">
            <HiOutlineCheckBadge size={20} className="text-green-400" />
            <div className="text-xs">{Packageview1?.Name}</div>
          </div>
        </>
      ) : (
        <Link to="/dashboard/subscriptions">
          <span className="border  border-slate-500 text-red-400 bg-white hover:scale-105 transition-all duration-500 flex items-center gap-1 hover:border-red-400 rounded-md px-3 py-1 cursor-pointer text-xs">
            Update
          </span>
        </Link>
      ),
    },
    {
      label: "Telegram ID",
      content: profile?.TelegramID ? (
        <>
          <HiOutlineCheckBadge size={20} className="text-green-400" />
        </>
      ) : (
        <span className="border  border-slate-500 text-red-400 bg-white hover:scale-105 transition-all duration-500 flex items-center gap-1 hover:border-red-400 rounded-md px-3 py-1 cursor-pointer text-xs">
          Pending
        </span>
      ),
    },
    {
      label: `LIVE BOTS `,
      name1: `(${pausedPaperBotCount1 ? pausedPaperBotCount1 : "0"}/${Packageview1?.Live_Bots || "0"
        })`,
      // buttonText: "Update",
      // url: "/dashboard/activebot",
    },
    {
      label: `PAPER BOTS `,
      name1: `(${pausedPaperBotCount ? pausedPaperBotCount : "0"}/${Packageview1?.Paper_Bots || "0"
        })`,
      // buttonText: "Update",
      // url: "/dashboard/activebot",
    },
  ];


  const basePath = "/dashboard/api_settings";

  return (
    <div>
      <div className="flex justify-center items-center">
        <div className="xl:w-full w-full xl:mt-0 mx-auto">
          <div className="grid grid-cols-2 xl:grid-cols-4 gap-4">
            {location.pathname.startsWith(basePath) &&
              items1.map((item, index) => (
                <div
                  key={index}
                  className={`flex flex-col items-center justify-evenly px-2 py-0.5 bg-colorSecondaryDark text-white rounded-lg shadow-neumorphic1 ${item.iconClass ? " " : "py-1"
                    }`}
                >
                  <div className="flex items-center">
                    <div className="flex-shrink-0 text-xs">{item.label}</div>
                  </div>
                  <div>
                    {item.label === "Telegram ID" ? (
                      <div className="flex items-center">{item.content}</div>
                    ) : (
                      <>
                        {item.label === "Subscription" ? (
                          <div className="flex items-center">{item.icon}</div>
                        ) : (
                          <>
                            {item.name1 && (
                              <div className="text-xs">{item.name1}</div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center">
        <div className="xl:w-full w-full  md:mt-0 mx-auto">
          <div className="grid-cols-2 xl:grid-cols-4 grid gap-4">
            {location.pathname === "/dashboard/botmain" &&
              items2.map((item, index) => (
                <div
                  key={index}
                  className={`flex flex-col items-center justify-evenly px-2 py-0.5 bg-colorSecondaryDark text-white rounded-lg shadow-neumorphic1 ${item.iconClass ? " " : "py-1"
                    }`}
                >
                  <div className="flex items-center">
                    <div className="flex-shrink-0 text-xs">{item.label}</div>
                  </div>
                  <div>
                    {item.label === "Telegram ID" ? (
                      <div className="flex items-center">{item.content}</div>
                    ) : (
                      <>
                        {item.label === "Subscription" ? (
                          <div className="flex items-center">{item.icon}</div>
                        ) : (
                          <>
                            {item.name1 && (
                              <div className="text-xs">{item.name1}</div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ButtonApi