import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { IoIosLock } from 'react-icons/io';
import Tree from "react-d3-tree";
import { useCenteredTree } from "./NodeLabel";
import axios from "axios";
import { Spin } from 'antd';


const containerStyles = {
  width: "100vw",
  height: "100vh"
};



const MatrixTwo = () => {

  const [translate, containerRef] = useCenteredTree();
  const [downline, setDownline] = useState([]);
  const [clickedUsers, setClickedUsers] = useState([]);
  const [profile, setProfile] = useState([]);
  const [imagesview, setImagesView] = useState("");
  const [secNested, setSecNested] = useState([])
  const [level, setLevel] = useState(1)
  // const jwt = localStorage.getItem("jwt");
  const [matrix, setMatrix] = useState([]);
  const [Quali, setQuali] = useState({});
  const [showButtons, setShowButtons] = useState("");
  const [buttonColors, setButtonColors] = useState({
    BOT1000: { from: '#0c0a44', to: '#fc6728' },
    BOT3000: { from: '#0c0a44', to: '#ce0b4c' },
    BOT5000: { from: '#0c0a44', to: '#00e7fb' },
    BOT10000: { from: '#0c0a44 ', to: '#f3ae5c' }
  });
  const jwt = localStorage.getItem("jwt");
  const [loading, setLoading] = useState(false); 

  const [buttonsDisplayed, setButtonsDisplayed] = useState(false);

 

  const View_Profile = async () => {
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
      data: postdata,
    }).then(async (res) => {
      const response = await res?.data?.Success;
      setProfile(response)
     
    }).catch((err) => {
      console.log("Error fetching profile data: ", err);
      toast.error(err?.response?.data?.Error);
    });
  };

  const View_Matrix = async () => {
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PUBLIC + "matrix",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response.data;
        setMatrix(res.Success);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const View_QualiMatrix = async () => {
    try {
      const postdata = {
        jwt: jwt,
      };

      const response = await axios.post(
        process.env.REACT_APP_API_Matrix + "all_matrix_qualified",
        postdata,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      console.log("response", response.data.Success);
      setQuali(response.data.Success);
      setButtonsDisplayed(true);
    } catch (err) {
      console.log("err", err);
    }
  };


  const View_Level = async () => {
    setLoading(true); 
    try {
      const postdata = {
        jwt: jwt,
        matrix_name: clickedButton
      };

      const response = await axios.post(
        process.env.REACT_APP_API_Matrix + "matrix_downline",
        postdata,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const downlineData = response.data.Success;
      const mapRes = downlineData.map((item, index) =>
        ({ name: item, id: index + 2, pid: 1, title: `Level 1`, img: imagesview, onClick: () => handleUserClick(item, clickedButton), level1: 1 }));
      setDownline(mapRes);

      await Promise.all(downlineData.map(async (name) => {
        const children = await View_ActiveLevel(name);
        const parentNode = clickedUsers[name];

        if (parentNode && !parentNode.children) {
          setClickedUsers((prevClickedUsers) => ({
            ...prevClickedUsers,
            [name]: {
              ...prevClickedUsers[name],
              children: children,
            },
          }));
           
        }
      }));
      setLoading(false);
    } catch (err) {
      console.log("Error fetching matrix_downline data:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    View_Matrix();
    View_Profile();
  }, [])


  useEffect(() => {
    View_QualiMatrix()
  }, [matrix]);

  const View_ActiveLevel = async (name, id, level, matrixName) => {
    setSecNested([]);
    const postdata = {
      jwt: jwt,
      username: name,
      matrix_name: clickedButton
    };
    const nextLevel = level + 1;

    try {
      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_Matrix + "downlines_downline",
        data: postdata,
      });

      const responseData = response.data.Success;
      const childrenNodes = responseData.map((item, index) => ({
        name: item,
        id: index + 1,
        pid: id,
        title: `Level ${nextLevel}`,
        img: imagesview,
        level1: nextLevel,
      }));

      await View_Details(name, id);

      const isExpanded = clickedUsers[name]?.isExpanded || false;

      setClickedUsers((prevClickedUsers) => ({
        ...prevClickedUsers,
        [name]: {
          ...prevClickedUsers[name],
          children: isExpanded ? childrenNodes : prevClickedUsers[name]?.children || [],
          isExpanded: isExpanded,
        },
      }));

      if (isExpanded && nextLevel <= 8) {
        childrenNodes.forEach(async (child) => {
          const childNode = await child;
          await View_Details(child.name, child.id);
          await View_ActiveLevel(child.name, child.id, nextLevel, matrixName);
        });
      }


      return childrenNodes;
    } catch (err) {
      console.log("Error fetching downlines_downline data:", err);
    }
  };



  const View_Details = async (name, id) => {
    const postdata = {
      jwt: jwt,
      username: name,
      matrix_name: clickedButton
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_Matrix + "view_downlines_sponsor",
      data: postdata,
    }).then(async (response) => {
      const responseData = response.data.Success;

      if (responseData) {
        setClickedUsers((prevClickedUsers) => ({
          ...prevClickedUsers,
          [name]: {
            ...prevClickedUsers[name],
            details: responseData,
            hasData: true,
          },
        }));
      }

    }).catch((err) => {
      console.log(err?.response?.data?.Error);
    });
  };

  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
    levelCount
  }) => {
    const userDetails = clickedUsers[nodeDatum.name]?.details || {};
    const activeButtonColor = buttonColors[clickedButton]?.to;
    const activeButtonColorbb = buttonColors[clickedButton]?.from;

    if (nodeDatum.level === 1 && loading) {
      return (
        <g>
          <foreignObject {...foreignObjectProps}>
            <div className='bg-white border-gradient h-40 flex justify-center items-center'>
              <Spin size="large" />
            </div>
          </foreignObject>
        </g>
      );
    }

    if (nodeDatum.level >= 1) {
      return (
        <g>
          <foreignObject {...foreignObjectProps}>
            <div className={`text-white font-bold rounded-lg bg-${activeButtonColor}`} style={{ border: "1px solid black", backgroundColor: activeButtonColor }}>
              <div className="flex justify-end text-center mx-2">
                <div>Level {nodeDatum.level}</div>
              </div>
              <h3 className='text-2xl text-center'>{nodeDatum.name}</h3>
              {clickedUsers[nodeDatum.name] && clickedUsers[nodeDatum.name].details && (
              <div>
                <p className='text-xl my-2 text-center'>Sponsor: {clickedUsers[nodeDatum.name].details}</p>
              </div>
            )}
              {nodeDatum.children && (
                <>
                  <button className='border border-black bg-[#0c0a44] mt-2 rounded-b-lg' style={{ width: "100%" }} onClick={() => handleNodeToggle(nodeDatum, toggleNode)}>
                    {nodeDatum.isExpanded ? "Collapse" : "Expand"}
                  </button>
                  {/* {nodeDatum.children.length > 0 ? (
                        <button className='border border-black mt-2 rounded-b-lg' style={{ width: "100%" }} onClick={() => handleNodeToggle(nodeDatum, toggleNode)}>
                          {nodeDatum.isExpanded ? "Collapse" : "Expand"}
                        </button>
                      ) : (
                        <div className="text-center mt-2 text-gray-500">No Children</div>
                      )} */}
                  {nodeDatum.children.map((childNode, index) => (
                    <div key={index}>
                      {childNode.hasData && (
                        <>

                          {childNode.children && childNode.children.length > 0 ? (
                            <button className='border border-black bg-[#0c0a44]' style={{ width: "100%" }} onClick={() => handleNodeToggle(childNode, toggleNode)}>
                              {childNode.isExpanded ? "Collapse" : "Expand"}
                            </button>
                          ) : (
                            <div className="text-center mt-2 text-gray-500">No Downline</div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </foreignObject>
        </g>
      );
    }

    return (
      <g>
        <foreignObject {...foreignObjectProps}>
          <div className={`text-white font-bold rounded-lg border-${activeButtonColor}`} style={{ border: "1px solid black", backgroundColor: activeButtonColor }}>
            <div className="flex justify-end text-center mx-2">
              <div>Level {nodeDatum.level}</div>
            </div>
            <h3 className='text-2xl text-center'>{nodeDatum.name}</h3>
            {clickedUsers[nodeDatum.name] && clickedUsers[nodeDatum.name].details && (
              <div>
                <p className='text-xl my-2 text-center'>Sponsor: {clickedUsers[nodeDatum.name].details}</p>
              </div>
            )}
            {nodeDatum.children && nodeDatum.children.length > 0 ? (
              <>
                <button className='border border-black mt-2 bg-[#0c0a44] rounded-b-lg' style={{ width: "100%" }} onClick={() => handleNodeToggle(nodeDatum, toggleNode)}>
                  {nodeDatum.isExpanded ? "Collapse" : "Expand"}
                </button>
                {nodeDatum.children.map((childNode, index) => (
                  <div key={index}>
                    {childNode.hasData && (
                      <>
                        {childNode.children && childNode.children.length > 0 ? (
                          <button className='border border-black bg-[#0c0a44] ' style={{ width: "100%" }} onClick={() => handleNodeToggle(childNode, toggleNode)}>
                            {childNode.isExpanded ? "Collapse" : "Expand"}
                          </button>
                        ) : (
                          <div className="text-center mt-2 text-white">No Downline</div>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <div className="border border-black rounded-b-lg text-center mt-2 text-white">No Downline</div>
            )}
          </div>
        </foreignObject>
      </g>
    );
  };


  const createNestedDataNodes = async (data, parentId, level) => {
    const nestedDataNodes = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (typeof value === "object") {
          const nestedNodes = await createNestedDataNodes(value, parentId, level + 1);
          nestedDataNodes.push(...nestedNodes);
        } else {
          nestedDataNodes.push({
            name: `${key}: ${value}`,
            id: parentId + nestedDataNodes.length + 1,
            pid: parentId,
            title: `Level ${level}`,
            img: imagesview,
            level1: level,
          });
        }
      }
    }
    return nestedDataNodes;
  };

  const [fetchedUsernames, setFetchedUsernames] = useState([]);

  const handleUserClick = async (userName, id, level) => {
    if (!fetchedUsernames.includes(userName)) {
      await View_ActiveLevel(userName, id, level, clickedButton);
      setFetchedUsernames([...fetchedUsernames, userName]);
    }
  };

  const buttonOrder = ['BOT1000', 'BOT3000', 'BOT5000', 'BOT10000'];
  const activeButtons = Object.keys(Quali).filter(card => Quali[card]);
  let buttonsToRender = activeButtons.length > 0 ? activeButtons : buttonOrder;
  const firstTrueButton = buttonsToRender.find(card => Quali[card] === true);

  const [clickedButton, setClickedButton] = useState(buttonsToRender[0]);

  useEffect(() => {
    if (firstTrueButton !== undefined) {
      setClickedButton(firstTrueButton);
    }
  }, [Quali]);

  const nodeSize = { x: 200, y: 200 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -0.5 * nodeSize.x,
    y: -0.5 * nodeSize.y
  };


  const createOrgChartNode = (item, level) => {
    if (level > 8) return null;

    return {
      name: item.name,
      title: `Level ${level}`,
      img: item.img,
      isExpanded: level < 8 && (clickedUsers[item.name]?.isExpanded || false), // Expand only if level is less than 8
      hasData: item.hasData,
      children: level < 8 && clickedUsers[item.name]?.children?.map((childItem) =>
        createOrgChartNode(childItem, level + 1)
      ).filter(Boolean),
      level: level
    };
  };


  const createOrgChart = () => {
    const orgChartNodes = downline.map((item) => createOrgChartNode(item, 1));
    return {
      name: profile.Username,
      sponsor: profile.Sponsor,
      children: orgChartNodes,
    };
  };

  const orgChartJson = createOrgChart();

  const handleNodeToggle = async (nodeDatum, toggleNode) => {
    const { name, id, level } = nodeDatum;
    const updatedClickedUsers = { ...clickedUsers };
    const updatedNode = updatedClickedUsers[name];

    if (updatedNode) {
    updatedNode.isExpanded = !updatedNode.isExpanded;

      try {
        if (!updatedNode.dataFetched) {
          const childrenNodes = await View_ActiveLevel(name, id, level, clickedButton);

          setClickedUsers((prevClickedUsers) => ({
            ...prevClickedUsers,
            [name]: {
              ...prevClickedUsers[name],
              children: childrenNodes,
              dataFetched: true,
            },
          }));
        }
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    }

    toggleNode(nodeDatum);
  };

  useEffect(() => {
    matrix?.forEach((item) => {
      View_Level(item?.Matrix_Name);
    });
  }, [matrix, clickedButton]);

  useEffect(() => {
    const trueCards = Object.keys(Quali).filter(key => Quali[key] === true);
    setShowButtons(trueCards.includes('BOT5000'));
  }, [Quali]);


  const handleButtonClick = (matrixName) => {
    // View_Level(matrixName);
    setClickedButton(matrixName);
    setDownline([]);

  };

 

  return (
    <>
      <div className='text-2xl text-center font-bold'>Active Matrix</div>
      {/* {showButtons && ( */}
        <div>
          <div className='grid grid-cols-4 mx-10 gap-3 my-2 py-2 border-b border-[#ccc]'>
            {buttonOrder?.map((card, index) => (
              <div key={index} className="relative">
                <button
                  className={`w-full py-3 rounded-lg font-bold px-10 ${clickedButton === card ? ' border-b-4 border-black text-white ' : ''} `}
                  // style={{
                  //   backgroundImage: `linear-gradient(to right, ${buttonColors[card].from}, ${buttonColors[card].to})`
                  // }}
                  style={{
                    backgroundImage: `linear-gradient(to right, ${
                      clickedButton === card ? buttonColors[card].from : '#cccccc'
                    }, ${
                      clickedButton === card ? buttonColors[card].to : '#cccccc'
                    })`
                  }}
                  
                  onClick={() => handleButtonClick(card)}
                >
                  {card}
                </button>
                {!Quali || Quali[card] === false && (
                  <div className="absolute h-12 rounded-lg cursor-not-allowed inset-0 flex items-center justify-center bg-black bg-opacity-40 text-white">
                    <IoIosLock size={20} />
                  </div>
                )}
              </div>
            ))}
          

          </div>
        </div>
      {/* )} */}

{loading ? ( // Conditionally render Spin if loading is true
            <div className="text-center mt-5">
                <Spin size="large" />
            </div>
        ) : (
            <>

      {buttonsDisplayed && (
        <div style={containerStyles} ref={containerRef}>
          <Tree
            depthFactor={300}
            separation={{ nonSiblings: 2, siblings: 2 }}
            data={orgChartJson}
            translate={translate}
            nodeSize={nodeSize}
            renderCustomNodeElement={(rd3tProps) =>
              renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
            }
            orientation="vertical"
          />
        </div>
      )}
      </>
      )}

    </>
  );
};

export default MatrixTwo;
