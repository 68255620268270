import axios from "axios";
import React, { useEffect, useState } from "react";
import RankQualTrans from "./RankQualTrans";

function RankCurrent(props) {
  const { rankqualData, loading, pageSize, page, totalPages, setPage, setpageSize } =
    props.data;
  const [rank, setRank] = useState([]);
  const [Quali, setQuali] = useState("");


  const getRank = () => {
    axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PUBLIC + "rank",
    })
      .then((res) => {
        setRank(res.data.Success);
      })
      .catch((err) => console.log(err));
  };

  const View_QualiRank = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH + "/rank/current_rank",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response.data;
        setQuali(res.Success.Rank);

      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getRank();
    View_QualiRank();
  }, []);



  return (
    <>
      <div>
        {rank.map((item, idx) => (
          item.Rank === Quali ? (
            <div className="flex flex-col items-center mt-5" key={idx}>
              <img
                src={`/images/TableFull & Final/Rank/${item.Image.replace("png", "webp")}`}
                alt={`item-image-${item.ID}`}
              />
            </div>
          ) : null
        ))}
        {rank.every(item => item.Rank !== Quali) && (
          <div className="text-center text-gray-500 mt-5">
            No qualified rank
          </div>
        )}
      </div>


      {/* <div>
  {rank?.map((items, idx) => (
          <div className="flex flex-col items-center mt-5">
            <img
              src={`/images/Full & Final/${path}/${items.Image.replace("png", "webp")}`}
              alt={`item-image-${items}`}
            />
          </div>
        ) : (
          <div className="text-center text-gray-500 mt-5">
            No qualified rank
          </div>
        ))}
      </div> */}

      {/* <div>
       
          <div className="flex flex-col items-center mt-5">
            <img
              src={`/images/TableFull & Final/Rank/${Quali?.replace(
                "png",
                "webp"
              )}`}
              alt={`item-image-${Quali.ID}`}
            />
          </div>
        ) : (
          <div className="text-center text-gray-500 mt-5">
            No qualified rank
          </div>
      </div> */}

      <RankQualTrans
        data={{
          rankqualData: rankqualData,
          page: page,
          loading: loading,
          pageSize: pageSize,
          totalPages: totalPages,
          setPage: setPage,
          setpageSize: setpageSize,
        }}
      />
    </>
  );
}

export default RankCurrent;
