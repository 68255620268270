import React, { useState } from "react";
import Botmainpagecopy from "./Botmainpagecopy";
import BotmainpagecopyTwo from "./BotmainpagecopyTwo";
import ActiveBot from "./ActiveBot";
import Reportcopy from "./Reportcopy";

function ActiveSwitch() {
  const [activeView, setActiveView] = useState("grid");

  const handleGridClick = () => {
    setActiveView("grid");
  };

  const handleListClick = () => {
    setActiveView("list");
  };

  return (
    <div>
    

      {activeView === "grid" ? (
        <ActiveBot data={{ handleGridClick, handleListClick }} />
      ) : (
        <Reportcopy data={{ handleGridClick, handleListClick }} />
      )}
    </div>
  );
}

export default ActiveSwitch;
