import React, { useEffect, useState } from "react";
import { IoIosLock } from "react-icons/io";

const Carousel = ({ rank, Quali, current, path, type }) => {

  const [carouselInView, setCarouselInView] = useState([3, 4, 5, 6, 7]);

  useEffect(() => {
    if (current) {
      const index = rank.findIndex((item) => type == "Level" ? item.Level === current.Level : item.Rank === current.Rank);
      if (index !== -1) {
        const updatedInView = [3, 4, 5, 6, 7].map((val) => {
          if (val === 5) return index + 1;
          else if (val === index + 1) return 3;
          else return val;
        });
        setCarouselInView(updatedInView);
      }
    }
  }, [current, rank]);

  const previous = () => {
    const updatedInView = carouselInView.map((index) =>
      index === 1 ? rank.length : index - 1
    );
    setCarouselInView(updatedInView);
  };

  const next = () => {
    const updatedInView = carouselInView.map((index) =>
      index === rank.length ? 1 : index + 1
    );
    setCarouselInView(updatedInView);
  };

  return (
    <>
      <div className="carousel relative">
        <div className="carousel-controls absolute z-40 left-0 top-1/2 transform -translate-y-1/2">
          <button className="carousel-control carousel-control-previous" onClick={previous}></button>
        </div>
        <div className="carousel-container z-10">
          {rank?.map((items, idx) => (
            <div
              key={items.ID}
              className={`carousel-item  carousel-item-${carouselInView.indexOf(idx + 1) + 1
                } ${[2, 4].includes(idx + 1) ? "carousel-item-lg" : ""}`}
            >
              <img
                src={`/images/Full & Final/${path}/${items.Image.replace("png", "webp")}`}
                alt={`item-image-${items.ID}`}
                loading="lazy"
                data-index={idx + 1}
              />

              {Quali &&
                !Quali[`${type === "Level" ? items.Level : items.Rank}`] &&
                current &&
                (type === "Level" ? current.Level !== items.Level : items.Rank !== current.Rank) && (
                  <div className="absolute inset-0 text-white flex items-center bg-blur backdrop-blur-sm bg-white/30 justify-center">
                    <IoIosLock size={50} />
                  </div>
                )}

              {current && type === "Level" ? current.Level === items.Level : current.Rank === items.Rank && (
                <div className="absolute inset-0 rounded-2xl pointer-events-none"></div>
              )}



              {Quali &&
                !Quali[`${items.Rank}`] &&
                current &&
                current.Rank !== items.Rank && (
                  <div className="absolute inset-0 text-white flex items-center bg-blur backdrop-blur-sm bg-white/30 justify-center">
                    <IoIosLock size={50} />
                  </div>
                )}
              {current && current.Rank === items.Rank && (
                <div className="absolute inset-0   rounded-2xl pointer-events-none"></div>
              )}
            </div>
          ))}
        </div>
        <div className="carousel-controls absolute z-40 right-0 top-1/2 transform -translate-y-1/2">
          <button className="carousel-control carousel-control-next" onClick={next}></button>
        </div>
      </div>
    </>
  );
};

export default Carousel;
