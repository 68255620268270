import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0.3, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
    },
  },
};
const menuItemAnimation = {
  hidden: (i) => ({
    padding: 0,
    x: "-100%",
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
  show: (i) => ({
    x: 0,
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
};

const SidebarMenu = ({ route, showAnimation, openSide, setopenSide }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setopenSide(true);
  };

  

  useEffect(() => {
    if (!openSide) {
      setIsMenuOpen(false);
    }
  }, [openSide]);

  
  
  return (
    <>
      <div className={` ${isMenuOpen ? "border-gradient rounded-lg" : ""}`}>
        <div className="menu" onClick={toggleMenu}>
          <div className="menu_item">
            <div className="icon ">{route.icon}</div>
            <AnimatePresence>
              {openSide && (
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="link_text"
                >
                  {route.name}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          {openSide && (
            <motion.div
              animate={
                isMenuOpen
                  ? {
                      rotate: -90,
                    }
                  : { rotate: 0 }
              }
            >
              <FaAngleDown />
            </motion.div>
          )}
        </div>
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              variants={menuAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="menu_container"
            >
              {route.subRoutes.map((subRoute, i) => (
                <motion.div variants={menuItemAnimation} key={i} custom={i}>
                  <NavLink to={subRoute.path} className="link ">
                    <div className="icon ">{subRoute.icon}</div>
                    <motion.div className="link_text">
                      {subRoute.name}
                    </motion.div>
                  </NavLink>
                </motion.div>
              ))}
            </motion.div>
          )}{" "}
        </AnimatePresence>
      </div>
    </>
  );
};

export default SidebarMenu;
