import axios from "axios";
import React, { useEffect, useState } from "react";
import MatrixTableData from "../Components/MatrixTableData";
import { Carousel } from "antd";
// import Carousel from 'react-carousel';


function MatrixTran(props) {
  const { martixQualData, pageSize, page, totalPages, setPage, setpageSize } =
    props.data;
  const [matrix, setMatrix] = useState([]);
  const [current, setCurrent] = useState({});

  const getMatrix = () => {
    axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PUBLIC + "matrix",
    })
      .then((res) => {
        setMatrix(res.data.Success);
      })
      .catch((err) => console.log(err));
  };


  const MatrixQual = async (matrixName) => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      matrix_name: matrixName,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_Matrix + "matrix_qualified",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response.data;
        setCurrent((prevState) => ({
          ...prevState,
          [matrixName]: response.data.Success,
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


  useEffect(() => {
    matrix.forEach((item) => {
      MatrixQual(item.Matrix_Name);
    });
  }, [matrix]);

  useEffect(() => {
    getMatrix();
  }, []);

  const filteredRank = matrix.filter(
    (item) => current[item.Matrix_Name] === true
  );

  return (
    <>
   
      <div>
        {filteredRank.length > 0 ? (
          <div lassName="flex  items-center justify-center my-3 w-full">
          <Carousel autoplay autoplaySpeed={5000}>
          {filteredRank?.map((item) => (
            <div key={item.ID} className="">
            <img
              
              src={`/images/TableFull & Final/Matrix/${item.Image.replace(
                "png",
                "webp"
              )}`}
              alt={`item-image-${item.ID}`}
            />
            </div>
          ))}
          
      </Carousel>
      </div>
        ) : (
          <div className="text-center text-gray-500 mt-5">
            No qualified matrix
          </div>
        )}
      </div>
      <MatrixTableData
        data={{
          martixQualData: martixQualData,
          page: page,
          pageSize: pageSize,
          totalPages: totalPages,
          setPage: setPage,
          setpageSize: setpageSize,
        }}
      />
    </>
  );
}

export default MatrixTran;
