import { Spin, Table } from "antd";
import React from "react";

function DirectReferralTable(props) {
  const { subData, loading } = props.data;

  const columns = [
    {
      title: "USERNAME",
      dataIndex: "Username",
      key: "Username",
      align: "center",
      width: 150,
    },
    {
      title: "RANK",
      dataIndex: "Rank",
      key: "Rank",
      align: "center",
      width: 100,
    },
    {
      title: "Level",
      dataIndex: "Level",
      key: "Level",
      align: "center",
      width: 100,
    },
    {
      title: "HIGHEST PACKAGE",
      dataIndex: "Highest_Subscription",
      key: "Highest_Subscription",
      align: "center",
      width: 150,
    },
    {
      title: "PACKAGE VOLUME",
      dataIndex: "Subscription_Volume",
      key: "Subscription_Volume",
      align: "center",
      width: 150,
    },
    {
      title: "TOTAL TEAM",
      dataIndex: "Total_Team",
      key: "Total_Team",
      align: "center",
      width: 150,
    },
    {
      title: "TEAM BUSINESS",
      dataIndex: "Total_Team_Business",
      key: "Total_Team_Business",
      align: "center",
      width: 150,
    },
    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      align: "center",
      render: (text) => (
        <span style={{ color: text ? 'green' : 'red' }}>
          {text ? "Active" : "Inactive"}
        </span>
      ),
      width: 100,
    },
  ];

  const rows = subData?.map((data, index) => ({
    key: data.id || index,
    Username: data.Username,
    Rank: data.Rank,
    Level: data.Level,
    Highest_Subscription: data.Highest_Subscription,
    Subscription_Volume: data.Subscription_Volume,
    Total_Team: data.Total_Team,
    Total_Team_Business: data.Total_Team_Business,
    Status: data.Status,
  }));

  const onChange = (pagination, filters, sorter, extra) => { };

  return (
    <div className="w-full bg-colorPrimary">
      <Spin spinning={loading} size="large">
        <Table
          className="table-newres mb-2"
          columns={columns}
          dataSource={rows}
          onChange={onChange}
          pagination={false}
          scroll={{ x: 'max-content', y: 500 }} // Horizontal scroll if content exceeds container width
        />
      </Spin>
    </div>
  );
}

export default DirectReferralTable;
