import React, { useState } from "react";
import { Button, Modal } from "antd";
import axios from "axios";
import { TbBrandTelegram } from "react-icons/tb";
import { AiOutlineArrowDown } from "react-icons/ai";

const TelegramModel = ({ isModalOpen, handleCancel1, handleOk1 }) => {

const telegram = async () => {
  const jwt = localStorage.getItem("jwt");
  const postdata = {
    project: process.env.REACT_APP_PROJECT,
    jwt: jwt,
  };

  const response = await axios({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    url: process.env.REACT_APP_API_PATH_BOT_TELE + "generate_telegram_link",
    data: postdata,
  })
    .then(async function (response) {
      const res = await response?.data.Success;
      window.open(res, "_blank");
    })
    .catch((err) => {
      console.log(err?.response?.data?.Error);
    });
};


  return (
    <>
      <Modal
        title="Telegram ID Update"
        className="tect-center"
        width={360}
        open={isModalOpen}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={false}
      >
        <p>
          - Before you dive into API or Copy Bot action, give your Telegram bot
          a power-up.
        </p>
        <p>- Make sure it's enabled for real-time alerts and notifications.</p>

        <div className="flex justify-center items-center mt-4">
          <p>Click this button</p>
          <div className="transition-transform transform -translate-y-2 animate-bounce">
            <AiOutlineArrowDown />
          </div>
        </div>

        <div className="flex justify-center ">
          <div>
            <button
              onClick={telegram}
              className="text-sm   text-neonPurple border-2 border-neonPurple px-2 py-1 rounded-lg"
            >
              <div className="flex justify-center items-center gap-1">
                <TbBrandTelegram className="text-[#9461fd]" />
                Telegram
              </div>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default TelegramModel;
