import React, { useEffect, useState } from 'react'
import { Drawer, Button, Checkbox, Form, Input, Collapse, Switch, Slider, Radio } from 'antd'
import { CloseOutlined } from '@ant-design/icons';
import { CaretDownOutlined } from '@ant-design/icons';
import { PiInfo } from "react-icons/pi";
import { MdOutlineSettingsInputComponent } from "react-icons/md";
import image1 from "../assets/CoinsNew/BTCUSDT.png";
import image2 from "../assets/CoinsNew/BNBUSDT.png";
import image3 from "../assets/CoinsNew/ETHUSDT.png";
import image4 from "../assets/CoinsNew/DOGEUSDT.png";
import image5 from "../assets/CoinsNew/GALAUSDT.png";
import image6 from "../assets/CoinsNew/OCEANUSDT.png";
import image7 from "../assets/CoinsNew/ADAUSDT.png";
import image8 from "../assets/CoinsNew/SOLUSDT.png";
import image9 from "../assets/CoinsNew/MANAUSDT.png";
import axios from 'axios';
import { toast } from 'react-toastify';


const CopybotDrawer = (props) => {
    const [secretKeyError, setSecretKeyError] = useState()
    const [sliderValue, setSliderValue] = useState(0);
    const [selectedRisk, setSelectedRisk] = useState("MEDIUM");
    const [coinImg, setCoinImg] = useState()
    const [exchange, setExchange] = useState([])
    const [selectedOption, setSelectedOption] = useState("");
    const [apiNames, setApiNames] = useState([])
    const [selectedAPi, setSElectedApi] = useState('')
    const [isChecked, setIsChecked] = useState(false);
    const [OTO, setOTO] = useState("MARKET")
    const [OTC, setOTC] = useState("MARKET")
    const [OTOL, setOTOL] = useState("MARKET")
    const [OTCL, setOTCL] = useState("MARKET")
    const [OTOS, setOTOS] = useState("MARKET")
    const [OTCS, setOTCS] = useState("MARKET")
    const [isOpen, setIsOpen] = useState(false);
    const [form] = Form.useForm();

    console.log("hurryy", selectedAPi, selectedOption, selectedRisk)

    const { open, setOpen, copyBot } = props.data

    const sliderValues = {
        SPOT: { LOW: 1270, MEDIUM: 1135, HIGH: 250 },
        FUTURES: { LOW: 127, MEDIUM: 113.5, HIGH: 25 },
    };

    const marks = {
        0: {
            label: "LOW",

            style: {
                color: "#fff"
            },
        },
        50: {
            label: "MEDIUM",

            style: {
                color: "#fff"
            },
        },
        100: {
            label: "HIGH",

            style: {
                color: "#f00",
            },
        },
    };

    const handleSliderChange = (value) => {
        setSliderValue(value);

        if (value === 50) {
            setSelectedRisk("MEDIUM");
        } else if (value === 100) {
            setSelectedRisk("HIGH");
        } else {
            setSelectedRisk("LOW");
        }
    };

    const initialCount = copyBot && sliderValues[copyBot?.Trade_Type][selectedRisk];
    const [count, setCount] = useState(initialCount);

    useEffect(() => {
        const updatedValue = copyBot && sliderValues[copyBot?.Trade_Type][selectedRisk];
        setCount(updatedValue);
    }, [selectedRisk, copyBot]);

    const onClose = () => {
        setOpen(false);
        form.resetFields();
    };

    const onFinish = (values) => {
        const jwt = localStorage.getItem('jwt')
        const postData = {
          project: process.env.REACT_APP_PROJECT,
          jwt: jwt,
          bot_id: copyBot?.VarBotID,
          api_name: selectedAPi,
          invest_type: selectedRisk,
          amount: count,
          exchange_type: copyBot?.Trade_Type,
          exchange: selectedOption,
          telegram_alert: isChecked,
          base: Number(""),
          basel: Number(""),
          bases: Number(""),
          order_count: Number(""),
          order_countl: Number(""),
          order_counts: Number(""),
          vol_mul: Number(""),
          vol_mull: Number(""),
          vol_muls: Number(""),
          lev: Number(""),
          order_type_close: "MARKET",
          order_type_openl: "MARKET",
          order_type_open: "MARKET",
          order_type_closel: "MARKET",
          order_type_opens: "MARKET",
          order_type_closes: "MARKET",
          take_profit_percent: Number(""),
          loss_percent: Number(""),
          loss_percent_mul: Number(""),
          take_profit_percentl: Number(""),
          loss_percentl: Number(""),
          loss_percent_mull: Number(""),
          take_profit_percents: Number(""),
          loss_percents: Number(""),
          loss_percent_muls: Number(""),
        };

        const postDataTwo = {
          project: process.env.REACT_APP_PROJECT,
          jwt: jwt,
          bot_id: copyBot?.VarBotID,
          api_name: selectedAPi,
          invest_type: selectedRisk,
          amount: count,
          exchange_type: copyBot?.Trade_Type,
          exchange: selectedOption,
          telegram_alert: isChecked,
          base: values?.base == undefined ? Number("") : parseInt(values?.base),
          basel:
            values?.basel == undefined ? Number("") : parseInt(values?.basel),
          bases:
            values?.bases == undefined ? Number("") : parseInt(values?.bases),
          order_count:
            values?.order_count == undefined
              ? Number("")
              : parseInt(values?.order_count),
          order_countl:
            values?.order_countl == undefined
              ? Number("")
              : parseInt(values?.order_countl),
          order_counts:
            values?.order_counts == undefined
              ? Number("")
              : parseInt(values?.order_counts),
          vol_mul:
            values?.vol_mul == undefined
              ? Number("")
              : parseInt(values?.vol_mul),
          vol_mull:
            values?.vol_mull == undefined
              ? Number("")
              : parseInt(values?.vol_mull),
          vol_muls:
            values?.vol_muls == undefined
              ? Number("")
              : parseInt(values?.vol_muls),
          lev: values?.lev == undefined ? Number("") : parseInt(values?.lev),
          take_profit_percent:
            values?.take_profit_percent == undefined
              ? Number("")
              : parseInt(values?.take_profit_percent),
          loss_percent:
            values?.loss_percent == undefined
              ? Number("")
              : parseInt(values?.loss_percent),
          loss_percent_mul:
            values?.loss_percent_mul == undefined
              ? Number("")
              : parseInt(values?.loss_percent_mul),
          take_profit_percentl:
            values?.take_profit_percentl == undefined
              ? Number("")
              : parseInt(values?.take_profit_percentl),
          loss_percentl:
            values?.loss_percentl == undefined
              ? Number("")
              : parseInt(values?.loss_percentl),
          loss_percent_mull:
            values?.loss_percent_mull == undefined
              ? Number("")
              : parseInt(values?.loss_percent_mull),
          take_profit_percents:
            values?.take_profit_percents == undefined
              ? Number("")
              : parseInt(values?.take_profit_percents),
          loss_percents:
            values?.loss_percents == undefined
              ? Number("")
              : parseInt(values?.loss_percents),
          loss_percent_muls:
            values?.loss_percent_muls == undefined
              ? Number("")
              : parseInt(values?.loss_percent_muls),
          order_type_open: OTO,
          order_type_close: OTC,
          order_type_openl: OTOL,
          order_type_closel: OTCL,
          order_type_opens: OTOS,
          order_type_closes: OTCS,
        };

        console.log("postDataTwo", postDataTwo)

        const response = axios({
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          url: process.env.REACT_APP_API_PATH_BOT + "create",
          data: values.length == 0 ? postData : postDataTwo,
        })
          .then((res) => {
            console.log(res);
            form.resetFields();
            setOpen(false);
            toast(res?.data?.Success);
          })
          .catch((err) => {
            console.log(err);
            toast.error(err?.response?.data?.Error);
          });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const customCloseIcon = (
        <CloseOutlined style={{ color: '#d9ad00', fontSize: 18 }} />
    );

    const generateImageUrl = (symbol) => {
        switch (symbol) {
            case "BTCUSDT":
                return setCoinImg(image1);
            case "BNBUSDT":
                return setCoinImg(image2);
            case "ETHUSDT":
                return setCoinImg(image3);
            case "DOGEUSDT":
                return setCoinImg(image4);
            case "SOLUSDT":
                return setCoinImg(image5);
            case "GALAUSDT":
                return setCoinImg(image6);
            case "OCEANUSDT":
                return setCoinImg(image7);
            case "ADAUSDT":
                return setCoinImg(image8);
            case "MANAUSDT":
                return setCoinImg(image9);

            default:
                return "";
        }
    };

    const toggleSwitch = () => {
        setIsChecked(!isChecked);
    };

    const getExchange = () => {
        const jwt = localStorage.getItem("jwt")

        const response = axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API_PATH_API + 'exchange_api_options',
            data: {
                project: process.env.REACT_APP_PROJECT,
                jwt: jwt,
                exchange_type: copyBot?.Trade_Type,
            }
        }).then(res => {
            console.log("getExchange", res?.data?.Success)
            setExchange(res?.data?.Success)
        }).catch(err => {
            console.log("getExchangeerr", err)
        })
    }

    const getApiName = () => {
        const jwt = localStorage.getItem("jwt")

        const postData = {
          project: process.env.REACT_APP_PROJECT,
          jwt: jwt,
          exchange: selectedOption,
          exchange_type: copyBot?.Trade_Type,
        };

        const response = axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API_PATH_API + 'apiname_options',
            data: postData
        }).then(res => {
            setApiNames(res?.data?.Success)
        }).catch(err => {
            console.log("apiNameerr", err)
        })
    }

    useEffect(() => {
        getApiName()
    }, [selectedOption])

    useEffect(() => {
        generateImageUrl(copyBot?.Symbol)
        getExchange()
        setSelectedRisk("MEDIUM")
        setSelectedOption("")
        setSElectedApi("")
        setIsChecked(false)
    }, [copyBot])

    const handleCollpase = () => {
        setIsOpen(!isOpen)
    }

    return (
      <div>
        <div>
          <Drawer
            title={<div className=" text-colorSecondary">Copy Bot</div>}
            width={1200}
            onClose={onClose}
            bodyStyle={{ background: "#161a1e" }}
            headerStyle={{
              background: "#161a1e",
              borderBottom: "2px solid #868e9e",
              color: "#fff",
            }}
            visible={open}
            closeIcon={customCloseIcon}
          >
            <div className=" text-colorSecondaryDark p-4">
              <Form
                name="basic"
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                {/* <div className=' grid grid-cols-2 gap-2'>
                                <div className=' col-span-1'>
                                    <Form.Item
                                        name="api_name"
                                        label={<div className='text-colorSecondaryDark text-base capitalize'>Api name :</div>}
                                        labelAlign='left' >
                                        <input
                                            id="api_name"
                                            className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            placeholder="invest type"
                                            name="api_name"
                                        />
                                        {secretKeyError && (
                                            <div className="text-red-500 px-3">{secretKeyError}</div>
                                        )}
                                    </Form.Item>

                                    <Form.Item
                                        name="invest_type"
                                        label={<div className='text-colorText text-base capitalize'>invest type :</div>}
                                        labelAlign='left' >
                                        <input
                                            id="invest_type"
                                            className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            placeholder="invest type"
                                            name="invest_type"
                                        />
                                        {secretKeyError && (
                                            <div className="text-red-500 px-3">{secretKeyError}</div>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className=' col-span-1'>
                                    <Form.Item
                                        name="amount"
                                        label={<div className='text-colorText text-base capitalize'>amount :</div>}
                                        labelAlign='left' >
                                        <input
                                            id="amount"
                                            className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            placeholder="Amount"
                                            name="amount"
                                        />
                                        {secretKeyError && (
                                            <div className="text-red-500 px-3">{secretKeyError}</div>
                                        )}
                                    </Form.Item>

                                    <Form.Item
                                        name="telegram_alert"
                                        label={<div className='text-colorText text-base capitalize'>telegram alert :</div>}
                                        labelAlign='left' >
                                        <input
                                            id="telegram_alert"
                                            className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            placeholder="telegram alert"
                                            name="telegram_alert"
                                        />
                                        {secretKeyError && (
                                            <div className="text-red-500 px-3">{secretKeyError}</div>
                                        )}
                                    </Form.Item>
                                </div>

                            </div>  */}

                <div className="rounded-lg text-white px-4 py-2 mb-2 border-colorText border-2 font-bold flex items-center justify-between">
                  <div className=" text-colorText">
                    <div className="flex items-end mb-2 justify-center gap-2">
                      {/* <BsRobot className="text-white text-4xl" /> */}
                      <div className="text-2xl font-bold  text-center">
                        {copyBot?.Name}
                      </div>
                    </div>
                    <div className="flex mt-2">
                      <div className="shadow-sm border-2 border-neonPurple text-[10px] px-3 font-bold py-1 rounded-full ">
                        {copyBot?.VarBotID}
                      </div>
                      <div className="shadow-sm border-2 border-neonPurple text-[10px] px-3 font-bold py-1 rounded-full ml-2 ">
                        {copyBot?.Trade_Type}
                      </div>
                    </div>
                  </div>
                  <div className="text-xs text-colorText w-7/12 flex items-center justify-between">
                    <div className="grid grid-cols-2 gap-6 justify-items-center items-end md:items-center w-2/4">
                      <div>
                        <p className=" text-center text-sm ">Select Exchange</p>
                        <select
                          id="selectOption"
                          className="bg-colorPrimary  boder-2 text-black border-colorText border-21 mt-1 px-2 md:px-4 py-1 rounded-full"
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.target.value)}
                        >
                          <option value="">Select...</option>
                          {exchange?.map((option) => (
                            <option
                              className="rounded-3xl"
                              key={option}
                              value={option}
                            >
                              {option}
                            </option>
                          ))}
                        </select>
                        {/* {exchangeError && (
                                                        <div className="text-red-500 text-xs">
                                                            Please select an exchange.
                                                        </div>
                                                    )} */}
                      </div>

                      <div>
                        <p className="text-center">Select API</p>

                        <select
                          id="selectOption2"
                          className="bg-colorPrimary text-black border-colorText border-21 mt-1 px-2 md:px-4  py-1 rounded-full"
                          value={selectedAPi}
                          onChange={(e) => {
                            setSElectedApi(e.target.value);
                          }}
                        >
                          <option value="">Select...</option>
                          {apiNames?.map((option) => (
                            <option
                              className="rounded-3xl"
                              key={option}
                              value={option}
                            >
                              {option}
                            </option>
                          ))}
                        </select>
                        {/* {optionError && (
                                                        <div className="text-red-500 text-xs">
                                                            Please select an API.
                                                        </div>
                                                    )} */}
                      </div>
                    </div>
                    <div>
                      <img src={coinImg} alt="coin" className="w-14" />
                      <p>Symbol</p>
                    </div>
                  </div>
                </div>

                <div className="relative -mt-6 overflow-hidden">
                  <div className="md:border-l-2 md:border-t-0 md:border-r-0 border-b-0 md:border-dashed md:border-colorSecondary ml-2 md:pl-6 justify-start mt-5 items-center flex flex-col w-full md:gap-5">
                    <div className=" w-4/5 mx-auto">
                      <div className=""></div>
                      {/* <p>{exchnagedata}</p> */}
                      <div className="my-2 bg-colorPrimary text-colorText px-3 flex justify-between  md:px-4 py-2 rounded-lg ">
                        <div className=" w-3/5">
                          <div className="flex items-center justify-between">
                            <div className="">Risks Matrix</div>
                            <div className="flex items-center">
                              <label className="mr-2  text-xs">
                                Telegram Alert:
                              </label>
                              <Switch
                                type="checkbox"
                                checked={isChecked}
                                onChange={toggleSwitch}
                                className={isChecked ? "off" : "on"}
                                checkedChildren="On"
                                unCheckedChildren="Off"
                              />
                            </div>
                          </div>
                          <Slider
                            value={sliderValue}
                            onChange={(value) => {
                              handleSliderChange(value);
                              setCount(value);
                            }}
                            step={null}
                            defaultValue={0}
                            marks={marks}
                          />
                        </div>
                        <div>
                          <div className=" flex justify-center items-center">
                            <div className="border-2 text-sm md:text-lg rounded-3xl p-1 md:p-0 md:px-7 border-[#ccc]/30 ">
                              Min Investment :
                              {copyBot &&
                                sliderValues[copyBot?.Trade_Type][
                                  selectedRisk
                                ]}{" "}
                              {/* Min Investment : */}
                              USDT
                            </div>
                          </div>
                          <div className="flex justify-center">
                            <p className=" text-red-500 font-medium mt-1">
                              Once Invested, amount cannot be changed
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="text-start mb-2 mx-auto">
                        <Input
                          id="count"
                          name="count"
                          type="text"
                          suffix="USDT"
                          className="Drawerinput bg-colorPrimary text-colorText "
                          value={count}
                          onChange={(event) => {
                            setCount(event.target.value);
                          }}
                          placeholder="Enter amount"
                        />
                      </div>

                      <button
                        className="text-colorText border-2 border-colorText text-colorSecondary p-1 px-5 rounded-xl font-semibold block mx-auto my-2"
                        onSubmit={onFinish}
                      >
                        NEXT
                      </button>
                    </div>
                  </div>
                </div>

                <Collapse
                  onChange={handleCollpase}
                  expandIcon={({ isActive }) => (
                    <MdOutlineSettingsInputComponent
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "20px",
                      }}
                    />
                  )}
                  items={[
                    {
                      key: "1",
                      label: (
                        <div className="flex justify-between">
                          <div className=" text-black">Advance Options</div>
                          {/* {
                                                    isOpen &&
                                                <button className='text-colorText border-2 border-colorText p-1 px-5 rounded-xl font-semibold' onSubmit={onFinish}>
                                                    NEXT
                                                </button>
                                                } */}
                        </div>
                      ),
                      contentBg: "",
                      children: (
                        <div className=" grid grid-cols-2 gap-10">
                          <div className=" col-span-1">
                            {copyBot?.Trade_Type == "SPOT" && (
                              <Form.Item
                                className=" mb-3"
                                name="order_type_open"
                                label={
                                  <div className="text-colorSecondaryDark text-sm capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>order type open</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <Radio.Group
                                  value={OTO}
                                  onChange={(e) => setOTO(e.target.value)}
                                  id="order_type_open"
                                  className=" text-colorSecondaryDark flex justify-around"
                                  defaultValue="MARKET"
                                >
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="LIMIT"
                                  >
                                    {" "}
                                    LIMIT{" "}
                                  </Radio>
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="MARKET"
                                  >
                                    {" "}
                                    MARKET{" "}
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "SPOT" && (
                              <Form.Item
                                className=" mb-3"
                                name="order_type_close"
                                value={OTC}
                                onChange={(e) => setOTC(e.target.value)}
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>order type close</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <Radio.Group
                                  id="order_type_close"
                                  className=" text-colorSecondaryDark flex justify-around"
                                  defaultValue="MARKET"
                                >
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="LIMIT"
                                  >
                                    {" "}
                                    LIMIT{" "}
                                  </Radio>
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="MARKET"
                                  >
                                    {" "}
                                    MARKET{" "}
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="order_type_openl"
                                value={OTOL}
                                onChange={(e) => setOTOL(e.target.value)}
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>order type open long</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <Radio.Group
                                  id="order_type_openl"
                                  className=" text-colorSecondaryDark flex justify-around"
                                  defaultValue="MARKET"
                                >
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="LIMIT"
                                  >
                                    {" "}
                                    LIMIT{" "}
                                  </Radio>
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="MARKET"
                                  >
                                    {" "}
                                    MARKET{" "}
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                value={OTCL}
                                onChange={(e) => setOTCL(e.target.value)}
                                name="order_type_closel"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>order type close long</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <Radio.Group
                                  id="order_type_closel"
                                  className=" text-colorSecondaryDark flex justify-around"
                                  defaultValue="MARKET"
                                >
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="LIMIT"
                                  >
                                    {" "}
                                    LIMIT{" "}
                                  </Radio>
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="MARKET"
                                  >
                                    {" "}
                                    MARKET{" "}
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="order_type_opens"
                                value={OTOS}
                                onChange={(e) => setOTOS(e.target.value)}
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>order type open short</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <Radio.Group
                                  id="order_type_opens"
                                  className=" text-colorSecondaryDark flex justify-around"
                                  defaultValue="MARKET"
                                >
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="LIMIT"
                                  >
                                    {" "}
                                    LIMIT{" "}
                                  </Radio>
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="MARKET"
                                  >
                                    {" "}
                                    MARKET{" "}
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="order_type_closes"
                                value={OTCS}
                                onChange={(e) => setOTCS(e.target.value)}
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>order type close short</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <Radio.Group
                                  id="order_type_closes"
                                  className=" text-colorSecondaryDark flex justify-around"
                                  defaultValue="MARKET"
                                >
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="LIMIT"
                                  >
                                    {" "}
                                    LIMIT{" "}
                                  </Radio>
                                  <Radio
                                    className=" text-colorSecondaryDark"
                                    value="MARKET"
                                  >
                                    {" "}
                                    MARKET{" "}
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="lev"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>leverage</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="lev"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="leverage"
                                  name="lev"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "SPOT" && (
                              <Form.Item
                                className=" mb-3"
                                name="base"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>span</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="base"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="base"
                                  name="base"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="basel"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>Base long</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="basel"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="base long"
                                  name="basel"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="bases"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>Base short</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="bases"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="bases short"
                                  name="bases"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "SPOT" && (
                              <Form.Item
                                className=" mb-3"
                                name="order_count"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>order count</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="order_count"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="order count"
                                  name="order_count"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="order_countl"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>order count long</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="order_countl"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="order count long"
                                  name="order_countl"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="order_counts"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center ">
                                    {" "}
                                    <span>order count short</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="order_counts"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="order count short"
                                  name="order_counts"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}
                          </div>
                          <div className=" col-span-1">
                            {copyBot?.Trade_Type == "SPOT" && (
                              <Form.Item
                                className=" mb-3"
                                name="vol_mul"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>Volume Multiplier</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="vol_mul"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="volume Multiplier"
                                  name="vol_mul"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="vol_mull"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>Volume Multiplier long</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="vol_mull"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="volume Multiplier long"
                                  name="vol_mull"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="vol_muls"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    <span>volume multiplier short</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="vol_muls"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="volume multiplier short"
                                  name="vol_muls"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "SPOT" && (
                              <Form.Item
                                className=" mb-3"
                                name="take_profit_percent"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>take profit percent</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="take_profit_percent"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="take profit percent"
                                  name="take_profit_percent"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="take_profit_percentl"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>take profit percent long</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="take_profit_percentl"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="take profit percent long"
                                  name="take_profit_percentl"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="take_profit_percents"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>take profit percent short</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="take_profit_percents"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="take profit percent short"
                                  name="take_profit_percents"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "SPOT" && (
                              <Form.Item
                                className=" mb-3"
                                name="loss_percent"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>loss percent</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="loss_percent"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="loss percent"
                                  name="loss_percent"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="loss_percentl"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    <span>loss percent long</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="loss_percentl"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="loss percent long"
                                  name="loss_percentl"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="loss_percents"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    <span>loss percent short</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="loss_percents"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="loss percent short"
                                  name="loss_percents"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "SPOT" && (
                              <Form.Item
                                className=" mb-3"
                                name="loss_percent_mul"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    <span>loss percent multiplier</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="loss_percent_mul"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="loss percent multiplier"
                                  name="loss_percent_mul"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="loss_percent_mull"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    <span>loss percent Multiplier long</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="loss_percent_mull"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="loss percent Multiplier long"
                                  name="loss_percent_mull"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}

                            {copyBot?.Trade_Type == "FUTURES" && (
                              <Form.Item
                                className=" mb-3"
                                name="loss_percent_muls"
                                label={
                                  <div className="text-colorSecondaryDark text-base capitalize flex gap-2 items-center">
                                    {" "}
                                    <span>loss percent Multiplier short</span>
                                    <PiInfo
                                      size={20}
                                      className="cursor-help text-colorSecondaryDark  hover:scale-110 transition-all transition-5s"
                                    />
                                  </div>
                                }
                                labelAlign="left"
                              >
                                <input
                                  id="loss_percent_muls"
                                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-colorSecondaryDark leading-tight focus:outline-none focus:shadow-outline"
                                  type="text"
                                  placeholder="loss percent Multiplier short"
                                  name="loss_percent_muls"
                                />
                                {secretKeyError && (
                                  <div className="text-red-500 px-3">
                                    {secretKeyError}
                                  </div>
                                )}
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      ),
                    },
                  ]}
                  style={{ backgroundColor: "#59C3C3" }}
                />
              </Form>
            </div>
          </Drawer>
        </div>
      </div>
    );
}

export default CopybotDrawer
