import React, { useState , useEffect } from 'react'
import img from '../assets/Rank/1.jpg'
import { message } from 'antd'


const NationalCard = (props) => {
    console.log("props =>>" , props)
    
    const {formData , setFormData} = props?.data
    const [selectedFile, setSelectedFile] = useState({});

    console.log("selectedFile ==>>", Object.keys(selectedFile).length)
    console.log("formData", formData)

    const handleFileChangeFront = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'image/png' || 'image/jpg') {
            setSelectedFile(file); // Set the selected file

            setSelectedFile({
                ...selectedFile,
                Front: file
        })
        } else {
            console.error('Please select a PNG file.');
        }
    };

    const handleFileChangeBack = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'image/png' || 'image/jpg') {
            setSelectedFile(file); // Set the selected file

            setSelectedFile({ 
                ...selectedFile,
                Back: file
        })
        } else {
            console.error('Please select a PNG file.');
        }
    };

    
    useEffect(() => {
        // if(Object.keys(selectedFile).length == 2){
        //     setFormData({
        //         ...formData,
        //         document : selectedFile,
        //         document_name : "Driving License"
        //     })
        // }


        if (formData?.document_name === "Driving License" || formData?.document_name === "Passport") {
            message.error(`Use have alredy uploaded ${formData?.document_name} photo `)
        } else if (Object.keys(selectedFile).length == 2) {
            setFormData({
                ...formData,
                document: selectedFile,
                document_name: "National ID"
            })
        }

    },[selectedFile])

    


    return (
        <div>
            <div className=' mt-6'>
                <p className=' text-lg font-semibold'>To avoid delays when verifying account, Please make sure bellow:</p>
                <p className=' text-sm'>Chosen credential must not be expired.</p>
                <p className=' text-sm'>Document should be good condition and clearly visible.</p>
                <p className=' text-sm'>Make sure that there is no light glare on the card.</p>

                <p className=' text-sm text-red-400 pt-10'>Upload Here Your National ID Front Side</p>
                <div className=' grid grid-cols-4 gap-8'>
                    <div className=' col-span-3 flex items-center'>
                        <div className='flex items-center justify-center h-48 w-full bg-cyan-200 border-[1px] border-colorDark border-dashed rounded-xl'>
                            <input type="file" accept=".png" onChange={handleFileChangeFront} />
                        </div>
                    </div>
                    <div className=' col-span-1'>
                        <div>
                            <img src={img} className=' w-[250px] rounded-full ' />
                        </div>
                    </div>
                </div>
                <p className=' text-sm text-red-400 pt-10'>Upload Here Your National ID Back Side</p>
                <div className=' grid grid-cols-4 gap-8'>
                    <div className=' col-span-3 flex items-center'>
                        <div className='flex items-center justify-center h-48 w-full bg-cyan-200 border-[1px] border-colorDark border-dashed rounded-xl'>
                            <input type="file" accept=".png" onChange={handleFileChangeBack} />
                        </div>
                    </div>
                    <div className=' col-span-1'>
                        <div>
                            <img src={img} className=' w-[250px] rounded-full ' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NationalCard
