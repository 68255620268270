import { Empty, Tabs } from "antd";
import React, { useEffect, useState, useRef } from "react";
import "../assets/css/style.css";
import axios from "axios";
import TradesTable from "../Components/TradesTable";
import { toast } from "react-toastify";
import FilterComponent from "./FilterComponent";
import { LuInbox } from "react-icons/lu";


function Trades() {
  const [filterData, setfilterData] = useState([]);
  const [filterbotData, setfilterBotData] = useState([]);
  const [typedata, setTypeData] = useState([]);
  const [apiname, setApiname] = useState([]);
  const [exchange, setexchange] = useState([]);
  const [botname, setbotname] = useState([]);
  const [filterexchange, setFilterExchange] = useState("FUTURES");
  const [filter2, setFilter2] = useState("ALL");
  const [showModal, setShowModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [originalSelectedOptions, setOriginalSelectedOptions] = useState([]);
  const [pendingChanges, setPendingChanges] = useState(false);

  console.log("filterexchange ==>>" , filterexchange)

  const initialSelectedFilters = {
    Symbol: filterData,
    BotName: filterbotData,
    Exchange_Type: "ALL",
  };

  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);

  const [showOptions, setShowOptions] = useState(false);
  const [showOptions1, setShowOptions1] = useState(false);


  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setShowOptions(false);
  //     }
  //   };
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [showOptions]);


  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       dropdownRef1.current &&
  //       !dropdownRef1.current.contains(event.target)
  //     ) {
  //       setShowOptions1(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [showOptions1]);

  useEffect(() => {
    setSelectedOptions(filterData);
  }, [filterData]);

  //  useEffect(() => {
  //    handleFilter("Exchange_Type");
  //  }, []);

  useEffect(() => {
    setFilterExchange(filterexchange);
  }, [filterexchange]);

  useEffect(() => {
    setSelectedOptions1(filterbotData);
  }, [filterbotData]);

  useEffect(() => {
    handleFilterClick("ALL");
  }, []);


  //  const handleOptionSelect = (option) => {
  //    if (selectedOptions.includes(option)) {
  //      setSelectedOptions(
  //        selectedOptions.filter((selectedOption) => selectedOption !== option)
  //      );
  //    } else {
  //      setSelectedOptions([...selectedOptions, option]);
  //    }
  //  };

  // const handleOptionSelect = (option) => {
  //   if (selectedOptions.includes(option)) {
  //     // Uncheck the option after a delay
  //     setTimeout(() => {
  //       setSelectedOptions((prevOptions) =>
  //         prevOptions.filter((selectedOption) => selectedOption !== option)
  //       );
  //     }, 0); // You can adjust the delay as needed
  //   } else {
  //     setSelectedOptions([...selectedOptions, option]);
  //   }

  //   // Set pendingChanges to true when options are selected/unselected
  //   setPendingChanges(true);
  // };

  const handleOptionSelect = (option) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((selectedOption) => selectedOption !== option)
      : [...selectedOptions, option];

    setSelectedOptions(updatedOptions);
    setPendingChanges(true);
  };


  const handleOptionSelect1 = (option) => {
    if (selectedOptions1.includes(option)) {
      setSelectedOptions1(
        selectedOptions1.filter((selectedOption) => selectedOption !== option)
      );
    } else {
      setSelectedOptions1([...selectedOptions1, option]);
    }
  };



  const handleFilter = (filterType) => {
    const jwt = localStorage.getItem("jwt");
    let postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      page_name: "Trades",
      filter_type: filterType,
    };

    if (filterType === "API_Name") {
      postData.filter_type = "API_Name";
    } else if (filterType === "Exchange") {
      postData.filter_type = "Exchange";
    } else if (filterType === "Symbol") {
      postData.filter_type = "Symbol";
    } else if (filterType === "VarBotID") {
      postData.filter_type = "VarBotID";
    } else if (filterType === "Exchange_Type") {
      postData.filter_type = "Exchange_Type";
    } else if (filterType === "Type") {
      postData.filter_type = "Type";
    } else if (filterType === "BotName") {
      postData.filter_type = "BotName";
    } else {
    }

    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_FIL + "filter",
      data: postData,
    })
      .then((res) => {
        if (filterType === "Symbol") {
          setfilterData(res?.data.Success || []);
          const updatedSelectedOptions = selectedOptions.filter((option) =>
            (res?.data.Success || []).includes(option)
          );
          setSelectedOptions(updatedSelectedOptions);
        } else if (filterType === "BotName") {
          setfilterBotData(res?.data.Success || []);
          const updatedSelectedOptions1 = selectedOptions1.filter((option) =>
            (res?.data.Success || []).includes(option)
          );
          setSelectedOptions1(updatedSelectedOptions1);
        } else if (filterType === "Exchange_Type") {
          // setFilterExchange(res.data.Success);
           setFilterExchange([res.data.Success[0]]);
        } else if (filterType === "Type") {
          setTypeData(res.data.Success);
        } else if (filterType === "API_Name") {
          setApiname(res.data.Success);
        } else if (filterType === "VarBotID") {
          setbotname(res.data.Success);
        } else if (filterType === "Exchange") {
          setexchange(res.data.Success);
        }
        toast(res?.data?.Success);
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err.response.data.Error);
      });
  };

  useEffect(() => {
    handleFilter("Symbol");
    handleFilter("Exchange_Type");
    handleFilter("BotName");
    handleFilter("VarBotID");
    handleFilter("Exchange");
    handleFilter("API_Name");
    handleFilter("Type");
  }, []);


  const handleFilterClick = (exchangeType) => {
    if (exchangeType === "ALL") {
      // setFilterExchange(["SPOT", "FUTURES"]);
      // handleFilter("Exchange_Type");
    } else {
      setFilterExchange([exchangeType]);
    }
    //  setIsSubmitActive(true);
    // setFilterExchange([exchangeType]);
  };

  const [originalSelectedOptions1, setOriginalSelectedOptions1] = useState([]);


  const handleCloseModal = () => {
    // Reset state if needed
    setSelectedOptions1([...originalSelectedOptions1]);
    setSelectedOptions([...originalSelectedOptions]);
    setShowModal(false);
  };


  useEffect(() => {
    setOriginalSelectedOptions([...selectedOptions]);
    setOriginalSelectedOptions1([...selectedOptions1]);
  }, [showModal]);




  const handleModalSubmit1 = () => {
    // Check if filters are selected
    // const exchangeFilter = filterexchange !== "ALL" ? [filterexchange] : [];
    // const symbolFilter = selectedOptions.length > 0 ? selectedOptions : [];
    // const botnameFilter = selectedOptions1.length > 0 ? selectedOptions1 : [];
    handleCloseModal();
    //  setIsSubmitActive(true);
    //  setFilterExchange(exchangeFilter);
    //  setfilterData(symbolFilter);
    //  setfilterBotData(botnameFilter);

    // Pass the selected filters to TradesTable
    // const tableData = [
    //   exchangeFilter,
    //   exchange,
    //   typedata,
    //   apiname,
    //   botname,
    //   filterbotData,
    //   filterData,
    // ];

    // // Call TradesTable component with the selected filters
    // <TradesTable
    //   data={tableData}
    //   type={exchangeFilter}
    //   coin={symbolFilter}
    //   selectedBotID={botnameFilter}
    // />;
  };

  const handleModalSubmit = () => {
    const exchangeFilter = filterexchange !== "ALL" ? [filterexchange] : [];
    const symbolFilter =
      selectedOptions.length > 0 ? selectedOptions : filterData;
    const botnameFilter =
      selectedOptions1.length > 0 ? selectedOptions1 : filterbotData;

    // Pass the selected filters to TradesTable
    const tableData = [
      exchangeFilter,
      exchange,
      typedata,
      apiname,
      botname,
      botnameFilter,
      symbolFilter,
    ];

    // Call TradesTable component with the selected filters
    <TradesTable
      data={tableData}
      type={exchangeFilter}
      coin={symbolFilter}
      selectedBotID={botnameFilter}
    />;
  };


  const Exchanges = [
    {
      name: "Future",
      click: "FUTURES"
    },
    {
      name: "Spot",
      click: "SPOT"
    },
  ]


  return (
    <>
      {/* <div className="shadow-neumorphic bg-colorPrimary pt-2 mx-5 mb-2 mt-3 lg:mt-0 hidden lg:block">
        <div className="grid gid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 lg:mx-5 mb-0 px-2 lg:px-4 ">
          <>
            <div className="flex justify-center flex-col items-center lg:space-x-4 ">
              <label htmlFor="selectOption3">Exchange</label>
              <div className="p-2 ">
                <div className="flex mb-4 items-center justify-end">
                  <button
                    className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px] shadow-md drop-shadow-xl ${
                      filterexchange?.includes("ALL")
                        ? "bg-colorSecondary text-white border-2 border-white "
                        : "bg-white text-neonPurple"
                    }`}
                    onClick={() => {
                      handleFilterClick("ALL");
                    }}
                  >
                    All
                  </button>
                  <button
                    className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px] shadow-md drop-shadow-xl ${
                      filterexchange?.includes("SPOT")
                        ? "bg-colorSecondary text-white border-2 border-white "
                        : "bg-white text-neonPurple"
                    }`}
                    onClick={() => {
                      handleFilterClick("SPOT");
                    }}
                  >
                    Spot
                  </button>
                  <button
                    className={`mr-2 px-4 text-sm py-1 rounded-full shadow-md drop-shadow-xl ${
                      filterexchange?.includes("FUTURES")
                        ? "bg-colorSecondary text-white border-2 border-white"
                        : "bg-white text-neonPurple"
                    }`}
                    onClick={() => {
                      handleFilterClick("FUTURES");
                    }}
                  >
                    Future
                  </button>
                </div>
              </div>
            </div>

            <div className="hidden flex-col items-center lg:space-x-4 ">
              <label htmlFor="selectOption3">Type</label>
              <div className="p-2 ">
                <div className="flex mb-4 items-center justify-end">
                  <button
                    className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md drop-shadow-xl ${
                      filter2 === "All"
                        ? "active text-white border-2 border-white "
                        : "bg-white text-neonPurple"
                    }`}
                    onClick={() => setFilter2("All")}
                  >
                    All
                  </button>

                  <button
                    className={`px-1 py-1 rounded-full text-sm w-[80px] shadow-md ${
                      filter2 === "LIVE"
                        ? "active text-white border-2 border-white drop-shadow-xl"
                        : "bg-white text-neonPurple"
                    }`}
                    onClick={() => setFilter2("LIVE")}
                  >
                    Live
                  </button>
                </div>
              </div>
            </div>

            <div
              ref={dropdownRef}
              className="flex justify-around items-center md:contents"
            >
              <div className="flex flex-col items-center  lg:space-x-4 ">
                <label htmlFor="selectedOption">Symbols for ALL</label>
                <div className="p-2 ">
                  <div className="relative">
                    <button
                      id="selectedOption"
                      onClick={() => {
                        setShowOptions(!showOptions);
                        handleFilter("Symbol");
                      }}
                      className="bg-colorPrimary  shadow-neumorphicnew text-sm mt-1 px-5 py-1 rounded-full"
                    >
                      Select Option..
                    </button>

                    {showOptions && (
                      <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                        {filterData?.length === 0 ? (
                          <div className="w-full flex justify-center">
                          <Empty className="w-20"/>
                          </div>
                        ) : (
                          filterData.map((option, index) => (
                            <label key={index} className="block p-2 ">
                              <input
                                type="checkbox"
                                onChange={() => handleOptionSelect(option)}
                                checked={selectedOptions.includes(option)}
                                className="mr-2"
                              />
                              {option}
                            </label>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                ref={dropdownRef1}
                className="flex flex-col items-center  lg:space-x-4 my-2 lg:my-0"
              >
                <label htmlFor="selectOption1">Bot Name</label>
                <div className="p-2 ">
                  <div>
                    <div className="relative">
                      <button
                        id="selectOption1"
                        onClick={() => {
                          setShowOptions1(!showOptions1);
                          handleFilter("BotName");
                        }}
                        className="bg-colorPrimary shadow-neumorphicnew text-sm mt-1 px-4 py-1 rounded-full"
                      >
                        Select Option..
                      </button>

                      {showOptions1 && (
                        <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                          {filterbotData?.map((option, index) => (
                            <label key={index} className="block p-2 ">
                              <input
                                type="checkbox"
                                onChange={() => handleOptionSelect1(option)}
                                className=" mr-2"
                                checked={selectedOptions1.includes(option)}
                              />
                              {option}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div> */}

      <div className="flex gap-4 items-center px-5 pt-6 pb-3">
        <span>Exchanges :</span>
        <span className=" flex items-center gap-2">
          {Exchanges.map((item) => {
            return (
              <button
                onClick={() => {
                  handleFilterClick(item?.click);
                }}
                className={`border-4 shadow-xl py-1 px-8  ${
                  filterexchange == item?.click
                    ? "border-gradient1 border-4 border-solid rounded-full"
                    : " border-gray-200 rounded-xl"
                }`}
              >
                {item.name}
              </button>
            );
          })}
        </span>

     
      </div>

      <div>
        <div className="px-5 mt-16 lg:mt-0">
          <TradesTable
            data={[
              filterexchange,
              exchange,
              typedata,
              apiname,
              botname,
              filterbotData,
              filterData,
            ]}
            type={filterexchange}
            coin={selectedOptions}
            selectedBotID={selectedOptions1}
          />
        </div>
      </div>
    </>
  );
}

export default Trades;



