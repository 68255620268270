import { Empty, Table } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";

function LevelQualTable(props) {
  const { rankqualData, pageSize, page, totalPages, setPage, setpageSize } =
    props.data;

  const columns = [
    {
      title: "DATE",
      dataIndex: "Datetime",
      key: "Datetime",
    },
    {
      title: "TRANS ID",
      dataIndex: "TransID",
      key: "TransID",
    },
    {
      title: "Level",
      dataIndex: "Level",
      key: "Level",
    },

    {
      title: "COMMISSION (%)",
      dataIndex: "Commission",
      key: "Commission",
    },
    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      render: (text) => (
        <span style={{ color: text === "Success" ? "green" : "red" }}>
          {text}
        </span>
      ),
    },
  ];

  const rows = [];

  if (rankqualData) {
    for (let i = 0; i < rankqualData.length; i++) {
      const dateParts = rankqualData[i]?.Datetime?.split(" ");
      if (dateParts && dateParts.length === 2) {
        rows.push({
          key: i,
          Datetime: rankqualData[i]?.Datetime,
          TransID: rankqualData[i]?.TransID,
          Level: rankqualData[i]?.Level,
          Commission: rankqualData[i]?.Commission + " %",
          Status: rankqualData[i]?.Status ? "Success" : "Failed",
        });
      }
    }
  }

  const onChange = (pagination, filters, sorter, extra) => { };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setpageSize(newPageSize);
  };


  const isMobile = useMediaQuery({ maxWidth: 1050 });

  if (isMobile) {
    return (
      <>
        <div className="mt-2">
          <CustomPagination
            totalPages={totalPages}
            currentPage={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={handlePageSizeChange}
          />

          {rows.length > 0 ? (
            <div className="grid grid-cols-1 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full">
              {rows.map((record) => (
                <div
                  key={record.TransID}
                  className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
                >
                  <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                    <div className="card-title font-semibold">Date Time:</div>
                    <div className="card-data">{record.Datetime}</div>
                  </div>
                  <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                    <div className="card-title font-semibold ">TransID:</div>
                    <div className="card-data">{record.TransID}</div>
                  </div>

                  <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                    <div className="card-title font-semibold">Level:</div>
                    <div className="card-data">{record.Level}</div>
                  </div>
                  <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                    <div className="card-title font-semibold">Commission (%):</div>
                    <div className="card-data">{record.Commission}</div>
                  </div>
                  <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                    <div className="card-title font-semibold">Status:</div>
                    <div className="card-data">
                      <span
                        style={{
                          color: parseFloat(record.Status) > 100 ? "red" : "green",
                        }}
                      >
                        {record.Status}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
              <Empty />
            </div>
          )}


          <CustomPagination
            totalPages={totalPages}
            currentPage={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      </>

    );
  }


  return (
    <>

      <div>
        <div className=" w-full bg-colorPrimary ">
          <CustomPagination
            totalPages={totalPages}
            currentPage={page}
            pageSize
            onPageChange={onPageChange}
            onPageSizeChange={handlePageSizeChange}
          />
          <Table
            className="table-newres my-2"
            columns={columns}
            dataSource={rows}
            onChange={onChange}
            pagination={false}
          />
          <CustomPagination
            totalPages={totalPages}
            currentPage={page}
            pageSize
            onPageChange={onPageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>

    </>
  );
}

export default LevelQualTable;
