import React, { Component, useEffect, useState, useRef } from "react";
import { Modal, Table, Tabs, Tooltip, Drawer } from "antd";
import "../assets/css/style.css";
import AllBot1 from "../Components/AllBot1";
import axios from "axios";
import image1 from "../assets/CoinsNew/BTCUSDT.png";
import image2 from "../assets/CoinsNew/BNBUSDT.png";
import image3 from "../assets/CoinsNew/ETHUSDT.png";
import image4 from "../assets/CoinsNew/DOGEUSDT.png";
import image5 from "../assets/CoinsNew/GALAUSDT.png";
import image6 from "../assets/CoinsNew/OCEANUSDT.png";
import image7 from "../assets/CoinsNew/ADAUSDT.png";
import image8 from "../assets/CoinsNew/SOLUSDT.png";
import image9 from "../assets/CoinsNew/MANAUSDT.png";
import { MdAccountBalanceWallet } from "react-icons/md";
import ButtonApi from "../Components/ButtonApi";
import SkeltorLoader from "../Components/SkeltorLoader"
import { BsFillInboxFill } from "react-icons/bs";
import { TbFilterCog } from "react-icons/tb";
import { VscCopy } from "react-icons/vsc";
import { Navigate, useNavigate } from "react-router-dom";
import CopybotDrawer from "../Components/CopybotDrawer";
import { IoGrid } from "react-icons/io5";
import { FaList } from "react-icons/fa6";

const { TabPane } = Tabs;

function BotmainpagecopyTwo(props) {
  const [isActive, setIsActive] = useState("");
  const [coinData, setCoinData] = useState({
    Futures_Coin: [],
    Spot_Coin: [],
  });
  const [exchnagelist, setexchnagelist] = useState([]);
  const [filteredBot, setFilteredBot] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [botData, setBotData] = useState([]);
  const [filter, setFilter] = useState("FUTURES");
  const [filterBot, setFilterBot] = useState([]);
  const [filter1, setFilter1] = useState("hightolow");
  const [selectedBotID, setSelectedBotID] = useState("");
  const [loading, setLoading] = useState(true);
  const [timeSpan, setTimeSpan] = useState('1W')
  const [copyBot, setCopyBot] = useState()
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const jwt = localStorage.getItem("jwt");

  const { handleGridClick, handleListClick } = props.data

  const handleFilterClick = (filterType) => {
    setFilter(filterType);
    setSelectedOption();
    setSelectedBotID("");
  };

  useEffect(() => {
    const masterfilter = botData?.filter((item) => {
      return (
        (filter === "ALL" || item?.Trade_Type === filter) &&
        (!selectedOption?.length || selectedOption?.includes(item?.Symbol)) &&
        (!selectedBotID?.length || selectedBotID?.includes(item?.Name))
      );
    });

    setFilterBot(masterfilter);
  }, [filter, selectedOption, selectedBotID, botData]);

  const uniqueSymbolsSet = new Set();
  const uniqueSymbolsArray = [];
  const AllBotName = [];

  if (botData) {
    botData.forEach((item) => {
      const { Symbol, Name } = item;
      if (!uniqueSymbolsSet.has(Symbol)) {
        uniqueSymbolsSet.add(Symbol);
        uniqueSymbolsArray.push(Symbol);
      }

      if (!uniqueSymbolsSet.has(Name)) {
        uniqueSymbolsSet.add(Name);
        AllBotName.push(Name);
      }
    });
  }

  const filterUniqueSymbols = () => {
    if (filter === "ALL") {
      return uniqueSymbolsArray;
    } else if (filter === "SPOT") {
      return uniqueSymbolsArray.filter((symbol) =>
        botData.some(
          (item) => item.Trade_Type === "SPOT" && item.Symbol === symbol
        )
      );
    } else if (filter === "FUTURES") {
      return uniqueSymbolsArray.filter((symbol) =>
        botData.some(
          (item) => item.Trade_Type === "FUTURES" && item.Symbol === symbol
        )
      );
    }
  };

  const exchageName = botData.filter((item) => item.Trade_Type == filter);

  const filtrBotNameSet = new Set();
  const filtrBotName = [];

  if (exchageName.length > 0) {
    exchageName.forEach((item) => {
      const { Name } = item;
      if (!filtrBotNameSet.has(Name)) {
        filtrBotNameSet.add(Name);
        filtrBotName.push(Name);
      }
    });
  }

  const ViewBot = async (e) => {
    console.log("running view bot");
    const formData = new FormData();
    formData.append("project", process.env.REACT_APP_PROJECT);
    try {
      const response = await axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH + "view_bot",
        data: formData,
      });

      console.log(response, "response");

      const botDataWithImages = await Promise.all(
        response?.data?.Data?.map(async (botItem) => {
          // const data = await ViewBotinfo(
          //   botItem?.VarBotID ? botItem?.VarBotID : ""
          // );
          return {
            ...botItem,
            images: generateImageUrl(botItem?.Symbol),
            // data: data,
            avg_profite: botItem?.Avg_Profit_Percent['1W']
          };
        })
      );

      const sortedFilterBot = [...botDataWithImages].sort((a, b) => {
        const profitA = parseFloat(a.Avg_Profit_Percent["1W"]) || 0;
        const profitB = parseFloat(b.Avg_Profit_Percent["1W"]) || 0;
        return profitB - profitA;
      });

      console.log(botDataWithImages, "botDataWithImages");
      setBotData(sortedFilterBot);
      setLoading(false);
    } catch (err) {
      console.error(err?.response?.data?.Error);
      setLoading(false);
    }
  };

  const filterBotNamesByExchange = () => {

    if (filter === "ALL") {
      return AllBotName;
    } else if (filter === "SPOT") {
      return AllBotName.filter((botName) =>
        botData.some(
          (item) => item.Trade_Type === "SPOT" && item.Name === botName
        )
      );
    } else if (filter === "FUTURES") {
      return AllBotName.filter((botName) =>
        botData.some(
          (item) => item.Trade_Type === "FUTURES" && item.Name === botName
        )
      );
    }
  };

  // const ViewBotinfo = async (botId) => {
  //   if (botId) {
  //     const postData = {
  //       jwt: jwt,
  //       project: process.env.REACT_APP_PROJECT,
  //       bot_id: botId,
  //     };

  //     try {
  //       const response = await axios.post(
  //         process.env.REACT_APP_API_PATH_BOT + "bot_info",
  //         postData
  //       );
  //       return response?.data?.Success;
  //     } catch (error) {
  //       console.error(error?.response?.data?.Error);
  //       return null;
  //     }
  //   }
  // };

  useEffect(() => {
    ViewBot();
  }, [timeSpan]);

  const tradable_asset = async () => {
    try {
      const formData = new FormData();
      formData.append("project", process.env.REACT_APP_PROJECT);
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER_NEW + "tradable_asset",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = response?.data?.Success || {};
      const allCoins = [
        ...new Set([...data?.Futures_Coin, ...data?.Spot_Coin]),
      ];

      setCoinData(data);
    } catch (err) {
      console.error(err);
    }
  };

  const bot_list = async () => {
    try {
      const formData = new FormData();
      formData.append("project", process.env.REACT_APP_PROJECT);
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER_NEW + "bot_list",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response?.data?.Success || {};
      const allBot = [...new Set([...data?.FUTURES, ...data?.SPOT])];
      setFilteredBot(allBot);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    tradable_asset();
    bot_list();
  }, []);

  const generateImageUrl = (symbol) => {
    switch (symbol) {
      case "BTCUSDT":
        return image1;
      case "BNBUSDT":
        return image2;
      case "ETHUSDT":
        return image3;
      case "DOGEUSDT":
        return image4;
      case "SOLUSDT":
        return image8;
      case "GALAUSDT":
        return image5;
      case "OCEANUSDT":
        return image6;
      case "ADAUSDT":
        return image7;
      case "MANAUSDT":
        return image9;

      default:
        return "";
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionChange1 = (event) => {
    setSelectedBotID(event.target.value);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModal1 = () => {
    setIsModalVisible1(true);
  };


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  const sortedFilterBotHighToLow = () => {
    setFilter1("hightolow");

    const sortedFilterBot = [...filterBot].sort((a, b) => {
      const profitA = parseFloat(a.Avg_Profit_Percent["1W"]) || 0;
      const profitB = parseFloat(b.Avg_Profit_Percent["1W"]) || 0;
      return profitB - profitA;
    });
    setFilterBot(sortedFilterBot);
  };

  const sortedFilterBotLowToHigh = () => {
    setFilter1("lowtohigh");

    const sortedFilterBot = [...filterBot].sort((a, b) => {
      const profitA = parseFloat(a.Avg_Profit_Percent["1W"]) || 0;
      const profitB = parseFloat(b.Avg_Profit_Percent["1W"]) || 0;
      return profitA - profitB;
    });
    setFilterBot(sortedFilterBot);
  };

  const [showOptions, setShowOptions] = useState(false);
  const [showOptions1, setShowOptions1] = useState(false);

  const options = filterUniqueSymbols();

  const handleOptionSelect = (option) => {
    const updatedOptions = Array.isArray(selectedOption) ? [...selectedOption] : [];

    if (updatedOptions.includes(option)) {
      updatedOptions?.splice(updatedOptions?.indexOf(option), 1);
    } else {
      updatedOptions?.push(option);
    }

    setSelectedOption(updatedOptions);
  };

  const handleOptionSelect1 = (option) => {
    const updatedOptions = selectedBotID.includes(option)
      ? selectedBotID.filter((selected) => selected !== option)
      : [...selectedBotID, option];

    setSelectedBotID(updatedOptions);
  };

  const handleChangeProfit = (updatedProp, index) => {
    console.log("===>>>", updatedProp, index)
    const updatedFilter = [...filterBot];
    updatedFilter[index].avg_profite = updatedProp;
    setFilterBot(updatedFilter)
  }

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRef1 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
        setShowOptions1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const getBotData = (record) => {
    const filterCopyBot = filterBot.filter(item => item?.VarBotID == record)
    setCopyBot(filterCopyBot[0])
    setTimeout(() => {
      setOpen(true)
    }, 500)
  }

  const dataSource = []

  if (filterBot.length > 0) {
    for (let i = 0; i < filterBot.length; i++) {
      const profitPercent = Object.keys(filterBot[i]?.Avg_Profit_Percent)

      const AvgPercent = filterBot[i]?.Avg_Profit_Percent[timeSpan] + ""
      const splitAvgPercent = AvgPercent?.split('.')

      console.log("AvgPercent", typeof AvgPercent)

      dataSource.push({
        Symbol: <span className="text-xs font-semibold">{filterBot[i]?.Symbol}</span>,
        BotID: filterBot[i]?.Symbol,
        BotName: <span className="text-xs font-semibold  ">{filterBot[i]?.Name}</span>,
        VarBotID: <span className="text-xs font-semibold">{filterBot[i]?.VarBotID}</span>,
        Exchange: <span className="text-xs font-semibold">{filterBot[i]?.Exchange}</span>,
        Trade_Type: <div className="text-xs font-semibold">{filterBot[i]?.Trade_Type}</div>,
        images: <div><img src={filterBot[i]?.images} className=" mx-auto w-8 h-8 my-1" /></div>,
        Avg_Profit_Percent: <span className={`text-xs font-semibold ${AvgPercent > 0 ? 'text-green-400' : "text-red-400"}`}>{AvgPercent} %</span>,
        // Avg_Profit_Percent:
        //   <div>
        //     <p className="text-[green] font-bold text-lg sm:text-4xl">
        //       {splitAvgPercent[0]}<span className="text-2xl">.{splitAvgPercent[1]} %</span>
        //     </p>
        //   </div>,
        Copy_bot: <Tooltip onClick={() => getBotData(filterBot[i]?.VarBotID)} placement="top" title={`Copy ${filterBot[i]?.Symbol}`}>
          <button className=" bg-textColor text-white px-3 py-1 rounded-lg hover:bg-colorPrimaryDark border border-colorPrimary border-solid hover:border-dashed hover:border-colorSecondary hover:text-colorSecondary transition-all transition-5s font-semibold">
            COPY
          </button>
        </Tooltip>
      })
    }
  }


  const columns = [
    {
      title: <div className="my-2 capitalize text-sm font-semibold ">Pair</div>,
      dataIndex: "images",
      width: 100
    },
    {
      title: <div className="my-2 capitalize text-sm font-semibold ">Symbol</div>,
      dataIndex: "Symbol",

    },
    {
      title: <div className="my-2 capitalize text-sm font-semibold ">Bot Name</div>,
      dataIndex: "BotName",
    },
    {
      title: <div className="my-2 capitalize text-sm font-semibold ">Exchange</div>,
      dataIndex: "Exchange",
    },
    // {
    //   title:<div className="my-2 capitalize text-sm font-semibold ">VarBotID</div>,
    //   dataIndex: "VarBotID",
    // },
    {
      title: <div className="my-2 capitalize text-sm font-semibold ">Trade Type</div>,
      dataIndex: "Trade_Type",
    },
    {
      title: <div className="my-2 capitalize text-sm font-semibold ">Avg Profit Percent %</div>,
      dataIndex: "Avg_Profit_Percent",
      // sorter: (a, b) => a.Avg_Profit_Percent - b.Avg_Profit_Percent,
    },
    {
      title: <div className="my-2 capitalize text-sm font-semibold ">Copy bot</div>,
      dataIndex: "Copy_bot",
    },
  ]

  const timeData = [
    "1W",
    "1M",
    "3M",
    "1Y"
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const item = [
    {
      name: "Future",
      click: "FUTURES"
    },
    {
      name: "Spot",
      click: "SPOT"
    },
    {
      name: "Scalping Bot",
      click: ""
    },
  ]


  return (
    <>
      <div>
      </div>
      <header className=" container flex fixed shadow-neumorphic bg-colorPrimary justify-between z-30  w-100 md:w-8/12 Block lg:hidden ">
        <div className=" xl:hidden block ">
          <div type="primary" onClick={showModal1} className="">
            <TbFilterCog size={25} className="text-white" />
          </div>
          <Modal
            title="Status"
            visible={isModalVisible1}
            footer={false}
            onCancel={handleCancel1}
          >
            <div className="shadow-neumorphic rounded-3xl bg-colorPrimary pt-2 w-full mb-2 mt-3 lg:mt-0">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 lg:mx-5 mb-0 px-2 lg:px-4 sm:grid-cols-4">
                <div className="flex justify-center flex-col items-center lg:space-x-4 ">
                  <label
                    htmlFor="selectOption3 "
                    className="text-white font-semibold"
                  >
                    Exchange
                  </label>
                  <div className="p-2 ">
                    <div className="flex items-center justify-end">
                      <button
                        className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md drop-shadow-xl ${filter === "ALL"
                          ? "bg-colorSecondaryDark text-white border-2 border-white "
                          : "bg-white text-white"
                          }`}
                        onClick={() => handleFilterClick("ALL")}
                      >
                        All
                      </button>
                      <button
                        className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "SPOT"
                          ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                          : "bg-white text-white"
                          }`}
                        onClick={() => handleFilterClick("SPOT")}
                      >
                        Spot
                      </button>
                      <button
                        className={`mr-2 xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "FUTURES"
                          ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                          : "bg-white text-white"
                          }`}
                        onClick={() => handleFilterClick("FUTURES")}
                      >
                        Future
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center lg:space-x-4 ">
                  <label
                    htmlFor="selectOption3"
                    className="text-white font-semibold"
                  >
                    FROM
                  </label>
                  <div className="p-2 ">
                    <div className="flex items-center justify-end">
                      <button
                        className={`mr-2 px-1 2xl:w-28 text-sm py-1 rounded-full w-[100px] shadow-md drop-shadow-xl ${filter1 === "hightolow"
                          ? "bg-colorSecondaryDark text-white border-2 border-white "
                          : "bg-white text-white"
                          }`}
                        onClick={sortedFilterBotHighToLow}
                      >
                        High to Low
                      </button>

                      <button
                        className={`px-1 py-1 rounded-full text-sm w-28 shadow-md ${filter1 === "lowtohigh"
                          ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                          : "bg-white text-white"
                          }`}
                        onClick={sortedFilterBotLowToHigh}>
                        Low to High
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  ref={dropdownRef}
                  className="flex justify-around items-center md:contents"
                >
                  <div className="flex flex-col items-center  lg:space-x-4 ">
                    <label
                      htmlFor="selectOption"
                      className="text-white font-semibold"
                    >
                      Symbols for ALL
                    </label>
                    <div className="p-2 ">
                      <div>
                        <div className="relative">
                          <button
                            id="selectedOption"
                            // value={selectedOption}
                            onClick={() => setShowOptions(!showOptions)}
                            className="bg-colorPrimary  shadow-neumorphicnew text-sm mt-1 px-5 py-1 rounded-full"
                          >
                            Select Option..
                          </button>

                          {showOptions && (
                            <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                              {filterUniqueSymbols()?.map((option, index) => (
                                <label key={index} className="block p-2 ">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleOptionSelect(option)}
                                    className=" mr-2"
                                  />
                                  {option}
                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    ref={dropdownRef1}
                    className="flex flex-col items-center  lg:space-x-4 my-2 lg:my-0"
                  >
                    <label
                      htmlFor="selectOption1"
                      className="text-white font-semibold"
                    >
                      Bot Name
                    </label>
                    <div className="p-2 ">
                      <div>
                        <div className="relative">
                          <button
                            id="selectOption1"
                            // value={selectedOption}
                            onClick={() => setShowOptions1(!showOptions1)}
                            className="bg-colorPrimary shadow-neumorphicnew text-sm mt-1 px-4 py-1 rounded-full"
                          >
                            Select Option..
                          </button>

                          {showOptions1 && (
                            <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                              {filterBotNamesByExchange()?.map(
                                (option, index) => (
                                  <label key={index} className="block p-2 ">
                                    <input
                                      type="checkbox"
                                      onChange={() =>
                                        handleOptionSelect1(option)
                                      }
                                      className=" mr-2"
                                    />
                                    {option}
                                  </label>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="  xl:hidden block ">
          <div
            type="primary"
            onClick={showModal}
            className="   text-white"
          >
            <MdAccountBalanceWallet size={25} className="" />
          </div>
          <Modal
            title="Status"
            visible={isModalVisible}
            // onOk={false}
            footer={false}
            onCancel={handleCancel}
          >
            <ButtonApi />
          </Modal>
        </div>
      </header>
      <div className=" pb-4 ">
        {/* <div className=" bg-colorSecondary border-2 rounded-xl border-colorText py-4 my-8 container hidden lg:block">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 lg:mx-5 mb-0 px-2 lg:px-4 mt-4 sm:grid-cols-1">
            <div className="flex justify-center flex-col items-center lg:space-x-4 ">
              <label htmlFor="selectOption3" className=" text-white font-normal">Exchange</label>
              <div className="p-2 ">
                <div className="flex items-center justify-end">
                  <button
                    className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md drop-shadow-xl ${filter === "ALL"
                      ? "active text-white border-2 border-white "
                      : "bg-white text-neonPurple"
                      }`}
                    onClick={() => handleFilterClick("ALL")}
                  >
                    All
                  </button>
                  <button
                    className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "SPOT"
                      ? "active text-white border-2 border-white drop-shadow-xl"
                      : "bg-white text-neonPurple"
                      }`}
                    onClick={() => handleFilterClick("SPOT")}
                  >
                    Spot
                  </button>
                  <button
                    className={`mr-2 xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "FUTURES"
                      ? "active text-white border-2 border-white drop-shadow-xl"
                      : "bg-white text-neonPurple"
                      }`}
                    onClick={() => handleFilterClick("FUTURES")}
                  >
                    Future
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center lg:space-x-4 ">
              <label htmlFor="selectOption3" className="text-white font-normal">FROM</label>
              <div className="p-2 ">
                <div className="flex items-center justify-end">
                  <button
                    className={`mr-2 px-1 2xl:w-28 text-sm py-1 rounded-full w-[100px] shadow-md drop-shadow-xl ${filter1 === "hightolow"
                      ? "active text-white border-2 border-white "
                      : "bg-white text-neonPurple"
                      }`}
                    onClick={sortedFilterBotHighToLow}
                  >
                    High to Low
                  </button>

                  <button
                    className={`px-1 py-1 rounded-full text-sm w-28 shadow-md ${filter1 === "lowtohigh"
                      ? "active text-white border-2 border-white drop-shadow-xl"
                      : "bg-white text-neonPurple"
                      }`}
                    onClick={sortedFilterBotLowToHigh}
                  >
                    Low to High
                  </button>
                </div>
              </div>
            </div>
            <div
              ref={dropdownRef}
              className="flex justify-around items-center md:contents"
            >
              <div className="flex flex-col items-center  lg:space-x-4 ">
                <label htmlFor="selectOption" className="text-white font-normal">Symbols for ALL</label>
                <div className="p-2 ">
                  <div>
                    <div className="relative">
                      <button
                        id="selectedOption"
                        // value={selectedOption}
                        onClick={() => setShowOptions(!showOptions)}
                        className=" bg-white text-colorSecondary text-sm mt-1 px-5 py-1 rounded-full"
                      >
                        Select Option..
                      </button>

                      {showOptions && (
                        <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                          {filterUniqueSymbols()?.map((option, index) => (
                            <label key={index} className="block p-2 ">
                              <input
                                type="checkbox"
                                onChange={() => handleOptionSelect(option)}
                                className=" mr-2"
                              />
                              {option}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref={dropdownRef1}
                className="flex flex-col items-center  lg:space-x-4 my-2 lg:my-0"
              >
                <label htmlFor="selectOption1" className="text-white font-normal">Bot Name</label>
                <div className="p-2 ">
                  <div>
                    <div className="relative">
                      <button
                        id="selectOption1"
                        // value={selectedOption}
                        onClick={() => setShowOptions1(!showOptions1)}
                        className=" bg-white text-colorSecondary text-sm mt-1 px-4 py-1 rounded-full"
                      >
                        Select Option..
                      </button>

                      {showOptions1 && (
                        <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                          {filterBotNamesByExchange()?.map((option, index) => (
                            <label key={index} className="block p-2 ">
                              <input
                                type="checkbox"
                                onChange={() => handleOptionSelect1(option)}
                                className=" mr-2"
                              />
                              {option}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className=" flex flex-col items-center gap-2">
                <p className="text-white font-normal">Avg Profit Percent</p>
                <div className="gap-2  flex">
                  {
                    timeData.map(item => {
                      return (
                        <span onClick={() => setTimeSpan(item)} className={`cursor-pointer border-dashed border-[1px] px-2 py-1 rounded-md  font-medium
                        ${timeSpan === item ? "bg-textColor text-colorTable border-colorTable" : "text-textColor bg-colorTable border-textColor"}`}>{item}</span>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="px-6 my-8 flex justify-between">
          <div className=" flex gap-4">
            {/* <button onClick={() => handleFilterClick("FUTURES")} className=" border-2 border-colorSecondary py-1 px-6 rounded-xl">Future</button>
            <button onClick={() => handleFilterClick("SPOT")} className=" border-2 border-colorSecondary py-1 px-6 rounded-xl">Spot</button>
            <button className=" border-2 border-colorSecondary py-1 px-6 rounded-xl">Bot</button> */}
            {
              item.map(item => {
                return (
                  <button 
                  onClick={() => handleFilterClick(item?.click)}
                    className={`border-4 shadow-xl  py-1 px-8  ${filter === item?.click ? "border-gradient1" : " border-gray-200" }`}
                   >{item?.name}</button>
                )
              })
            }
          </div>

          <div className=" flex gap-8">
            <IoGrid
              size={24}
              onClick={handleGridClick}
              style={{ cursor: "pointer" }}
            />
            <FaList
              size={24}
              onClick={handleListClick}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>


      {/* -------------------mobile view--------------- */}

      <CopybotDrawer data={{ open, setOpen, copyBot }} />

      <div>
        <div>
          <div className=" mt-4">
            {loading
              ?
              //  Array?.from({ length: 12 })?.map((_, index) => (
              //   <div
              //     key={index}
              //     className={`pt-4 bg-colorPrimary text-white md:h-40 px-6 p-0 relative rounded-xl shadow-neumorphic`}
              //   >

              //   </div>
              // ))
              <SkeltorLoader />
              :
              <div>
                <div className="">
                  {
                    filterBot.length !== 0 &&
                    <Table size="small" dataSource={dataSource} columns={columns} onChange={onChange} className="px-6"/>
                  }
                </div>
              </div>
            }
          </div>

          {filterBot.length === 0 && !loading && (
            <div className="flex justify-center flex-col w-100 h-96 items-center text-center text-5xl text-neonPurple">
              {/* <BsFillInboxFill size={35} />
              No Bot Active */}
              Comming soon...
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BotmainpagecopyTwo;
