import { Empty, Table } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";

function Matrix_NameQualTrans(props) {
  const { martixQualData, pageSize, page, totalPages, setPage, setpageSize } =
    props.data;

  const columns = [
    {
      title: "DATE",
      dataIndex: "Datetime",
      key: "Datetime",
    },
    {
      title: "MATRIX NAME",
      dataIndex: "Matrix_Name",
      key: "Matrix_Name",
    },
    {
      title: "SPONSOR",
      dataIndex: "Sponsor",
      key: "Sponsor",
    },

    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      render: (text) => (
        <span style={{ color: text === "Success" ? "green" : "red" }}>
          {text}
        </span>
      ),
    },
  ];

  const rows = [];

  if (martixQualData) {
    for (let i = 0; i < martixQualData.length; i++) {
      const dateParts = martixQualData[i]?.Datetime?.split(" ");
      if (dateParts && dateParts.length === 2) {
        const matrixName = martixQualData[i]?.Matrix_Name;
        const formattedMatrixName = matrixName ? matrixName.replace("BOT", "BOT ") : '';
        rows.push({
          key: i,
          Datetime: martixQualData[i]?.Datetime,
          TransID: martixQualData[i]?.TransID,
          Sponsor: martixQualData[i]?.Sponsor,
          Matrix_Name: formattedMatrixName,
          Transaction: `${martixQualData[i]?.Transaction}`,
          Status: martixQualData[i]?.Status ? "Success" : "Failed",
        });
      }
    }
  }

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setpageSize(newPageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => { };
  const isMobile = useMediaQuery({ maxWidth: 1050 });

  if (isMobile) {
    return (
      <>
        <div className="mt-2"></div>
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />

        {rows.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full">
            {rows.map((record) => (
              <div
                key={record.TransID}
                className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
              >
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Date Time:</div>
                  <div className="card-data">{record.Datetime}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">Matrix Name:</div>
                  <div className="card-data">{record.Matrix_Name}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Sponsor:</div>
                  <div className="card-data">{record.Sponsor}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Status:</div>
                  <div className="card-data">
                    <span
                      style={{
                        color: parseFloat(record.Status) > 100 ? "red" : "green",
                      }}
                    >
                      {record.Status}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
            <Empty />
          </div>
        )}


        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </>
    );
  }



  return (
    <div>
      <div className=" w-full bg-colorPrimary ">
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <Table
          className="table-newres my-2"
          columns={columns}
          dataSource={rows}
          onChange={onChange}
          pagination={false}
        />
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
}

export default Matrix_NameQualTrans;
