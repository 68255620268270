import React, { useEffect, useState } from "react";
import WalletBalance from "../Components/WalletBalance";
import axios from "axios";
import TransferWallet from "../Components/TransferWallet";
import TransferUser from "../Components/TransferUser";
import { toast } from "react-toastify";


function Wallet() {
  const [wallet, setWallet] = useState("");
  const [profile1, setProfile1] = useState("");

  const View_Wallet = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_Wallet + "wallet_details",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response.data;
        setWallet(res.Success);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    View_Wallet();
  }, []);


  const View_Profile1 = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_USER + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setProfile1(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    View_Profile1();
  }, []);


  return (
    <>
      <div className="container">
        <div>
          <WalletBalance wallet={wallet} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-10">
          <div>
            <TransferWallet
              data={{ wallet, View_Wallet }}
            />
          </div>
          <div>
            <TransferUser data={{ wallet, View_Wallet , profile1 }} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Wallet;
