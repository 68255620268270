import { Alert, Checkbox, Form, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import img1 from "../assets/Api/BINANCEFUTURES.png";
import img2 from "../assets/Api/BINANCESPOT.png";
import img5 from "../assets/Api/BYBITFUTURES.png";
import img6 from "../assets/Api/BYBITSPOT.png";
import img3 from "../assets/Api/KUCOINFUTURES.png";
import img4 from "../assets/Api/KUCOINSPOT.png";
import img11 from "../assets/coins/BINANCEFUTURES.png";
import img21 from "../assets/coins/BINANCESPOT.png";
import img51 from "../assets/coins/BYBITFUTURES.png";
import img61 from "../assets/coins/BYBITSPOT.png";
import img31 from "../assets/coins/KUCOINFUTURES.png";
import img41 from "../assets/coins/KUCOINSPOT.png";
import { PiInfo } from "react-icons/pi";
import DrawerTooltip from "../Components/DrawerTooltip";
import DrawerComponent from "../Components/DrawerComponent";


const Step = ({ title, completed, isChecked, index }) => {
  return (
    <div className={`step ${completed ? "current bg-red-500" : "bg-blue-300"}`}>
      <span className={`step-number ${completed ? "checkmark" : ""}`}>
        {completed ? "✔" : index}
      </span>
      <span>{title}</span>
      {title === "Setup Keys" && isChecked && (
        <span className="checkbox">✔</span>
      )}
    </div>
  );
};


function Spotconnectiondetails({ currentStepIndex }) {
  const location = useLocation();

  const [apiName, setApiName] = useState();
  const [apiKey, setApiKey] = useState();
  const [secretKey, setSecretKey] = useState();
  const [exchangeData, setExchangeData] = useState(null);
  const [exchImg, setExchImg] = useState(null);
  const [apiNameError, setApiNameError] = useState("");
  const [apiKeyError, setApiKeyError] = useState("");
  const [secretKeyError, setSecretKeyError] = useState("");
  const [inputValue, setInputValue] = useState(
    "161.35.20.74 139.59.149.93 167.172.171.143 46.101.133.73"
  );
  const [drawerState, setDrawerState] = useState({
    visible: false,
    tooltipTexts: [],
    activeIndex: -1, 
    activetabtitle: "",
  });
  const maxWordCount = 10;

  const navigate = useNavigate();
   const [exchangetpeImg, setExchangetpeImg] = useState("");
   const [exchageImg, setExchangeImg] = useState("");

   useEffect(() => {
     if (location.state && location.state.exchangeType === "Futures") {
       setExchangetpeImg(img1);
     } else {
       setExchangetpeImg(img2);
     }

     if (location.state && location.state.exchange === "Binance") {
       setExchangeImg(img11);
     }
   }, [location.state]);

   useEffect(() => {
     if (location.state && location.state.exchangeData) {
       const { exchangeData: exchangeDataFromLocation, filteredImages } =
         location.state;

       const exchangeImg = exchangeDataFromLocation.filter(
         (item) => item.img === filteredImages
       );

       if (exchangeImg.length > 0) {
         setExchImg(exchangeImg[0]);
       }

       setExchangeData(exchangeDataFromLocation);
     }
   }, [location.state]);

  useEffect(() => {
    if (location.state && location.state.exchangeData) {
      const { exchangeData: exchangeDataFromLocation, filteredImages } =
        location.state;

      const exchangeImg = exchangeDataFromLocation.filter(
        (item) => item.img === filteredImages
      );

      if (exchangeImg.length > 0) {
        setExchImg(exchangeImg[0]);
      }

      setExchangeData(exchangeDataFromLocation);
    }
  }, [location.state]);
  


  const required = apiName || apiKey || secretKey;

  const imgSrc = location.state ? location.state.filteredImages || "" : "";
  const imgSrc2 = location.state ? location.state.filteredImages1 || "" : "";

  const exchange = location.state ? location.state.exchangeName || "" : "";
  const types = location.state ? location.state.exchangeTypes || "" : "";

  if (location.state) {
    const destinationImageName = "imgSrc2"; 
    const imgnewValue = location.state[destinationImageName] || ""; 


  }
  

  function handleCopy() {
   
    navigator.clipboard
      .writeText(inputValue)
      .then(() => {
       
        toast("Text copied to clipboard.");
      })
      .catch((error) => {
      
        message.error("Copy failed. Please try again.");
      });
  }




  const [api, setAPI] = useState({
    api_name: "",
    key: "",
    secret: "",
    type: "",
    exchange: exchange,
    balance: {
      BNB: "",
      BTC: "",
      ETH: "",
      USDT: "",
    },
  });

  const handleNavigate = () => {
    navigate("/dashboard/api_settings/ConnectionLoder", {
      state: {
        api_name: apiName,
        key: apiKey,
        secret: secretKey,
        type: "LIVE",
        exchange: exchange,
        exchange_type: types,
        balance: {},
        logo: exchageImg,
        // imgSrc2,
      },
    });
  };


  const images = [
    { name: "BINANCEFUTURES", img: img1, imgnew: img11 },
    { name: "BINANCESPOT", img: img2, imgnew: img21 },
    { name: "KUCOINSPOT", img: img4, imgnew: img31 },
    { name: "KUCOINFUTURES", img: img3, imgnew: img41 },
    { name: "BYBITFUTURES", img: img5, imgnew: img51 },
    { name: "BYBITSPOT", img: img6, imgnew: img61 },
  ];

  const targetNames =
    "BINANCEFUTURES,BINANCESPOT,KUCOINSPOT,KUCOINFUTURES,BYBITFUTURES,BYBITSPOT"; // Replace with the names you want to filter by
  const targetNamesArray = targetNames.split(",");

  const selectedImage = images.find((item) => item.name === exchange);


  let imgnewImage = null;

  if (selectedImage && targetNamesArray.includes(exchange)) {
    imgnewImage = selectedImage.imgnew;
  }

  const [error, setError] = useState("");

  const handleValidationAndSubmit = () => {
    setApiNameError(""); // Clear any previous error messages
    setApiKeyError("");
    setSecretKeyError("");

    let isValid = true;

    if (!apiName) {
      setApiNameError("Api Name is required");
      isValid = false;
    }

    if (!apiKey) {
      setApiKeyError("Api Key is required");
      isValid = false;
    }

    if (!secretKey) {
      setSecretKeyError("Secret Key is required");
      isValid = false;
    }

    if (isValid) {
      handleNavigate(); // Proceed to the next step or submit the form
    }
  };

  const [tooltipsVisibility, setTooltipsVisibility] = useState({});

  const handleToggleTooltip = (id) => {
    setTooltipsVisibility((prevVisibility) => ({
      ...prevVisibility,
      [id]: !prevVisibility[id],
    }));
  };

  const tooltips = [
    {
      id: "1",
      title: "Api Name",
      description:
        "Unlock personalized and targeted digital interactions with UniqueID Connector—an API that sets your integration apart, offering distinct identification for seamless online experiences.",
    },
    {
      id: "2",
      title: "Api Key",
      description:
        "An exchange API key is a confidential code enabling software applications to interact with your exchange account securely, allowing third-party services access without sharing login credentials.",
    },
    {
      id: "3",
      title: "Secret Key",
      description:
        "The Secret Key in API key creation for cryptocurrency exchanges is a confidential code crucial for secure communication. Paired with the API key, it acts as a digital lock, safeguarding your account. Keep it private, update keys regularly, and exercise caution in granting permissions for enhanced security during exchange integration.",
    },
    {
      id: "4",
      title: "IPs to whitelist",
      description:
        "Implementing IP access restrictions, particularly whitelisting trusted IPs, is paramount for exchange security. By doing so, you fortify your account against unauthorized access, adding an additional layer of protection. This precautionary measure ensures that only pre-approved, secure IPs have the privilege of interacting with your exchange account, minimizing potential risks.",
    },
  ];

  

  const toggleDrawer = (tooltipTexts, title) => {
    setDrawerState({
      visible: !drawerState.visible,
      tooltipTexts: tooltipTexts,
      activeIndex: -1,
      activetabtitle: title,
    });
    // setColorChanged(false);
  };

  const handleApiNameChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/);
  
    if (words.length <= maxWordCount) {
      setApiName(inputValue);
      setApiNameError('');
    } else {
      setApiNameError('Input exceeds the maximum word count.');
    }
  };

  const projectName = process.env.REACT_APP_DEMO || "Default Project";

  return (
    <>
      {/* <Step */}
      <div className="text-center my-4">
        <div className="text-slate-900 text-lg font-black mt-2">
          Set up your keys
        </div>
        <div className="font-light text-sm">
          Create your API keys and Securely Connect your Exchange to {projectName}
        </div>
      </div>

      <div className="grid lg:grid-cols-2">
        <div className="flex justify-end">
          <Form
            className="bg-colorPrimary  rounded-3xl px-8 pt-6 pb-8 w-full mx-5  md:w-[600px] lg:me-28 shadow-neumorphic"
            onFinish={handleNavigate}
            // onSubmit={ViewBot_info}
          >
            <div className="items-center">
              <div className=" rounded-full align items-center border-solid drop-shadow-xl flex  p-1 justify-between mb-3  mx-2 text-black">
                <img
                  src={exchangetpeImg}
                  alt="{exchangeName}"
                  className="mr-2 w-full md:w-[150px] bg-transparent py-1 rounded-lg shadow-neumorphic1 px-2"
                />
              </div>
            </div>

            <div className="mb-4">
              <div className="flex justify-between items-center">
                <label
                  className="block text-gray-700 text-xs font-bold mb-2"
                  htmlFor="apiName"
                >
                  Api Name <span className="text-[red]">*</span>
                </label>
                <div onClick={() => toggleDrawer(tooltips, "Api Name")}>
                  <PiInfo
                    size={20}
                    className="cursor-help hover:scale-110 transition-all transition-5s"
                  />
                </div>
              </div>

              <Form.Item
                name="apiName"
                rules={[
                  {
                    required: true,
                    message: "Api Name is required",
                  },
                ]}
              >
                <input
                  id="apiName"
                  name="apiName"
                  value={apiName}
                  maxLength={10}
                  // onChange={handleApiNameChange}
                  onChange={(e) => setApiName(e.target.value)}
                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Api Name"
                />
                {apiNameError && (
                  <div className="text-red-500 px-3">{apiNameError}</div>
                )}
              </Form.Item>
            </div>

            <div className="mb-4">
              <div className="flex justify-between items-center">
                <label
                  className="block text-gray-700 text-xs font-bold mb-2"
                  htmlFor="apikey"
                >
                  Api Key <span className="text-[red]">*</span>
                </label>
                <div onClick={() => toggleDrawer(tooltips, "Api Key")}>
                  <PiInfo
                    size={20}
                    className="cursor-help hover:scale-110 transition-all transition-5s"
                  />
                </div>
              </div>
              <Form.Item
                name="apikey"
                rules={[
                  {
                    required: true,
                    message: "Api Key is required",
                  },
                ]}
              >
                <input
                  id="apikey"
                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Api Key"
                  name="apikey"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                />
                {apiKeyError && (
                  <div className="text-red-500 px-3">{apiKeyError}</div>
                )}
              </Form.Item>
            </div>

            <div className="mb-4">
              <div className="flex justify-between items-center">
                <label
                  className="block text-gray-700 text-xs font-bold mb-2"
                  htmlFor="secretKey"
                >
                  Secret Key <span className="text-[red]">*</span>
                </label>
                <div onClick={() => toggleDrawer(tooltips, "Secret Key")}>
                  <PiInfo
                    size={20}
                    className="cursor-help hover:scale-110 transition-all transition-5s"
                  />
                </div>
              </div>

              <Form.Item
                name="secretKey"
                rules={[
                  {
                    required: true,
                    message: "Secret Key is required",
                  },
                ]}
              >
                <input
                  id="secretKey"
                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Secret Key"
                  name="secretKey"
                  value={secretKey}
                  onChange={(e) => setSecretKey(e.target.value)}
                />
                {secretKeyError && (
                  <div className="text-red-500 px-3">{secretKeyError}</div>
                )}
              </Form.Item>
            </div>

            <div className="flex justify-between items-center">
              <h4>IPs to whitelist</h4>
              <div onClick={() => toggleDrawer(tooltips, "IPs to whitelist")}>
                <PiInfo
                  size={20}
                  className="cursor-help hover:scale-110 transition-all transition-5s"
                />
              </div>
            </div>
            <div className="text-sm font-semibold text-lg ">
              <div className="flex justify-center items-center rounded-3xl px-4 py-2 bg-colorPrimary shadow-neumorphicnew ">
                <input
                  className=" appearance-none  bg-transparent w-full  text-black leading-tight focus:outline-none "
                  value={inputValue}
                  readOnly
                  onChange={(e) => setInputValue(e.target.value)}
                  style={{ background: "transparent" }}
                ></input>
                <Tooltip title="Copy">
                  <CopyOutlined
                    onClick={handleCopy}
                    style={{
                      color: "#000",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </div>
              <span
                id="copiedMessage"
                style={{ display: "none", color: "green" }}
              >
                Copied
              </span>
            </div>

            <Alert
              message={`To increase your security, connect to your exchange with ${projectName} whitelisted IPs. Copy the IPs and paste them into your exchange while creating the new API keys.`}
              className="mt-5 mb-5"
              type="info"
              showIcon
            />

            <div className="items-end flex justify-end mt-5">
              <button
                className={`active rounded-3xl  mx-2 hover:active text-white   font-bold py-2 px-4  focus:outline-none focus:shadow-outline `}
              >
                <Link to="/dashboard/api_settings">Prev</Link>
              </button>

              <button
                htmlType="submit"
                className={`active rounded-3xl hover:active text-white  font-bold py-2 px-4 focus:outline-none focus:shadow-outline `}
                type="button"
                onClick={handleValidationAndSubmit}
              >
                Next
              </button>
              {error && <div className="text-red-500">{error}</div>}
            </div>
          </Form>
          <div className="w-1 md:h-[80%] mt-6 border-opacity-95 bg-black"></div>
        </div>
        <div className="text-center items-center my-5 lg:mt-24 ">
          <img src={exchageImg} alt="binance" className="w-36 inline-block" />

          <p className="text-lg">How to create your API key on Binance?</p>
          <button type="button" className="p-2 bg-colorPrimary rounded-xl mt-5">
            <Link
              to={
                exchange === "KUCOIN"
                  ? "https://www.kucoin.com/support/360015102174"
                  : "https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072"
              }
              target="blank"
            >
              Learn How
            </Link>
          </button>
        </div>
      </div>
      {/* /> */}
      <DrawerComponent toggleDrawer={toggleDrawer} drawerState={drawerState} />
    </>
  );
}

export default Spotconnectiondetails;
