import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import Exchange_pre from "./Exchange_pre";
import { MdSecurity } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import image1 from "../assets/Api/BINANCEFUTURES.png";
import image2 from "../assets/Api/BINANCESPOT.png";
import image3 from "../assets/Api/BYBITFUTURES.png";
import image4 from "../assets/Api/BYBITSPOT.png";



const Preffred_exchanges = ({ }) => {
  const [stepsData, setStepsData] = useState([
    { title: "Choose Exchange", completed: false },
    { title: "Setup Keys", completed: false },
    { title: "Connect", completed: false },
  ]);

  const [imagesToShow, setImagesToShow] = useState([]);
  const projectName = process.env.REACT_APP_DEMO || "Default Project";
   

  const Navigate = useNavigate();

  const handleStepClick = (stepIndex) => {
    const updatedStepsData = stepsData.map((step, index) => ({
      ...step,
      completed: index === stepIndex && index === 0,
    }));
    setStepsData(updatedStepsData);
  };

  const ViewPackageBuy = async () => {
    const jwt = localStorage.getItem("jwt");
    const formData = new FormData();
    formData.append("project", process.env.REACT_APP_PROJECT);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER_NEW + "exchange",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const res = response.data;
      if (res?.Data) {
        toast(res.Data);

        const botDataWithImages = res.Data.map((exchangeName) => ({
          name: exchangeName,
          image: generateImageUrl(exchangeName),
        }));

        setImagesToShow(botDataWithImages);
      } else {
        toast.error(res?.Error);
      }
    } catch (err) {
      toast.error(err.response?.data?.Error);
    }
  };



    useEffect(() => {
      ViewPackageBuy();
    }, []);

    const generateImageUrl = (symbol) => {
      switch (symbol) {
        case "BINANCEFUTURES":
          return image1;
        case "BINANCESPOT":
          return image2;
        case "KUCOINFUTURES":
          return image3;
        case "KUCOINSPOT":
          return image4;
        default:
          return "";
      }
    };



  return (
    <>
      <div className="flex items-center justify-center  ">
        <div className="  w-full xl:full p-5 from-colorSecondaryDark  to-colorSecondaryDark via-colorPrimary  text-black font-bold  rounded-3xl">
          <div className=" items-center text-center text-lg my-4">
            <h6>Choose an Exchange</h6>
            <div className="flex justify-center items-center  text-justify">
            
              <MdSecurity size={25} fill="#0c2b44"  className="hidden md:block"/>
              <p className="font-normal text-xs mt-2 md:mt-0 md:text-sm">
                {projectName} will not have access to transfer or withdraw your
                assets. Each exchange connects with encrypted API keys
              </p>
            </div>
          </div>
          {/* <Exchange_pre imgSrc={imagesToShow} /> */}
          
          <Exchange_pre imgSrc={imagesToShow} />
           
        </div>
      </div>
    </>
  );
};

export default Preffred_exchanges;
