import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { IoIosLock } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal, Checkbox, Form, Input } from 'antd';


function TransferUser(props) {
  const [selectedOption, setSelectedOption] = useState("");
  const [username, setUsername] = useState("");
  const [amount, setAmount] = useState("");
  const [withdrawal, setWithdrawal] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [authStatus, setAuthStatus] = useState(false)
  const [adminFee, setAdminFee] = useState()
  const [error, setError] = useState('');


  const minWithdrawalAmount = 50;


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { wallet, View_Wallet, profile1 } = props.data;

  const navigate = useNavigate()

  const View_TransferUser = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      username: username,
      amount: parseFloat(amount),
      // totp : 
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_Wallet + "transfer_to_user",
      data: postdata,
    }).then(async (response) => {
      const res = await response.data;
      toast(res.Success);
      View_Wallet();
      setUsername("");
      setAmount("");
    })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.response.data.Error);
      });
  };

  const View_Withdrawal = async (value) => {
    console.log("value", value)
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      amount: parseFloat(withdrawal),
      topt: value?.topt == undefined ? value?.topt : ''
    };

    console.log("postdata", postdata)

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_Wallet + "withdraw_request",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response.data;
        toast(res.Success);
        View_Wallet()
        setWithdrawal("");
        setIsModalOpen(false)
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.response.data.Error);
      });
  };

  const [minTransfer, setMinTransfer] = useState()

  const getData = () => {
    const jwt = localStorage.getItem('jwt')
    const response = axios({
      method: 'POST',
      headers: {
        'Content-Type': "application/json"
      },
      url: process.env.REACT_APP_API_Wallet + 'minimum_withdrawal',
      data: { jwt: jwt }
    }).then(res => {
      setMinTransfer(res?.data?.Success)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    getData()
  })

  const checkAuth = () => {
    const jwt = localStorage.getItem('jwt')
    const postData = {
      project: "Odecent",
      jwt: jwt
    }
    const response = axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: process.env.REACT_APP_API_PATH_USER + 'private_status_totp',
      data: postData
    }).then(res => {
      setAuthStatus(res?.data?.Success)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    checkAuth()
  }, [])

  const withdraw_fees = () => {
    const jwt = localStorage.getItem('jwt')
    const response = axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: process.env.REACT_APP_API_Wallet + 'withdraw_fees',
      data: { jwt: jwt }
    }).then(res => {
      setAdminFee(res?.data?.Success)
    })
      .catch(err => { console.log(err) })
  }

  const adminCharges = (adminFee / 100) * withdrawal

  useEffect(() => {
    withdraw_fees()
  }, [])

  const handleWithdrawalChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Remove characters other than 0-9
    setWithdrawal(value);
    if (parseInt(value) < minWithdrawalAmount) {
      setError('Amount must be $50 or more');
    } else {
      setError('');
    }
  };


  return (
    <>
      <div className="">
        <div className=" bg-colorPrimary shadow-neumorphic1 border-gradient  ">
          <div className=" font-semibold text-center text-2xl mt-5 mb-3 ml-3">
            Transfer to User
          </div>

          <div className='flex w-full flex-col justify-center items-center'>
            <div className="w-full flex justify-center items-center md:block">
              <div className="w-80 md:w-96 my-5 mx-auto">
                <div className="relative w-full min-w-[200px] h-10">
                  <div className="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 right-3 -translate-y-2/4">
                    <i className="fas fa-heart" aria-hidden="true"></i>
                  </div>
                  <input
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="peer w-full h-full  text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] !pr-9 border-blue-gray-200 focus:border-gray-900"
                    placeholder=" "
                  />
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                    Username:
                  </label>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center md:block">
              <div className="md:w-96 w-80 mx-auto">
                <div className="font-bold text-end text-sm">
                  ${" "}
                  {props.data.wallet.Wallet_Balance}
                  &nbsp;available
                </div>
                <div className="relative w-full min-w-[200px] h-10">
                  <div className="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 right-3 -translate-y-2/4">
                    <i className="fas fa-heart" aria-hidden="true"></i>
                  </div>
                  <input
                    type="text"
                    value={amount}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, ''); // Remove characters other than 0-9
                      setAmount(value);
                    }}
                    pattern="[0-9]*"
                    // value={amount}
                    // onChange={(e) => setAmount(e.target.value)}
                    className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] !pr-9 border-blue-gray-200 focus:border-gray-900"
                    placeholder=" "
                  />
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                    Amount:
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <button
                onClick={View_TransferUser}
                className="my-5 active px-5 py-2 rounded-full"
              >
                Transfer
              </button>
            </div>
          </div>
        </div>

        <div className=" relative my-2 bg-colorPrimary shadow-neumorphic1 border-gradient mt-4 ">
          <div className='lg:flex w-full my-2 justify-between items-center'>
            <div className=' flex flex-col ml-3 text-center '>

              <div className="text-lg font-semibold ">Withdrawal</div>
              <p className=' text-xs font-medium'>(Min amount : ${minTransfer})</p>
            </div>
            <div className="flex items-center gap-4 mr-3">
              <div className="w-96">
                <div className="font-bold flex justify-end mx-2 text-sm mr-4 mt-2 md:mt-0">
                  {/* <span>Min amount : ${minTransfer}</span> */}
                  <span>${props.data.wallet.Withdrawal_Balance} available</span>
                </div>
                <div className="relative w-full min-w-[200px] h-10">
                  <div className="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 right-3 -translate-y-2/4">
                    <i className="fas fa-heart" aria-hidden="true"></i>
                  </div>
                  {/* <input
                    type="text"
                    value={withdrawal}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, ''); // Remove characters other than 0-9
                      setWithdrawal(value);
                    }}
                    pattern="[0-9]*"
                    // value={withdrawal}
                    // onChange={(e) => setWithdrawal(e.target.value)}
                    className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] !pr-9 border-blue-gray-200 focus:border-gray-900"
                    placeholder=" "
                  /> */}
                  <input
                    type="text"
                    value={withdrawal}
                    onChange={handleWithdrawalChange}
                    className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] !pr-9 border-blue-gray-200 focus:border-gray-900"
                    placeholder=" "
                  />
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                    Amount:
                  </label>
                  {error && <p className="text-xs text-red-500">{error}</p>}

                </div>
              </div>
              <div className="hidden xl:flex justify-center  items-center">
                <button
                  // onClick={View_Withdrawal}
                  onClick={showModal}
                  disabled={!withdrawal}
                  className={`my-5 active px-5 py-2 rounded-full ${!withdrawal && 'cursor-not-allowed'}`}
                >
                  Transfer
                </button>
              </div>
            </div>
          </div>
          <div className='xl:hidden flex justify-center items-center '>
            <button
              // onClick={View_Withdrawal}
              onClick={showModal}
              disabled={!withdrawal || parseInt(withdrawal) < minWithdrawalAmount}
              className={`my-5 active px-5 py-2 rounded-full ${(!withdrawal || parseInt(withdrawal) < minWithdrawalAmount) && 'cursor-not-allowed'}`}
            >
              Transfer
            </button>
          </div>

          {
            profile1?.Wallet?.Address == "" &&
            <div className="absolute inset-0 text-white flex items-center bg-blur backdrop-blur-sm bg-white/20 justify-center">
              <div className=' flex flex-col justify-center items-center '>
                <IoIosLock size={50} className=' text-black/40' />
                <p onClick={() => navigate('/dashboard/profile')} className='text-black/40 underline cursor-pointer'>
                  Activate Your Wallet at Profile
                </p>
              </div>
            </div>
          }
        </div>
      </div>


      <Modal title={<div className=' text-lg font-semibold'>Wallet Transfer</div>} open={isModalOpen} footer={false} onOk={handleOk} onCancel={handleCancel}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={View_Withdrawal}
          autoComplete="off"
        >

          <div className='flex justify-between font-medium text-sm '><span>Withdrawal Wallet </span><span>${wallet?.Withdrawal_Balance}</span></div>
          <div className='flex justify-between font-medium text-sm '><span>Requested Withdrawal </span><span>{withdrawal}</span></div>
          <div className='flex justify-between font-medium text-sm '><span>Admin fees(5%) </span><span>{adminCharges}</span></div>
          <div className='flex justify-between font-medium text-sm '><span>Final withdrawal price </span><span>{withdrawal - adminCharges}</span></div>
          <div className='flex justify-between font-medium text-sm '><span>To wallet Address </span><span>{profile1?.Wallet?.Address}</span></div>

          {
            authStatus &&
            <Form.Item
              label="2FA"
              name="topt"
              labelAlign='left'
              className=' mt-2'
              rules={[
                {
                  required: true,
                  message: 'Please input your topt!',
                },
              ]}
            >
              <Input placeholder='2FA' className=' h-8' />
            </Form.Item>
          }

          <div>
            <button type="primary" htmlType="submit" className=' block mx-auto active py-1 px-4 rounded-xl mt-4'>
              Request
            </button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default TransferUser