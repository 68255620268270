import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import Apisame from "./Apisame";
import RankCurrent from "./RankCurrent";
import MatrixTran from "../Pages/MatrixTran";
import MAtchingQualTrans from "./MAtchingQualTrans";
import LevelQualTrans from "./LevelQualTrans";
import RankTableData from "./RankTableData";
import MatrixwithoutTrans from "./MatrixwithoutTrans";
import LevelTableData from "./LevelTableData";
import MatchingTableData from "./MatchingTableData";

const TransactionBonus = () => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(18);
  const abbreviatedNames = [
    "Rank_Qualification",
    "Rank_Reward_Transaction",
    "Level_Qual_Transaction",
    "Level_Earning_Transaction",
    "Matrix_Qualification",
    "Matrix_Earning_Transaction",
    "Matching_Income_Qualification",
    "Matching_Income_Transaction",
  ];
  const tabNames = [
    "Rank Status",
    "Rank Reward",
    "Level Status",
    "Level Reward",
    "Matrix Status",
    "Matrix Reward",
    "Matching Status",
    "Matching Reward",
  ];

  const [pageName, setPageName] = useState(abbreviatedNames[0]); 

  const { data, totalPages,setData } = Apisame({ pageName, pageSize, page });

  const handleTabClick = (newIndex, pageName) => {
    setIndex(newIndex);
    setPage(1);
    setPageName(pageName);
    setData([])
  };

  const tabContent = [
    <RankCurrent key={0} data={{ rankqualData: data, page,pageSize,setData, totalPages, setPage, setpageSize }} />,
    <RankTableData key={1} data={{ rankData: data, page, pageSize,setData, totalPages, setPage, setpageSize }} />,
    <LevelQualTrans key={2} data={{ rankqualData: data, page, pageSize,setData, totalPages, setPage, setpageSize }} />,
    <LevelTableData key={3} data={{ levelData: data, page, pageSize, totalPages,setData, setPage, setpageSize }} />,
    <MatrixTran key={4} data={{ martixQualData: data, page, pageSize, totalPages,setData, setPage, setpageSize }} />,
    <MatrixwithoutTrans key={5} data={{ martixData: data, page, pageSize, totalPages,setData, setPage, setpageSize }} />,
    <MAtchingQualTrans key={6} data={{ matchingqualData: data, page, pageSize, totalPages,setData, setPage, setpageSize }} />,
    <MatchingTableData key={7} data={{ matchingData: data, page, pageSize, totalPages, setPage,setData, setpageSize }} />,
  ];

  const tabWidths = tabNames.map(name => `${name.length * 10}px`);

  return (
    <>
      <div className="bg-colorPrimary mx-2 rounded-3xl">
        <div className="mt-5">
          <div className="flex border-b-2 border-[#ccc6] mobilescroll overflow-x-scroll mx-5">
            {tabNames?.map((tabName, tabIndex) => (
              <div
                key={tabIndex}
                
                className={`tab  min-w-fit py-1  uppercase  px-6 mx-6 text-center cursor-pointer text-sm ${index === tabIndex
                    ? "active-tab  border-gradientbottom text-black font-semibold"
                    : "text-[#666666] "
                  }`}
                onClick={() => handleTabClick(tabIndex, abbreviatedNames[tabIndex])}
              >
                {tabName}
              </div>
            ))}
          </div>
        </div>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          className="mt-3 mx-2"
        >
          {tabContent}
        </SwipeableViews>
      </div>
    </>
  );
};

export default TransactionBonus;
