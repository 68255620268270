import { Empty, Table } from "antd";
import moment from "moment";
import React from "react";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";

function LevelTableData(props) {
  const { levelData, pageSize, page, totalPages, setPage, setpageSize } =
    props.data;

  const columns = [
    {
      title: "DATE",
      dataIndex: "Datetime",
      key: "Datetime",
    },
    {
      title: "TRANS ID",
      dataIndex: "TransID",
      key: "TransID",
    },
    {
      title: "FROM",
      dataIndex: "From",
      key: "From",
    },

    {
      title: "SUBSCRIPTION",
      dataIndex: "Subscription",
      key: "Subscription",
    },
    {
      title: "LEVEL",
      dataIndex: "Level",
      key: "Level",
    },


    {
      title: "COMMISSION (%)",
      dataIndex: "Commission",
      key: "Commission",
    },
    {
      title: "COMMISSION ($)",
      dataIndex: "Amount",
      key: "Amount",
    },

    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      render: (text) => (
        <span style={{ color: text === "Success" ? "green" : "red" }}>
          {text}
        </span>
      ),
    },
  ];

  const rows = [];

  if (levelData) {
    for (let i = 0; i < levelData.length; i++) {
      const dateParts = levelData[i].Datetime?.split(" ");
      if (dateParts && dateParts.length === 2 && levelData[i].Subscription) {
        rows.push({
          key: i,
          Datetime: levelData[i].Datetime,
          TransID: levelData[i].TransID,
          Level: levelData[i].Level,
          Subscription: levelData[i].Subscription.replace("BOT", "BOT "),
          Amount: "$" + (levelData[i].Amount),
          Commission: (levelData[i].Commission) + "%",
          From: levelData[i].From,
          Status: levelData[i].Status ? "Success" : "Failed",
        });
      }
    }
  }

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setpageSize(newPageSize);
  };


  const onChange = (pagination, filters, sorter, extra) => { };

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  if (isMobile) {
    return (
      <>
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />

        {rows.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full">

            {rows.map((record) => (

              <div
                key={record.TransID}
                className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
              >
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Date Time:</div>
                  <div className="card-data">{record.Datetime}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">TransID:</div>
                  <div className="card-data">{record.TransID}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">From:</div>
                  <div className="card-data">{record && record.From ? record.From.slice(0, 15) + '...' + record.From.slice(-8) : ''}</div>
                </div>


                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Subscription:</div>
                  <div className="card-data">{record.Subscription}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Level:</div>
                  <div className="card-data">{record.Level}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Commission (%):</div>
                  <div className="card-data">{record.Commission}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Commission ($):</div>
                  <div className="card-data">{record.Amount}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Status:</div>
                  <div className="card-data">
                    <span
                      style={{
                        color: parseFloat(record.Status) > 100 ? "red" : "green",
                      }}
                    >
                      {record.Status}
                    </span>
                  </div>
                </div>
              </div>
            ))}

          </div>
        ) : (
          <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
            <Empty />
          </div>
        )}


        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </>
    );
  }



  return (
    <div>
      <div className=" w-full bg-colorPrimary ">
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <Table
          className="table-newres my-2"
          columns={columns}
          dataSource={rows}
          onChange={onChange}
          pagination={false}
        />
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
}

export default LevelTableData;
