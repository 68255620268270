import React, { useState } from "react";
import { FaWallet } from "react-icons/fa";
import { RiTokenSwapFill } from "react-icons/ri";
import { MdOutlineSwapVert, MdSwapCalls } from "react-icons/md";
import { FaArrowDownLong } from "react-icons/fa6";
import { toast } from "react-toastify";
import axios from "axios";
import { Tooltip, Button, Modal } from 'antd';


function TransferWallet(props) {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption1, setSelectedOption1] = useState("");
  const [wallet1, setWallet] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { wallet, View_Wallet } = props.data;

  const handleOptionSelect = (value) => {
    setSelectedOption(value);
  };

  const handleOptionSelect1 = (value) => {
    setSelectedOption1(value);
  };

  const View_Transfer = async (from, to, amount) => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      from_wallet: typeof from == 'string' ? from : selectedOption,
      to_wallet: to ? to : selectedOption1,
      amount: amount || amount == 0 ? amount : parseFloat(wallet1),
    };

    console.log("postdata", postdata)

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_Wallet + "transfer_wallet",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response.data;
        if (res) {
          toast(res.Success);
          View_Wallet();
          setSelectedOption("");
          setSelectedOption1("");
          setWallet("");
        }

        handleCancel()
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.response.data.Error);
      });
  };

  const handleTransferAll = () => {

    const Wallet = [
      {
        name: 'Network Balance',
        from: "Network_Balance",
        maxAmount: wallet?.Network_Balance
      },
      {
        name: 'Level Earning Balance',
        from: "Level_Earning_Balance",
        maxAmount: wallet?.Level_Earning_Balance
      },
      {
        name: 'Rank Reward Balance',
        from: "Rank_Reward_Balance",
        maxAmount: wallet?.Rank_Reward_Balance
      },
      {
        name: 'Matching Income Balance',
        from: "Matching_Income_Balance",
        maxAmount: wallet?.Matching_Income_Balance
      },
      {
        name: 'Matrix Earning Balance',
        from: "Matrix_Earning_Balance",
        maxAmount: wallet?.Matrix_Earning_Balance
      }
    ]

    const To = "Withdrawal_Balance"

    Wallet.map(item => {
      if (item?.maxAmount > 0) {
        View_Transfer(item?.from, To, item?.maxAmount)
      }
    })
  }


  const handleMaxAmountClick = () => {
    if (selectedOption !== "0" && props.data.wallet[selectedOption]) {
      setWallet(props.data.wallet[selectedOption].toFixed(4));
    }
  };

  const Wallet = [
    {
      from: "Network Balance",
      maxAmount: wallet?.Network_Balance
    },
    {
      from: "Level Earning Balance",
      maxAmount: wallet?.Level_Earning_Balance
    },
    {
      from: "Rank Reward Balance",
      maxAmount: wallet?.Rank_Reward_Balance
    },
    {
      from: "Matching Income Balance",
      maxAmount: wallet?.Matching_Income_Balance
    },
    {
      from: "Matrix Earning Balance",
      maxAmount: wallet?.Matrix_Earning_Balance
    },
  ]

  const TotalTransfer = wallet?.Network_Balance + wallet?.Level_Earning_Balance + wallet?.Rank_Reward_Balance + wallet?.Matching_Income_Balance + wallet?.Matrix_Earning_Balance

  return (
    <>
      <div className=" bg-colorPrimary shadow-neumorphic1 border-gradient ">
        <div className="block md:flex justify-end md:justify-between pl-3 pr-5 pt-4 pb-2">
          <div className="flex justify-end"> 
          <Tooltip title="Transfer all earning wallet to withdrawal wallet " color={"purple"}>
            <button onClick={showModal} className="my-2 active block justify-end md:hidden px-5 py-2 rounded-full">Transfer All</button>
          </Tooltip>
          </div>
          <div className=" font-semibold text-2xl my-3 text-center">Transfer Wallet</div>
          <Tooltip title="Transfer all earning wallet to withdrawal wallet " color={"purple"}>
            <button onClick={showModal} className="my-2 hidden md:block active px-5 py-2 rounded-full">Transfer All</button>
          </Tooltip>

        </div>
        <div className="flex flex-col justify-center items-center">
          <div>
            <div>
            </div>
            <div className="w-full flex justify-center md:block">
            <div className="flex items-center border border-[#ccc] w-80 md:w-96 py-2 px-2 rounded-lg">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center w-20">
                  <RiTokenSwapFill className="mr-2" />
                  <label htmlFor="selectOption" className="mr-3 font-semibold">
                    From{" "}
                  </label>
                </div>
                <div className="flex-grow">
                  <select
                    className="w-full text-sm focus:border-none border-none outline-none "
                    id="selectOption"
                    value={selectedOption}
                    onChange={(e) =>
                      handleOptionSelect(
                        e.target.value,
                        e.target.options[e.target.selectedIndex].text
                      )
                    }
                  >
                    <option value="">Select option</option>
                    {/* <option value="Network_Balance">Network Balance</option> */}
                    <option value="Level_Earning_Balance">
                      Level Earning Balance
                    </option>
                    <option value="Rank_Reward_Balance">
                      Rank Reward Balance
                    </option>
                    <option value="Matching_Income_Balance">
                      Matching Income Balance
                    </option>
                    <option value="Matrix_Earning_Balance">
                      Matrix Earning Balance
                    </option>
                  </select>
                </div>
              </div>
            </div> 
            </div>

            <div className="my-3 w-full flex justify-center md:block">
              <FaArrowDownLong />
            </div>
            <div className="w-full flex justify-center md:block">
            <div className="flex items-center border border-[#ccc] w-80 md:w-96 py-2 px-2 rounded-lg">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center w-20">
                  <MdOutlineSwapVert className="mr-2" />
                  <label htmlFor="selectOption1" className="mr-3 font-semibold">
                    To{" "}
                  </label>
                </div>
                <div className="flex-grow">
                  <select
                    className="w-full text-sm focus:border-none border-none outline-none "
                    id="selectOption1"
                    value={selectedOption1}
                    onChange={(e) =>
                      handleOptionSelect1(
                        e.target.value,
                        e.target.options[e.target.selectedIndex].text
                      )
                    }
                  >
                    <option value="">Select option</option>
                    <option value="Withdrawal_Balance">Withdrawal Balance</option>
                  </select>
                </div>
              </div>
            </div>
            </div>

            <div className="w-80 md:w-96 my-5 ">
              <div className="font-bold text-end text-sm">
                ${" "}
                {selectedOption === "0" ||
                  !props?.data?.wallet ||
                  !props?.data?.wallet[selectedOption]
                  ? "0"
                  : props.data.wallet[selectedOption].toFixed(4)}
                &nbsp;available
              </div>
              <div className="w-full flex justify-center md:block">
              <div className="relative w-80 md:w-96 h-10">
                <div className="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 right-3 -translate-y-2/4">
                  <i className="fas fa-heart" aria-hidden="true"></i>
                </div>
                <input 
                   type="text"
                   value={wallet1}
                   onChange={(e) => {
                     const value = e.target.value.replace(/[^0-9]/g, ''); // Remove characters other than 0-9
                     setWallet(value);
                   }}
                   pattern="[0-9]*"
                  className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-[#ccc] placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-1.5 rounded-[7px] !pr-9 border-blue-gray-200 focus:border-gray-900"
                  placeholder=" "
                />
                <button
                  onClick={handleMaxAmountClick}
                  className="text-sm text-neonPurpleShadow font-semibold right-0  px-5 py-2 rounded-full absolute"
                >
                  MAX
                </button>
                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                  Amount:
                </label>
              </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <button
                onClick={View_Transfer}
                className="my-5 active px-5 py-2 rounded-full"
              >
                Transfer
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* Modal */}

      <Modal title={<div className=" text-xl">One Click Transfer</div>} open={isModalOpen} onOk={handleOk} footer={false} onCancel={handleCancel}>
        {
          Wallet.map(item => {
            return (
              <div className={`flex justify-between`}>
                <p className=" text-sm font-medium">{item?.from} :</p>
                <p>$ {item?.maxAmount}</p>
              </div>
            )
          })
        }
        <div className=" flex justify-between border-black border-t-2 border-dashed pt-2 mt-4">
          <p className=" text-sm font-medium">Total :</p>
          <p>$ {TotalTransfer}</p>
        </div>

        <div className=" flex justify-center mt-4">
          <button onClick={handleTransferAll} disabled={TotalTransfer <= 0} className={`active py-2 px-6 font-medium rounded-2xl ${TotalTransfer == 0 && 'cursor-not-allowed'}`}>Tansfer All</button>
        </div>
      </Modal>
    </>
  );
}

export default TransferWallet;


