import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal } from "antd";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { HandleSession } from "./HandleSession";


function Packages() {
  const [pack, setPack] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const openModal = (packageItem) => {
    setSelectedPackage(packageItem);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedPackage(null);
    setModalVisible(false);
  };

  const View_Pack = async () => {
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PUBLIC + "subscription",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response.data;
        setPack(res.Success);
      })
      .catch((err) => {
        HandleSession(err);
      });
  };

  useEffect(() => {
    View_Pack();
  }, []);


  const View_Packbuy = async (subscriptionName) => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      subscription_name: subscriptionName,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_SUB + "buy_subscription",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response.data;
        toast(res.Success);
        closeModal();

      })
      .catch((err) => {
        toast.error(err.response.data.Error);
        HandleSession(err);
      });
  };

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="container ">
      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 md:gap-x-2 gap-y-4">
        {pack?.map((pack) => (
          <div
            key={pack.ID}
            className="h-auto md:h-[380px] p-4 flex flex-col justify-between"
          >
            <div className="relative transition-all hover:scale-105 transition-5s cursor-pointer">
              {/* <img
                src={`/images/Full & Final/Packages/${pack.Image.replace("png", "webp")}`}
                alt={`package-${pack.ID}`}
                className="mx-auto"
                onLoad={handleImageLoad}
              /> */}

              <img
                src={`/images/Full & Final/Packages/${pack.Image.replace("png", "webp")}?${Date.now()}`}
                alt={`package-${pack.ID}`}
                className="mx-auto"
                onLoad={handleImageLoad}
              />



              {imageLoaded && (
                <div className="absolute bottom-0 w-full text-center text-black">
                  <div className="flex justify-center items-center mb-10 w-full">
                    <button
                      onClick={() => openModal(pack)}
                      className="w-2/3 bg-gradient-to-r from-neonPurple via-neonPurpleShadow to-neonBlue py-2 px-2 transition-all font-semibold transition-10s cursor-pointer shadow-md shadow-black border-solid text-white hover:shadow-2xl hover:shadow-black hover:scale-105 border-gray-200 rounded-full"
                    >
                      View more
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <Modal
        title="Package Details"
        visible={modalVisible}
        onCancel={closeModal}
        footer={false}
        width={800}

      >
        {selectedPackage && (
          <>
            <div className="grid grid-cols-2 gap-5 my-6">
              <div>
                <img
                  src={`/images/Full & Final/Packages/${selectedPackage.Image.replace(
                    "png",
                    "webp"
                  )}`}
                  alt={`package-${selectedPackage.ID}`}
                  className="mx-auto spin-animation"
                />
              </div>
              <div className="">
                <div className=" flex justify-between"><span className=" text-sm font-medium flex items-center gap-4"><IoCheckmarkDoneCircle size={24} className=" text-green-400" />
                  Package Name:</span> {selectedPackage?.Name}</div>
                <div className=" flex justify-between"><span className=" text-sm font-medium flex items-center gap-4"><IoCheckmarkDoneCircle size={24} className=" text-green-400" />
                  Price:</span> $ {selectedPackage?.Price}</div>
                <div className=" flex justify-between"><span className=" text-sm font-medium flex items-center gap-4"><IoCheckmarkDoneCircle size={24} className=" text-green-400" />
                  Validity:</span> {selectedPackage?.Validity} days</div>
                <div className=" flex justify-between"><span className=" text-sm font-medium flex items-center gap-4"><IoCheckmarkDoneCircle size={24} className=" text-green-400" />
                  Live APIs:</span> {selectedPackage?.Live_API}</div>
                <div className=" flex justify-between"><span className=" text-sm font-medium flex items-center gap-4"><IoCheckmarkDoneCircle size={24} className=" text-green-400" />
                  Exchange :</span> {selectedPackage?.Exchange}</div>
                <div className=" flex justify-between"><span className=" text-sm font-medium flex items-center gap-4"><IoCheckmarkDoneCircle size={24} className=" text-green-400" />
                  Live Bots :</span> {selectedPackage?.Live_Bots}</div>
                <div className=" flex justify-between"><span className=" text-sm font-medium flex items-center gap-4"><IoCheckmarkDoneCircle size={24} className=" text-green-400" />
                  Max Fund Allotted :</span> $ {selectedPackage?.Max_Fund_Allotted}</div>
              </div>

              <div>

              </div>
              <div>
                <button key="subscribe" className="border border-[#ccc] active font-bold text-white rounded-lg  px-4 py-2 w-1/2 block mx-auto" onClick={() => View_Packbuy(selectedPackage?.Name)}>
                  subscribe Now
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default Packages;
