import React, { useState } from 'react'
import { Button, Card, Form, Input, QRCode, Tooltip, message } from 'antd';
import { CopyOutlined } from "@ant-design/icons";
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';


function PaymentGateway({data}) {
  const location = useLocation();
  //  const { Receiver_Wallet_Address, Amount, Asset, TransID } = props.data;
  // const connectloctState = location.state?.Receiver_Wallet_Address;
  // const connectloctState1 = location.state?.Amount;
  // const connectloctState2 = location.state?.Asset;
  // const connectloctState3 = location.state?.TransID;
  // const connectloctState = Receiver_Wallet_Address;
  // const connectloctState1 = Amount;
  // const connectloctState2 = Asset;
  // const connectloctState3 = TransID;
  const [inputValue, setInputValue] = useState(data.Address);
  
  const projectName = process.env.REACT_APP_DEMO || "Default Project";
  function handleCopy() {
    navigator.clipboard
    .writeText(inputValue)
      .then(() => {
        toast("Text copied to clipboard.");
      })
      .catch((error) => {
        message.error("Copy failed. Please try again.");
      });
  }
  
 
  const splitAsset = data.Coin ? data.Coin.split("_") : [];
 
  const navigate = useNavigate();
  const ViewPackagePayment = async () => {
    const jwt = localStorage.getItem("jwt");
    const formData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      // transid: connectloctState3,
    };
    // const formData = new FormData();

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_PAY + "submit_payment",
      data: formData,
    })
      .then(async function (response) {
        const res = response.data.Success;
        toast(res);
        if (res === "Payment received successfully!") {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  return (
    <div>
      <div className="container ">
        <div className="flex justify-center items-center">
          <div className=" rounded-3xl h-auto w-full  p-3">
            <div>
              <div className="text-3xl text-center font-semibold py-2 text-colorSecondaryDark">
                You have selected a {splitAsset[1]} Network
              </div>
              <div className="text-center px-2 py-1 text-md">
              
                Scan the QR code below or Deposit the ${splitAsset[1]}{" "}
                {splitAsset[0]}  {splitAsset[1]}&nbsp; into the below address
                and wait for network confirmation.
              </div>
            </div>
            <div className="flex justify-center ">
              <div className=" text-md list-disc my-1 border-2 w-44 text-center text-colorSecondaryDark border-colorSecondary border-dotted  font-semibold mt-1 px-2 rounded-full">
                ${splitAsset[1]} &nbsp;
                {splitAsset[0]}
              </div>
            </div>
            <div className="text-center flex justify-center">
              <Card className="shadow-neumorphicnew bg-colorPrimary ">
                <QRCode
                  value={data.QR_Code ? data.QR_Code : ""}
                  renderAs="canvas"
                />
              </Card>
              {/* <img src={img1} alt="payment" /> */}
            </div>
            <div className="text-center py-3">
              {" "}
              Send Your payment to the Provided wallet address
            </div>

            <div className="grid grid-cols-1 px-3 gap-2 py-2 items-center justify-center justify-items-center">
              <div className="w-full md:w-2/3 text-sm font-semibold  ">
                <div className="flex justify-center items-center rounded-3xl px-4 py-2 bg-colorPrimary shadow-neumorphicnew ">
                  <input
                    className=" appearance-none  bg-transparent w-full  text-black leading-tight focus:outline-none "
                    value={data.Address}
                    readOnly
                    onChange={(e) => setInputValue(e.target.value)}
                    style={{ background: "transparent" }}
                  ></input>
                  <Tooltip title="Copy">
                    <CopyOutlined
                      onClick={handleCopy}
                      style={{
                        color: "#000",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
                <span
                  id="copiedMessage"
                  style={{ display: "none", color: "green" }}
                >
                  Copied
                </span>
              </div>
            </div>
            <div className="text-center text-xs pb-1 text-[red]">
              Only Deposit {splitAsset[0]} {splitAsset[1]} into above address,
              depositing any other currency will result in loss of funds.
            </div>
            <div className="text-center text-sm">
              Need help? Contact :- support@{projectName}.com
            </div>
            <div className="text-center text-xs xl:text-base py-3">
              You can close this window anytime without impacting your payment
              process.
            </div>

            <div className="flex justify-center pt-2 gap-3"></div>
            {/* <div className="flex md:flex-row flex-col justify-between mx-3">
              <Link to="/dashboard/deposit">
                <button
                  // onClick={getverifyotp}
                  className="active my-2 lg:my-0 text-white py-1 mr-1 px-2 rounded-lg hover:text-colorSecondary hover:border-2 hover:border-colorSecondary hover:bg-white"
                >
                  Cancel
                </button>
              </Link>
              <button
                onClick={ViewPackagePayment}
                className="active text-white py-1 px-2 rounded-lg hover:text-colorSecondary hover:border-2 hover:border-colorSecondary hover:bg-white"
              >
                Amount Transfered to Above Address
              </button>
            </div> */}
            {/* <p>
              Send Your payment to the Provided wallet address
              0xEA1F91021BeD0EC78CA9de789BcBd4dDcD62C4
            </p>
            <p>
              Send Your payment to the Provided wallet address
              0xEA1F91021BeD0EC78CA9de789BcBd4dDcD62C4
            </p>
            <p>
              You can close this window anytime without impacting your payment
              process.
            </p>
            <p>CountDown: 18 seconds</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentGateway