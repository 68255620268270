import { Button, Modal, Skeleton, Tabs, Tag } from "antd";
import React, { Component, useEffect, useState, useRef } from "react";
import "../assets/css/style.css";
import AllBot1 from "../Components/AllBot1";
import axios from "axios";
import image1 from "../assets/CoinsNew/BTCUSDT.png";
import image2 from "../assets/CoinsNew/BNBUSDT.png";
import image3 from "../assets/CoinsNew/ETHUSDT.png";
import image4 from "../assets/CoinsNew/DOGEUSDT.png";
import image5 from "../assets/CoinsNew/GALAUSDT.png";
import image6 from "../assets/CoinsNew/OCEANUSDT.png";
import image7 from "../assets/CoinsNew/ADAUSDT.png";
import image8 from "../assets/CoinsNew/SOLUSDT.png";
import image9 from "../assets/CoinsNew/MANAUSDT.png";
import { MdAccountBalanceWallet } from "react-icons/md";
import ButtonApi from "../Components/ButtonApi";
import SkeltorLoader from "../Components/SkeltorLoader"
import { BsFillInboxFill } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { TbFilterCog } from "react-icons/tb";
import { IoGrid } from "react-icons/io5";
import { FaList } from "react-icons/fa6";

const { TabPane } = Tabs;

function Botmainpagecopy(props) {
  const [isActive, setIsActive] = useState("");
  const [coinData, setCoinData] = useState({
    Futures_Coin: [],
    Spot_Coin: [],
  });
  const [exchnagelist, setexchnagelist] = useState([]);
  const [filteredBot, setFilteredBot] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [botData, setBotData] = useState([]);
  const [filter, setFilter] = useState("FUTURES");
  const [filterBot, setFilterBot] = useState([]);
  const [filter1, setFilter1] = useState("hightolow");
  const [selectedBotID, setSelectedBotID] = useState("");
  const [loading, setLoading] = useState(true);

  const jwt = localStorage.getItem("jwt");

  const { handleGridClick, handleListClick } = props.data

  const handleFilterClick = (filterType) => {
    setFilter(filterType);
    setSelectedOption();
    setSelectedBotID("");
  };

  useEffect(() => {
    const masterfilter = botData?.filter((item) => {
      return (
        (filter === "ALL" || item?.Trade_Type === filter) &&
        (!selectedOption?.length || selectedOption?.includes(item?.Symbol)) &&
        (!selectedBotID?.length || selectedBotID?.includes(item?.Name))
      );
    });

    setFilterBot(masterfilter);
  }, [filter, selectedOption, selectedBotID, botData]);

  const uniqueSymbolsSet = new Set();
  const uniqueSymbolsArray = [];
  const AllBotName = [];

  if (botData) {
    botData.forEach((item) => {
      const { Symbol, Name } = item;
      if (!uniqueSymbolsSet.has(Symbol)) {
        uniqueSymbolsSet.add(Symbol);
        uniqueSymbolsArray.push(Symbol);
      }

      if (!uniqueSymbolsSet.has(Name)) {
        uniqueSymbolsSet.add(Name);
        AllBotName.push(Name);
      }
    });
  }

  const filterUniqueSymbols = () => {
    if (filter === "ALL") {
      return uniqueSymbolsArray;
    } else if (filter === "SPOT") {
      return uniqueSymbolsArray.filter((symbol) =>
        botData.some(
          (item) => item.Trade_Type === "SPOT" && item.Symbol === symbol
        )
      );
    } else if (filter === "FUTURES") {
      return uniqueSymbolsArray.filter((symbol) =>
        botData.some(
          (item) => item.Trade_Type === "FUTURES" && item.Symbol === symbol
        )
      );
    }
  };

  const exchageName = botData.filter((item) => item.Trade_Type == filter);

  const filtrBotNameSet = new Set();
  const filtrBotName = [];

  if (exchageName.length > 0) {
    exchageName.forEach((item) => {
      const { Name } = item;
      if (!filtrBotNameSet.has(Name)) {
        filtrBotNameSet.add(Name);
        filtrBotName.push(Name);
      }
    });
  }

  const ViewBot = async (e) => {
    console.log("running view bot");
    const formData = new FormData();
    formData.append("project", process.env.REACT_APP_PROJECT);

    try {
      const response = await axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH + "view_bot",
        data: formData,
      });

      const botDataWithImages = await Promise.all(
        response?.data?.Data?.map(async (botItem) => {
          const data = await ViewBotinfo(
            botItem?.VarBotID ? botItem?.VarBotID : ""
          );
          return {
            ...botItem,
            images: generateImageUrl(botItem?.Symbol),
            data: data,
            avg_profite: botItem?.Avg_Profit_Percent['1W']
          };
        })
      );

      const sortedFilterBot = [...botDataWithImages].sort((a, b) => {
        const profitA = parseFloat(a.Avg_Profit_Percent["1W"]) || 0;
        const profitB = parseFloat(b.Avg_Profit_Percent["1W"]) || 0;
        return profitB - profitA;
      });

      console.log(botDataWithImages, "botDataWithImages");
      setBotData(sortedFilterBot);
      setLoading(false);
    } catch (err) {
      console.error(err?.response?.data?.Error);
      setLoading(false);
    }
  };

  const filterBotNamesByExchange = () => {
    if (filter === "ALL") {
      return AllBotName;
    } else if (filter === "SPOT") {
      return AllBotName.filter((botName) =>
        botData.some(
          (item) => item.Trade_Type === "SPOT" && item.Name === botName
        )
      );
    } else if (filter === "FUTURES") {
      return AllBotName.filter((botName) =>
        botData.some(
          (item) => item.Trade_Type === "FUTURES" && item.Name === botName
        )
      );
    }
  };

  const ViewBotinfo = async (botId) => {
    if (botId) {
      const postData = {
        jwt: jwt,
        project: process.env.REACT_APP_PROJECT,
        bot_id: botId,
      };

      try {
        const response = await axios.post(
          process.env.REACT_APP_API_PATH_BOT + "bot_info",
          postData
        );
        return response?.data?.Success;
        // response.data;
      } catch (error) {
        console.error(error?.response?.data?.Error);
        return null;
      }
    }
  };

  useEffect(() => {
    ViewBot();

  }, []);

  const tradable_asset = async () => {
    try {
      const formData = new FormData();
      formData.append("project", process.env.REACT_APP_PROJECT);
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER_NEW + "tradable_asset",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = response?.data?.Success || {};
      const allCoins = [
        ...new Set([...data?.Futures_Coin, ...data?.Spot_Coin]),
      ];

      setCoinData(data);
      // setFilteredCoins(allCoins);
    } catch (err) {
      console.error(err);
    }
  };

  const bot_list = async () => {
    try {
      const formData = new FormData();
      formData.append("project", process.env.REACT_APP_PROJECT);
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER_NEW + "bot_list",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response?.data?.Success || {};
      const allBot = [...new Set([...data?.FUTURES, ...data?.SPOT])];
      setFilteredBot(allBot);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    tradable_asset();
    bot_list();
  }, []);

  const generateImageUrl = (symbol) => {
    switch (symbol) {
      case "BTCUSDT":
        return image1;
      case "BNBUSDT":
        return image2;
      case "ETHUSDT":
        return image3;
      case "DOGEUSDT":
        return image4;
      case "SOLUSDT":
        return image8;
      case "GALAUSDT":
        return image5;
      case "OCEANUSDT":
        return image6;
      case "ADAUSDT":
        return image7;
      case "MANAUSDT":
        return image9;

      default:
        return "";
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionChange1 = (event) => {
    setSelectedBotID(event.target.value);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModal1 = () => {
    setIsModalVisible1(true);
  };


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  const sortedFilterBotHighToLow = () => {
    setFilter1("hightolow");

    const sortedFilterBot = [...filterBot].sort((a, b) => {
      const profitA = parseFloat(a.Avg_Profit_Percent["1W"]) || 0;
      const profitB = parseFloat(b.Avg_Profit_Percent["1W"]) || 0;
      return profitB - profitA;
    });
    setFilterBot(sortedFilterBot);
  };

  const sortedFilterBotLowToHigh = () => {
    setFilter1("lowtohigh");

    const sortedFilterBot = [...filterBot].sort((a, b) => {
      const profitA = parseFloat(a.Avg_Profit_Percent["1W"]) || 0;
      const profitB = parseFloat(b.Avg_Profit_Percent["1W"]) || 0;
      return profitA - profitB;
    });
    setFilterBot(sortedFilterBot);
  };

  const [showOptions, setShowOptions] = useState(false);
  const [showOptions1, setShowOptions1] = useState(false);

  const options = filterUniqueSymbols();

  const handleOptionSelect = (option) => {
    const updatedOptions = Array.isArray(selectedOption) ? [...selectedOption] : [];

    if (updatedOptions.includes(option)) {
      updatedOptions?.splice(updatedOptions?.indexOf(option), 1);
    } else {
      updatedOptions?.push(option);
    }

    setSelectedOption(updatedOptions);
  };

  const handleOptionSelect1 = (option) => {
    const updatedOptions = Array.isArray(selectedOption) ? [...selectedOption] : [];

    if (updatedOptions.includes(option)) {
      updatedOptions?.splice(updatedOptions?.indexOf(option), 1);
    } else {
      updatedOptions?.push(option);
    }

    setSelectedBotID(updatedOptions);
  };

  const handleChangeProfit = (updatedProp, index) => {
    console.log("===>>>", updatedProp, index)
    const updatedFilter = [...filterBot];
    updatedFilter[index].avg_profite = updatedProp;
    setFilterBot(updatedFilter)
  }

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRef1 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
        setShowOptions1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const item = [
    {
      name : "Future",
      click : "FUTURES"
    },
    {
      name : "Spot",
      click : "SPOT"
    },
    {
      name : "Scalping Bot",
      click : ""
    },
  ]


  return (
    <>
      <header className=" container flex fixed shadow-neumorphic bg-colorPrimary justify-between z-30  w-100 md:w-8/12 Block lg:hidden ">
        <div className=" xl:hidden block ">
          <div type="primary" onClick={showModal1} className="">
            <TbFilterCog size={25} className="text-colorSecondaryDark" />
          </div>
          <Modal
            title="Status"
            visible={isModalVisible1}
            // onOk={false}
            footer={false}
            onCancel={handleCancel1}
          >
            <div className="shadow-neumorphic rounded-3xl bg-colorPrimary pt-2 w-full mb-2 mt-3 lg:mt-0">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 lg:mx-5 mb-0 px-2 lg:px-4 sm:grid-cols-4">
                <div className="flex justify-center flex-col items-center lg:space-x-4 ">
                  <div
                    htmlFor="selectOption3"
                    className="text-colorSecondaryDark text-xs font-semibold"
                  >
                    Exchange
                  </div>
                  <div className="p-2 ">
                    <div className="flex mb-4 items-center justify-end">
                      <button
                        className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md drop-shadow-xl ${filter === "ALL"
                          ? "bg-colorSecondaryDark text-white border-2 border-white "
                          : "bg-white text-colorSecondaryDark"
                          }`}
                        onClick={() => handleFilterClick("ALL")}
                      >
                        All
                      </button>
                      <button
                        className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "SPOT"
                          ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                          : "bg-white text-colorSecondaryDark"
                          }`}
                        onClick={() => handleFilterClick("SPOT")}
                      >
                        Spot
                      </button>
                      <button
                        className={`mr-2 xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "FUTURES"
                          ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                          : "bg-white text-colorSecondaryDark"
                          }`}
                        onClick={() => handleFilterClick("FUTURES")}
                      >
                        Future
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center lg:space-x-4 ">
                  <label
                    htmlFor="selectOption3"
                    className="text-colorSecondaryDark font-semibold"
                  >
                    FROM
                  </label>
                  <div className="p-2 ">
                    <div className="flex mb-4 items-center justify-end">
                      <button
                        className={`mr-2 px-1 2xl:w-28 text-sm py-1 rounded-full w-[100px] shadow-md drop-shadow-xl ${filter1 === "hightolow"
                          ? "bg-colorSecondaryDark text-white border-2 border-white "
                          : "bg-white text-colorSecondaryDark"
                          }`}
                        onClick={sortedFilterBotHighToLow}
                      >
                        High to Low
                      </button>

                      <button
                        className={`px-1 py-1 rounded-full text-sm w-28 shadow-md ${filter1 === "lowtohigh"
                          ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                          : "bg-white text-colorSecondaryDark"
                          }`}
                        onClick={sortedFilterBotLowToHigh}
                      >
                        Low to High
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  ref={dropdownRef}
                  className="flex justify-around items-center md:contents"
                >
                  <div className="flex flex-col items-center  lg:space-x-4 ">
                    {/* <label
                      htmlFor="selectOption"
                      className="text-colorSecondaryDark font-semibold"
                    >
                      Symbols for ALL
                    </label> */}
                    <div className="p-2 ">
                      <div>
                        <div className="relative">
                          <button
                            id="selectedOption"
                            // value={selectedOption}
                            onClick={() => setShowOptions(!showOptions)}
                            className="bg-colorPrimary  shadow-neumorphicnew text-sm mt-1 px-5 py-1 rounded-full"
                          >
                            Select Symbols..
                          </button>

                          {showOptions && (
                            <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                              {filterUniqueSymbols()?.map((option, index) => (
                                <label key={index} className="block p-2 ">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleOptionSelect(option)}
                                    className=" mr-2"
                                  />
                                  {option}
                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    ref={dropdownRef1}
                    className="flex flex-col items-center  lg:space-x-4 my-2 lg:my-0"
                  >
                    {/* <label
                      htmlFor="selectOption1"
                      className="text-colorSecondaryDark font-semibold"
                    >
                      Bot Name
                    </label> */}
                    <div className="p-2 ">
                      <div>
                        <div className="relative">
                          <button
                            id="selectOption1"
                            // value={selectedOption}
                            onClick={() => setShowOptions1(!showOptions1)}
                            className="bg-colorPrimary shadow-neumorphicnew text-sm mt-1 px-4 py-1 rounded-full"
                          >
                            Select Bot Name..
                          </button>

                          {showOptions1 && (
                            <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                              {filterBotNamesByExchange()?.map(
                                (option, index) => (
                                  <label key={index} className="block p-2 ">
                                    <input
                                      type="checkbox"
                                      onChange={() =>
                                        handleOptionSelect1(option)
                                      }
                                      className=" mr-2"
                                    />
                                    {option}
                                  </label>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="  xl:hidden block ">
          <div
            type="primary"
            onClick={showModal}
            className="   text-colorSecondaryDark"
          >
            <MdAccountBalanceWallet size={25} className="" />
          </div>
          <Modal
            title="Status"
            visible={isModalVisible}
            // onOk={false}
            footer={false}
            onCancel={handleCancel}
          >
            <ButtonApi />
          </Modal>
        </div>
      </header>
      {/* <div className="bg-colorSecondaryDark rounded-lg pt-2 mx-5 mb-2 mt-3 lg:mt-0 hidden lg:block">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 lg:mx-5 mb-0 px-2 lg:px-4 sm:grid-cols-1">
          <div className="flex justify-center flex-col items-center lg:space-x-4 ">
            <div className="p-2 ">
              <div className="flex mb-4 items-center justify-end">
                <button
                  className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md drop-shadow-xl ${filter === "ALL"
                    ? "active text-white border-2 border-white "
                    : "bg-white text-neonPurple"
                    }`}
                  onClick={() => handleFilterClick("ALL")}
                >
                  All
                </button>
                <button
                  className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "SPOT"
                    ? "active text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-neonPurple"
                    }`}
                  onClick={() => handleFilterClick("SPOT")}
                >
                  Spot
                </button>
                <button
                  className={`mr-2 xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "FUTURES"
                    ? "active text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-neonPurple"
                    }`}
                  onClick={() => handleFilterClick("FUTURES")}
                >
                  Future
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center lg:space-x-4 ">
            <div className="p-2 ">
              <div className="flex mb-4 items-center justify-end">
                <button
                  className={`mr-2 px-1 2xl:w-28 text-sm py-1 rounded-full w-[100px] shadow-md drop-shadow-xl ${filter1 === "hightolow"
                    ? "active text-white border-2 border-white "
                    : "bg-white text-neonPurple"
                    }`}
                  onClick={sortedFilterBotHighToLow}
                >
                  High to Low
                </button>
                <div
                  className="css-xxr2ul"
                  style={{ transform: "rotate(-45deg)" }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15 12C15 13.6569 13.6569 15 12 15C10.6938 15 9.58254 14.1652 9.17071 13H3V11H9.17071C9.58254 9.83481 10.6938 9 12 9C13.6569 9 15 10.3431 15 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
                      fill="#848E9C"
                    ></path>
                  </svg>
                </div>

                <button
                  className={`px-1 py-1 rounded-full text-sm w-28 shadow-md ${filter1 === "lowtohigh"
                    ? "active text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-neonPurple"
                    }`}
                  onClick={sortedFilterBotLowToHigh}
                >
                  Low to High
                </button>
              </div>
            </div>
          </div>
          

        </div>
      </div> */}

      <div className="px-6 my-8 flex justify-between">
        <div className="flex gap-8">
          {
            item.map(item => {
              return (
                <button
                  onClick={() => handleFilterClick(item?.click)}
                  className={`border-4 shadow-xl py-1 px-8  ${
                    filter === item?.click
                      ? "border-gradient1 border-4 border-solid rounded-full"
                      : " border-gray-200 rounded-xl"
                  }`}
                  
                >
                  {item?.name}
                </button>
              );
            })
          }
        </div>

        <div className=" flex gap-8">
          <IoGrid
            size={24}
            onClick={handleGridClick}
            style={{ cursor: "pointer" }}
          />
          <FaList
            size={24}
            onClick={handleListClick}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>

      {/* -------------------mobile view--------------- */}

      <div>
        <div>
          <div className=" grid xl:grid-cols-3 2xl:grid-cols-4 lg:grid-cols-2 grid-cols-1 gap-5 px-5 mt-12 lg:mt-0">
            {loading
              ? Array?.from({ length: 12 })?.map((_, index) => (
                <div
                  key={index}
                  className={`pt-4 bg-colorPrimary text-black md:h-40 px-6 relative rounded-xl mt-4 shadow-neumorphic`}
                >
                  <SkeltorLoader />
                </div>
              ))
              : // filterBot.length > 0 ?
              filterBot?.map((botItemMap, index) => {
                const averageProfitPercent = botItemMap?.data?.botItemMap;
                const formattedAverageProfitPercent =
                  averageProfitPercent !== undefined
                    ? averageProfitPercent?.toFixed(2)
                    : "";

                const myObject = {
                  key1: "value1",
                  key2: "value2",
                  key3: "value3",
                };

                const oneWeek = botItemMap.Avg_Profit_Percent["1W"];

                return (
                  <AllBot1
                    key={index}
                    myProp={{
                      id: index,
                      exchnagedata: exchnagelist,
                      Name: botItemMap?.Name,
                      VarBotID: botItemMap?.VarBotID,
                      Trade_Type: botItemMap?.Trade_Type,
                      Symbol: botItemMap?.Symbol,
                      images: botItemMap?.images,
                      CiEdit: isActive,
                      days: botItemMap?.data?.No_of_days,
                      Avg_Profit_Percent: botItemMap?.Avg_Profit_Percent,
                      avg_profite: botItemMap?.avg_profite,
                    }}
                    onPropChange={(updatedProp) =>
                      handleChangeProfit(updatedProp, index)
                    }
                  />
                );
              })}
          </div>
          {filterBot.length === 0 && !loading && (
            <div className="flex justify-center flex-col w-100 h-96 items-center text-5xl text-neonPurple">
              {/* <BsFillInboxFill size={35} />
              No Bot Active */}
              Comming soon...
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Botmainpagecopy;
