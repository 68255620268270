import React from "react";
import { Drawer } from "antd";

const DrawerComponent = ({ toggleDrawer, drawerState }) => {
  return (
    <Drawer
      placement="right"
      onClose={() => toggleDrawer([])}
      visible={drawerState.visible}
      width={300}
      headerStyle={{ display: "none" }}
      style={{ border: "0" }}
    >
      <div className="bg-colorPrimary h-auto min-h-full text-center">
        <h2 className="text-lg font-bold text-black bg-colorPrimary py-2">
          Help
        </h2>
        <div>
          {drawerState?.tooltipTexts?.find(
            (item) => item.title === drawerState.activetabtitle
          ) && (
            <div>
              {drawerState.tooltipTexts
                .filter((item) => item.title === drawerState.activetabtitle)
                .map((item, index) => (
                  <div key={index}>
                    <div
                      className={` pb-4 pt-3 rounded-2xl mx-2 mb-6 border-neonPurple border-2 shadow shadow-neumorphic text-white bg-colorSecondaryDark`}
                    >
                      <h4 className="text-xl font-normal mb-2 pl-2 text-primaryColor text-left">
                        {item.title} :
                      </h4>
                      <p className="p-2 rounded-md text-black border-2 bg-white mx-2 border-neonPurple shadow-md leading-4 text-justify text-xs">
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          )}

          {/* Render the rest of the items */}
          {drawerState?.tooltipTexts
            ?.filter((item) => item.title !== drawerState.activetabtitle)
            .map((item, index) => (
              <div key={index}>
                <div
                  className={`text-black border-2 mb-6 pb-4 pt-3 rounded-2xl mx-2 bg-colorPrimary shadow-neumorphic`}
                >
                  <h4 className="text-xl font-normal mb-2 pl-2 text-primaryColor text-left">
                    {item.title} :
                  </h4>
                  <p className="p-2 rounded-md text-black border-2 bg-white mx-2 border-neonPurple shadow-neumorphic leading-4 text-justify text-xs">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerComponent;

