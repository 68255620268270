import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import Ranktab from "../Components/Ranktab";
import LevelTab from "../Components/LevelTab";
import MatrixCard from "../Components/MatrixCard";
import MatchingIncomeCard from "../Components/MatchingIncomeCard";

const SwipeableTabs = () => {
  const [index, setIndex] = useState(0);
  const [rankDataFetched, setRankDataFetched] = useState(false);

  useEffect(() => {
    if (index === 0 && !rankDataFetched) {
      setRankDataFetched(true); 
    }
  }, [index, rankDataFetched]);

  const handleChangeIndex = (newIndex) => {
    setIndex(newIndex);
  };

  return (
    <>
      <div className="mx-5 rounded-3xl">
        <div className="mt-1 md:mt-3">
          <div className="flex border-b-2 border-[#ccc6] mobilescroll">
            <div
              className={`tab uppercase min-w-fit py-1 text-sm px-6 text-center cursor-pointer ${
                index === 0
                  ? "active-tab border-gradientbottom  text-black font-semibold"
                  : "text-[#666666] "
              }`}
              onClick={() => setIndex(0)}
            >
              Rank & Rewards
            </div>
            <div
              className={`tab py-1 uppercase text-sm px-6 mx-3 text-center cursor-pointer ${
                index === 1
                  ? "active-tab border-gradientbottom text-black font-semibold"
                  : "text-[#666666] "
              }`}
              onClick={() => setIndex(1)}
            >
              Level
            </div>
            <div
              className={`tab py-1 uppercase text-sm px-6 text-center cursor-pointer ${
                index === 2
                  ? "active-tab border-gradientbottom text-black font-semibold"
                  : "text-[#666666] "
              }`}
              onClick={() => setIndex(2)}
            >
              Matrix
            </div>
            <div
              className={`tab py-1 uppercase text-sm px-6 text-center cursor-pointer ${
                index === 3
                  ? "active-tab border-gradientbottom text-black font-semibold"
                  : "text-[#666666] "
              }`}
              onClick={() => setIndex(3)}
            >
              Matching Income
            </div>
          </div>
        </div>
        <SwipeableViews
          index={index}
          onChangeIndex={handleChangeIndex}
          className="mx-4"
        >
          <div>{index === 0 && <Ranktab />}</div>
          <div>{index === 1 && <LevelTab />}</div>
          <div>{index === 2 && <MatrixCard />}</div>
          <div>{index === 3 && <MatchingIncomeCard />}</div>
        </SwipeableViews>
      </div>
    </>
  );
};

export default SwipeableTabs;

