import React, { useEffect, useState } from "react";
import axios from "axios";
import LevelQualTable from "./LevelQualTable";

function LevelQualTrans(props) {
  const { rankqualData, pageSize, page, totalPages, setPage, setpageSize } =
    props.data;

  const [LevelData, setLevelData] = useState([]);
  const [Qual, setQual] = useState("");

  const getlevel = () => {
    axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PUBLIC + "level",
    })
      .then((res) => {
        setLevelData(res.data.Success);
      })
      .catch((err) => console.log(err));
  };
  const getQuali = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_Level + "current_level",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response.data;
        setQual(res.Success.Level);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getlevel();
    getQuali();
  }, []);

  return (
    <>

      <div>
        {LevelData.map((item, idx) => (
          item.Level === Qual ? (
            <div className="flex flex-col items-center mt-5" key={idx}>
              <img
                src={`/images/TableFull & Final/Level/${item.Image.replace("png", "webp")}`}
                alt={`item-image-${item.ID}`}
              />
            </div>
          ) : null
        ))}
        {LevelData.every(item => item.Level !== Qual) && (
          <div className="text-center text-gray-500 mt-5">
            No qualified Level
          </div>
        )}
      </div>

      <LevelQualTable
        data={{
          rankqualData: rankqualData,
          page: page,
          pageSize: pageSize,
          totalPages: totalPages,
          setPage: setPage,
          setpageSize: setpageSize,
        }}
      />
    </>
  );
}

export default LevelQualTrans;
