import React, { useEffect, useState } from "react";
import { Avatar, Col, Image, Modal, Row, Slider, Tabs, Tooltip, Button, Checkbox, Form, Input, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Notifications from "../Components/Notifications";
import ChangePassword from "../Components/ChangePAssword";
import axios from "axios";
import image1 from "../assets/Profile/1.webp";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import Auth from "../Components/Auth";

import ProfileDetails from "../Components/ProfileDetails";
import WalletDetails from "../Components/WalletDetails";

const Profile = (props) => {

  const param = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const verified = searchParams.get("verified");
  // const successValue = props?.location?.state?.successValue;
  const [profile, setProfile] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log(profile, "profile");

  const View_Profile = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setProfile(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };


  useEffect(() => {
    View_Profile();
  }, []);

  const [toggle, setToggle] = useState({
    System: false,
    API: false,
    Fuel: false,
    Fund: false,
  });



  const onFinish = (values) => {
    const jwt = localStorage.getItem('jwt')

    const postData = {
      ...values,
      jwt: jwt,
      network: "BEP.20"
    }

    const response = axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: process.env.REACT_APP_API_USER + "update_wallet_address",
      data: postData
    }).then(res => {
      toast(res?.data?.Success)
      setIsModalOpen(false)
      View_Profile()
    })
      .catch(err => {
        console.log(err)
        message.error(err?.response?.data?.Error)
      })
  };

  

  return (
    <>
      <div></div>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 container gap-5">

          <div className=" md:px-0 bg-colorPrimary shadow-neumorphic py-5 shadow-lg rounded-lg">
            <ProfileDetails View_Profile={View_Profile} profile={profile} />
          </div>
          <div className="">
            <div className=" flex flex-col  justify-center items-center h-full w-full  bg-colorPrimary shadow-neumorphic  shadow-lg rounded-lg">
              <ChangePassword />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-6 gap-5 container">
          <div className=" col-span-6 md:col-span-2 px-4 md:px-0 bg-colorPrimary shadow-neumorphic py-5 shadow-lg rounded-lg">
            <Notifications />
          </div>
          <div className="col-span-6 md:col-span-4 flex flex-col  justify-center items-center  bg-colorPrimary shadow-neumorphic py-5 shadow-lg rounded-lg">
            {" "}
            <WalletDetails setIsModalOpen={setIsModalOpen} profile={profile} isModalOpen={isModalOpen} onFinish={onFinish} />
          </div>
        </div>
      </div>
      <Modal title="Update user Wallet Adderss" open={isModalOpen} footer={false} onCancel={handleCancel}>
        <div>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              className=" mt-6"
              label="Wallet address"
              name="address"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: 'Please input your Wallet address!',
                },
              ]}
            >
              <Input placeholder="Enter Wallet address" />
            </Form.Item>

            <div className=" flex justify-center">
              <button className=" active text-sm font-medium py-1 px-4 rounded-xl">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default Profile;
