import { Empty, Table } from "antd";
import moment from "moment";
import React from "react";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";

function MatchingTableData(props) {
  const { matchingData, pageSize, page, totalPages, setPage, setpageSize } =
    props.data;

  const columns = [
    {
      title: "DATE",
      dataIndex: "Datetime",
      key: "Datetime",
    },

    {
      title: "TRANS ID",
      dataIndex: "TransID",
      key: "TransID",
    },
    {
      title: "LEVEL",
      dataIndex: "Level",
      key: "Level",
    },
    {
      title: "FROM",
      dataIndex: "From",
      key: "From",
    },
    {
      title: "TOTAL EARNING",
      dataIndex: "Total_Earning",
      key: "Total_Earning",
    },
    {
      title: "DISTRIBUTION (%)",
      dataIndex: "Distribution",
      key: "Distribution",
    },
    {
      title: "AMOUNT",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      render: (text) => (
        <span style={{ color: text === "Success" ? "green" : "red" }}>
          {text}
        </span>
      ),
    },
  ];

  const rows = [];

  if (matchingData) {
    for (let i = 0; i < matchingData.length; i++) {
      const dateParts = matchingData[i]?.Datetime?.split(" ");
      if (dateParts && dateParts.length === 2) {
        const matrixName = matchingData[i]?.Matrix_Name || '';

        rows.push({
          key: i,
          Datetime: matchingData[i]?.Datetime,
          TransID: matchingData[i]?.TransID,
          Amount: "$" + (matchingData[i]?.Amount),
          Level: matchingData[i]?.Level,
          Total_Earning: "$ " + matchingData[i]?.Total_Earning,
          From: matchingData[i]?.From,
          Matrix_Name: matrixName.replace("BOT", "BOT "),
          Distribution: matchingData[i]?.Distribution + " %",
          Transaction: `${matchingData[i]?.Transaction}`,
          Status: matchingData[i]?.Status ? "Success" : "Failed",
        });
      }
    }
  }
  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setpageSize(newPageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => { };

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  if (isMobile) {
    return (
      <>
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />

        {rows.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full">
            {rows.map((record) => (
              <div
                key={record.TransID}
                className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
              >
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Date Time:</div>
                  <div className="card-data">{record.Datetime}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">TransID:</div>
                  <div className="card-data">{record.TransID}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Level:</div>
                  <div className="card-data">{record.Level}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">From:</div>
                  <div className="card-data">{record && record.From ? record.From.slice(0, 15) + '...' + record.From.slice(-8) : ''}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Total Earning:</div>
                  <div className="card-data">{record.Total_Earning}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Distribution (%):</div>
                  <div className="card-data">{record.Distribution}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Amount:</div>
                  <div className="card-data">{record.Amount}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Status:</div>
                  <div className="card-data">
                    <span
                      style={{
                        color: parseFloat(record.Status) > 100 ? "red" : "green",
                      }}
                    >
                      {record.Status}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
            <Empty />
          </div>
        )}


        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </>
    );
  }



  return (
    <div>
      <div className=" w-full bg-colorPrimary ">
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <Table
          className="table-newres my-2"
          columns={columns}
          dataSource={rows}
          onChange={onChange}
          pagination={false}
        
        />
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
}

export default MatchingTableData;
