import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
// import logo from "../assets/images/logo.png";
// import logo1 from "../assets/logo-beta.png";
import one from "../assets/images/loginicone/one.png";
import two from "../assets/images/loginicone/two.png";
import three from "../assets/images/loginicone/three.png";
import { theme } from "antd";
import Lottie from "lottie-react";
import ant from "../Login.json";
import { BiSolidBookBookmark } from "react-icons/bi";
import { GoBook } from "react-icons/go";
import { AiOutlineRead } from "react-icons/ai";
import NeoDesign from "./NeoDesign";

function AuthWrapper() {
  const [hamburgerview, sethamburgerview] = useState("hidden");
  const handlehamburger = () => {
    sethamburgerview("block");
  };

  const navigate = useNavigate();


  const [isStopped, setIsStopped] = useState(true);

  const toggleAnimation = () => {
    setIsStopped(!isStopped);
  };
  const isMdScreen = window.matchMedia("(min-width: 768px)").matches; // Check if the screen size is medium or larger (you might need to adjust the media query accordingly)
  return (
    <div className="">
      <div className="mx-w-[1540px] relative  md:bg-colorPrimaryDark ">
      {isMdScreen ? null : <div className="active md:hidden">
        <div className="flex justify-center items-center h-28 ">
          <div className="text-white text-3xl">Odecent</div>
        </div>
      </div>}

        <div className="  flex items-center justify-center md:ml-5 overflow-hidden">
          <div className=" w-8/12 justify-start md:flex md:flex-col text-justify md:items-start hidden">
            <NeoDesign />
          </div>

          <div className="md:w-4/12  w-full rounded-t-full bg-colorPrimaryDark flex flex-col items-center justify-center">

            <div className=" w-full px-4 flex justify-center items-center">
              <div className="flex md:justify-between justify-center items-center w-full"></div>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthWrapper;
