import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosLock } from "react-icons/io";
import { HandleSession } from "./HandleSession";

function MatrixCard() {
  const [matrix, setMatrix] = useState([]);
  const [qualiMatrix, setQualiMatrix] = useState({});
  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const matrixResponse = await axios.post(
          process.env.REACT_APP_API_PUBLIC + "matrix",
          { project: process.env.REACT_APP_PROJECT },
          { headers: { "Content-Type": "application/json" } }
        );

        const qualiMatrixResponse = await axios.post(
          process.env.REACT_APP_API_Matrix + "all_matrix_qualified",
          { jwt },
          { headers: { "Content-Type": "application/json" } }
        );

        setMatrix(matrixResponse.data.Success);
        setQualiMatrix(qualiMatrixResponse.data.Success);
      } catch (error) {
        HandleSession(error);
      }
    };

    fetchData();
  }, [jwt]);

  return (
    <>
      <div className='text-center font-semibold text-black py-4 text-2xl'>Your Current Achievement</div>
      <div className="container gap-3">
        <div className="mx-2 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 md:gap-x-9 gap-y-4 mt-0">
          {matrix.map((item) => (
            <div key={item.ID} className="matrix-card h-full">
              <div className="card-inner">
                <div className="front">
                  <img
                    src={`/images/Full & Final/Matrix Bonus/${item.Image.replace(
                      "png",
                      "webp"
                    )}`}
                    alt={`package-${item.ID}`}
                    className="mx-auto h-full"
                  />
                </div>
                <div className="back">
                  <img
                    src={`/images/Full & Final/MatrixBack/${item.Image.replace(
                      "png",
                      "webp"
                    )}`}
                    alt={`package-${item.ID}`}
                    className="mx-auto  h-full"
                  />
                </div>
                {qualiMatrix && qualiMatrix[item.Matrix_Name] === false && (
                  <div className="absolute inset-0 text-white flex items-center bg-blur backdrop-blur-sm bg-white/20 justify-center">
                    <IoIosLock size={50} />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default MatrixCard;
