import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BiSupport } from "react-icons/bi";
// import SideBar from "./Sidebar";
import SideBar from "./SideBar";
import axios from "axios";
import { toast } from "react-toastify";

const Dashboard = ({ selectedImage }) => {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const [profile, setProfile] = useState();

  useEffect(() => {
    if (open == true) {
      setOpen(false);
    }
  }, []);
  
  const [openSide, setOpenSide] = useState(true);


   const pathToHideIcon = "/dashboard/support1"; 
    const pathsToApplyBottom15 = [
      "/dashboard/api_settings1",
      "/dashboard/support1",
      
    ];

    const View_Profile = async () => {
      const jwt = localStorage.getItem("jwt");
      const postdata = {
        project: process.env.REACT_APP_PROJECT,
        jwt: jwt,
      };
  
      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
        data: postdata,
      })
        .then(async (res) => {
          const response = await res;
          if (response) {
            setProfile(response?.data?.Success);
          } else {
            toast.error(response?.data?.Error);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Error);
        });
    };
  
  
    useEffect(() => {
      View_Profile();
    }, []);

    console.log(profile, "profile");

  return (
    <div>
      <div className=" lg:h-screen  bg-colorPrimary">
      <Navbar
            openSide={openSide}
            setOpenSide={setOpenSide}
            selectedImage={selectedImage}
            className="z-50"
          />
        <div
          className={`  h-auto max-h-screen ${
            openSide
              ? "xl:ml-56 md:ml-56 md:mr-3  "
              : "md:pl-16 md:pr-4 lg:ml-0  w-full"
          } `}
        >
          {/* <div
            className={` ${
              pathsToApplyBottom15.includes(window.location.pathname)
                ? ""
                : "mb-[1%]"
            }`}
          > */}
          <SideBar openSide={openSide} setopenSide={setOpenSide} />
          {/* <Navbar
            openSide={openSide}
            setOpenSide={setOpenSide}
            selectedImage={selectedImage}
            className="z-50 mb-5 "
          /> */}
          {/* <div className="mt-20 ">
            <Outlet
              className={`bottom-2  h-full${
                pathsToApplyBottom15.includes(window.location.pathname)
                  ? ""
                  : "mb-[1%] mt-[5%]"
              }`}
            />
          </div> */}

          <div
            className={` mt-5 ${
              pathsToApplyBottom15.includes(window.location.pathname)
                ? ""
                : "mb-[3%]"
            }`}
          >
            {/* <Sidebar openSide={openSide} setOpenSide={setOpenSide} /> */}
            <Outlet
            View_Profile={View_Profile}
            profile={profile}
              className={`bottom-12 ${
                pathsToApplyBottom15.includes(window.location.pathname)
                  ? ""
                  : "mb-[5%]"
              }`}
            />
          </div>
          {/* </div> */}

          <div className="md:block hidden">
            <Link
              to="/dashboard/support"
              className={`bottom-10 right-0 z-40 fixed p-2 rounded-full shadow-neumorphic bg-neonPurple mx-2 ${
                location.pathname === pathToHideIcon ? "hidden" : ""
              } `}
            >
              <BiSupport size={25} className="text-white" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
