import { Alert, Button, Drawer, Modal, Steps } from "antd";
import React, { useState, useCallback } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { AiOutlineBlock } from "react-icons/ai";
import ButtonApi from "../Components/ButtonApi";
import { useEffect } from "react";
import ConnectContext from "./ConnectContext";

const Step = ({ title, completed, index, isChecked, onClick }) => {
  const stepClassName = completed ? "current bg-[#000]" : "bg-blue-300";

  return (
    <div className={`step ${stepClassName}`}>
      <span className={`step-number ${completed ? "checkmark" : ""}`}>
        {completed ? "✔" : index}
      </span>
      <span>{title}</span>
      {title === "Choose Exchange" && isChecked && (
        <span className="checkbox">✔</span>
      )}
    </div>
  );
};

const Api_Setting = () => {
  const location = useLocation();
  // const locationState = location.state || false;
  console.log(location, "location")
  const navigate = useNavigate();
  const { param } = useParams();
  

  const [stepsData, setStepsData] = useState([
    { title: "Choose Exchange", completed: false },
    { title: "Setup Keys", completed: false },
    { title: "Connecting..", completed: false },
  ]);
  const [locationState, setLocationState] = useState(location.state || false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    console.log(param, "param")
    if (location.pathname === "/dashboard/api_settings/Paperfutureconnectiondetails" || location.pathname === "/dashboard/api_settings/Spotconnectiondetails") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: false },

        { ...prevStepsData[2], completed: false },
      ]);
    } 
    else if (location.pathname === "/dashboard/api_settings/Paperfutureconnectiondetails" || parentState === "Clicked") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: true },
        { ...prevStepsData[2], completed: true },
      ]);
    } 
    else if (location.pathname === "/dashboard/api_settings/ConnectionLoder") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: true },
        { ...prevStepsData[2], completed: false },

      ]);
    }
    else if (location.pathname === "/dashboard/api_settings/ConnectionLoder" && parentState === "Clicked" ) {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: true },
        { ...prevStepsData[2], completed: true },

      ]);
    }
   else if (location.pathname === "/dashboard/api_settings") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: false },
        { ...prevStepsData[1], completed: false },
        { ...prevStepsData[2], completed: false },
      ]);
    }
  }, [location, locationState, location.pathname]);
  const [parentState, setParentState] = useState('Initial Value');

  const updateParentState = useCallback((newValue) => {
    setParentState(newValue);
    console.log('Parent state updated:', newValue);
    if (location.pathname === "/dashboard/api_settings/Paperfutureconnectiondetails" && newValue === "Clicked") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: true },
        { ...prevStepsData[2], completed: true },
      ]);
    } 
    if (location.pathname === "/dashboard/api_settings/ConnectionLoder" && newValue === "Clicked" ) {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: true },
        { ...prevStepsData[2], completed: true },

      ]);
    }
    
  }, []);
  return (
    <>
      <div className="">
        <div className="container mx-auto ">
         
          <div className="wrapper ">
            <div className="arrow-steps flex mt-5 md:mt-0   flex-wrap justify-center">
              {stepsData.map((step, index) => (
                <Step
                  key={index}
                  title={step.title}
                  completed={step.completed}
                  index={index + 1}
                  className="shadow-md shadow-black "
                  // isChecked={locationState}
                />
              ))}
            </div>
            <div className="fixed bottom-7 right-2 xl:hidden block z-40">
              <button
                type="primary"
                onClick={showModal}
                className=" rounded-full py-1 shadow-neumorphic active"
              >
                <AiOutlineBlock size={25} className="" />
              </button>
              <Modal
                title="Status"
                visible={isModalVisible}
                onOk={handleOk}
                footer={false}
                onCancel={handleCancel}
              >
                <ButtonApi />
              </Modal>
            </div>
          </div>
          <ConnectContext.Provider value={{ parentState, updateParentState }}>
            <Outlet />
          </ConnectContext.Provider>
        </div>
      </div>
    </>
  );
};

export default Api_Setting;
