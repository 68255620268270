import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Test from "../Layout/Test";

function LevelChart() {
  const [level, setLevel] = useState([]);
  const [profile, setProfile] = useState([]);
  const [imagesview, setImagesView] = useState("");

   const View_Profile = async () => {
     const jwt = localStorage.getItem("jwt");
     const postdata = {
       project: process.env.REACT_APP_PROJECT,
       jwt: jwt,
     };

     const response = await axios({
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       url: process.env.REACT_APP_API_PATH_USER + "view_profile",
       data: postdata,
     })
       .then(async (res) => {
         const response = await res?.data;
         if (response) {
           setProfile(response?.Success);
         } else {
           toast.error(response?.data?.Error);
         }
       })
       .catch((err) => {
         toast.error(err?.response?.data?.Error);
       });
   };

  const View_Level = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_Level + "view_downline",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res.data;
        if (response) {
          setLevel(response?.Success);
          console.log(response?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

   const View_ActiveLevel = async (name) => {
     const jwt = localStorage.getItem("jwt");
     const postdata = {
       jwt: jwt,
       username: name,
     };

     const response = await axios({
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       url: process.env.REACT_APP_API_Level + "view_downlines_downline",
       data: postdata,
     })
       .then(async (res) => {
         const response = await res.data.Success;
         if (response) {
        //    setLevel(res?.Success);
         } else {
           toast.error(response?.data?.Error);
         }
       })
       .catch((err) => {
         toast.error(err?.response?.data?.Error);
       });
   };

    const View_imgLevel = async (name) => {
      const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      username: name,
    };

      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_Level + "view_downline_profile_image",
        data: postdata,
      })
        .then(async (res) => {
          const response = await res.data.Success;
          if (response) {
               setImagesView(res?.Success);
          } else {
            toast.error(response?.data?.Error);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Error);
        });
    };

   
    useEffect(() => {
      const fetchData = async () => {
        await View_imgLevel();
      };
      fetchData();
    }, []);


  useEffect(() => {
    View_Level();
    View_Profile();
  }, []);


  return (
    <>
      <div>
        <Test
          datachart={level}
          profilelevel1={profile}
          View_ActiveLevel={View_ActiveLevel}
          imagesview={imagesview}
          View_imgLevel={View_imgLevel}
        />
      </div>
    </>
  );
}

export default LevelChart;
