import React, {  useEffect, useState } from "react";
import {Table, Button, Switch, Modal, Popconfirm } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { DeleteOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { MdDeleteForever } from "react-icons/md";
import image1 from "../assets/Fingenius/BTC.png";
import image2 from "../assets/Fingenius/BNB.png";
import image3 from "../assets/Fingenius/ETH.png";
import image4 from "../assets/Fingenius/USDT.png";

export const Activebotnew = () => {
  const jwt = localStorage.getItem("jwt");
  const [apilistdata, setapilistdata] = useState([]);
  const [dataSource, setDataSource] = useState([]);

 const [deleteRecord, setDeleteRecord] = useState(null);
 const [isModalVisible, setIsModalVisible] = useState(false);

 // ... (existing code)

 const showDeleteConfirmation = (record) => {
   setDeleteRecord(record);
   setIsModalVisible(true);
 };

 const handleOk = () => {
   setIsModalVisible(false);
   if (deleteRecord) {
     handleDelete(deleteRecord);
   }
 };

 const handleCancel = () => {
   setIsModalVisible(false);
   setDeleteRecord(null);
 };


  const expandedRowRender = (record) => {
    const renderNestedTable = (data) => {
      const columns = [
        {
          title: "Asset",
          dataIndex: "asset",
          key: "asset",
        },
        ...Object.keys(data).map((key) => ({
          title: key,
          dataIndex: key,
          key: key,
        })),
      ];
  
      const dataSource = [
        {
          key: "data",
          asset: "Data",
          ...data,
        },
      ];
  
      return <Table size="medium" columns={columns} dataSource={dataSource} pagination={false} />;
    };
  
    const columns = [
      {
        title: "Asset",
        dataIndex: "asset",
        key: "asset",
      },
      ...Object.keys(record.Initial).map((asset) => ({
        title: asset,
        dataIndex: asset,
        key: asset,
      })),
    ];
  
    const dataSource = [
      {
        key: "Initial",
        asset: "Initial",
        ...record.Initial,
      },
      {
        key: "Invested",
        asset: "Invested",
        ...record.Invested,
      },
      {
        key: "Balance",
        asset: "Balance",
        ...record.Balance,
      },
    ];
    return (
      <Table
        size="medium"
        columns={columns}
        className="newtable"
        dataSource={dataSource}
        pagination={false}
      />
    );
  };
  const defaultColumns = [
    {
      title: "API ID",
      dataIndex: "APIID",
      key: "APIID",
      editable: true,
      width: "20%",
    },
    {
      title: "Exchange",
      dataIndex: "Exchange",
      key: "Exchange",
      editable: true,
      width: "20%",
    },
    // {
    //   title: "Type",
    //   dataIndex: "Type",
    //   key: "Type",
    //   width: "10%",
    //   editable: true,
    // },
    {
      title: "Exchange Type",
      dataIndex: "Exchange_Type",
      key: "Exchange_Type",
      editable: true,
      width: "20%",
    },
    {
      title: "API Name",
      dataIndex: "API_Name",
      key: "API_Name",
      editable: true,
      width: "20%",
    },

    {
      title: "API Key",
      dataIndex: "Key",
      key: "Key",
      editable: true,
      width: "15%",
      render: (text) => (
        <span>
          {text.length > 10
            ? `${text.slice(0, 4)}......${text.slice(-6)}`
            : text}
        </span>
      ),
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: "10%",
      editable: true,
      className: "w-7",

      render: (text, record) => (
        <>
          <div className="justify-center items-center ">
            <div>
              <Switch
                checkedChildren="START"
                unCheckedChildren="PAUSE"
                className={record.Status === "ACTIVE" ? "off" : "on"}
                checked={record.Status === "ACTIVE"}
                // checked={record.Status === "ACTIVE" ? true : false}
                onChange={(checked) => onswitchChange(record, checked)}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Action",
      width: "10%",
      key: "action",
      render: (_, record) => (
        <>
          <button
            className="border-none mb-0 py-1"
            onClick={() => handleDelete(record)}
          >
            <DeleteOutlined size={18} />
          </button>
        </>
      ),
    },
  ];


  const handleDelete = (record) => {
    let postData = {
      jwt: jwt,
      api_name: record.API_Name,
      project: process.env.REACT_APP_PROJECT,
    };
    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_API + "delete_api",
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data;
        if (res.Success) {
          //   getallrole();
          // getapiDetails();
          view_totp()
          toast(res.Success);
        } else if (res.Error) {
         toast.error(res.Error);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.Error);
        // getapiDetails();
      });
  };


  const onswitchChange = (record, checked) => {
    const newStatus = checked ? "ACTIVE" : "INACTIVE";
    const updatedDataSource = dataSource.map((item) =>
      item.API_Name === record.API_Name ? { ...item, Status: newStatus } : item
    );
    setDataSource(updatedDataSource);
    const postData = {
      jwt: jwt,
      api_name: record.API_Name,
      status: newStatus,
      project: process.env.REACT_APP_PROJECT,
    };

    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_API + "change_api_status",
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data;
        if (res?.Success) {
          toast(res?.Success);
          handleSave();
        } else if (res.Error) {
          toast.error(res.Error);
        }
      })
      .catch((err) => {
    
        toast.error(err.response.data.Error);
      });
  };

  const allColumns = [...defaultColumns];

  const columns = allColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        width: 10 ,
        handleSave,
      }),
    };
  });

  const generateUniqueKeys = (data) => {
    return data.map((item, index) => `${item.APIID}_${index}`);
  };


  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const view_totp = async () => {
    const postData = { jwt: jwt, project: process.env.REACT_APP_PROJECT }
    axios({
      method: 'POST',
      headers: { 'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' },
      url: process.env.REACT_APP_API_PATH_API + 'api_list',
      data: postData,
    }).then(async function (res) {
      const response = res?.data
      // const updatedData = response.Success?.map((item) => ({
      //   ...item,
      //   isActive: item.Status === "ACTIVE",
      // }));
      const updatedData = response.Success?.map((item, index) => ({
        ...item,
        isActive: item.Status === "ACTIVE",
        key: `${item.APIID}_${index}`, // Generate a unique key for each record
      }));
      setapilistdata(updatedData);
      setDataSource(updatedData);
    }).catch((err) => {
    });
  }

  useEffect(() => {
    view_totp()
  }, []);

  const generateImageUrl = (symbol) => {
    switch (symbol) {
      case "BTC":
        return image1;
      case "BNB":
        return image2;
      case "ETH":
        return image3;
      case "USDT":
        return image4;
      default:
        return "";
    }
  };

    const isMobile = useMediaQuery({ maxWidth: 1050 });



    if (isMobile) {
      return (
        <>
          <div className=" grid grid-cols-1 mt-5 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full">
            {dataSource.map((record) => (
              <div
                key={record.TransID}
                className="card-container mx-2  lg:my-2 my-3 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
              >
                <div className="card-content mt-1 flex justify-between my-2 items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Status:</div>
                  <div className="card-data">
                    {" "}
                    <Switch
                      checkedChildren="START"
                      unCheckedChildren="PAUSE"
                      className={record.Status === "ACTIVE" ? "off" : "on"}
                      checked={record.Status === "ACTIVE"}
                      // checked={record.Status === "ACTIVE" ? true : false}
                      onChange={(checked) => onswitchChange(record, checked)}
                    />
                  </div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">APIID:</div>
                  <div className="card-data">{record.APIID}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">Exchange:</div>
                  <div className="card-data">{record.Exchange}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Exchange Type:</div>
                  <div className="card-data">
                    <div className="card-data">{record.Exchange_Type}</div>
                  </div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">API Name:</div>
                  <div className="card-data">{record.API_Name}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">API Key:</div>
                  <div className="card-data">
                    {`${record.Key.slice(0, 4)}...${record.Key.slice(-4)}`}{" "}
                  </div>
                </div>

                <div className="grid grid-col-3 gap-2 my-2">
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="card-title font-semibold text-sm   md:text-base">
                        Symbol
                      </div>
                      <div className="card-title font-semibold">
                        <img
                          src={generateImageUrl("BNB")}
                          alt="BNB"
                          className="currency-image w-6 "
                        />
                      </div>
                      <div className="card-title font-semibold">
                        <img
                          src={generateImageUrl("BTC")}
                          alt="BTC"
                          className="currency-image w-6 "
                        />
                      </div>
                      <div className="card-title font-semibold">
                        <img
                          src={generateImageUrl("ETH")}
                          alt="ETH"
                          className="currency-image w-6 "
                        />
                      </div>
                      <div className="card-title font-semibold">
                        <img
                          src={generateImageUrl("USDT")}
                          alt="USDT"
                          className="currency-image w-6 "
                        />
                      </div>
                    </div>
                    <div>
                      <div className="card-title font-semibold text-sm   md:text-base">
                        Initial
                      </div>
                      <div className="card-data my-1 text-sm   md:text-base">
                        {record?.Initial?.BNB?.toFixed(4) || 0}
                      </div>
                      <div className="card-data text-sm   md:text-base">
                        {record?.Initial?.BTC?.toFixed(4) || 0}
                      </div>
                      <div className="card-data text-sm   md:text-base">
                        {record?.Initial?.ETH?.toFixed(4) || 0}
                      </div>
                      <div className="card-data my-1 text-sm   md:text-base">
                        {record?.Initial?.USDT?.toFixed(4) || 0}
                      </div>
                    </div>
                    <div>
                      <div className="card-title font-semibold text-sm   md:text-base">
                        Balance
                      </div>
                      <div className="card-data my-1 text-sm   md:text-base">
                        {record?.Balance?.BNB?.toFixed(4) || 0}
                      </div>
                      <div className="card-data text-sm   md:text-base">
                        {record?.Balance?.BTC?.toFixed(4) || 0}
                      </div>
                      <div className="card-data text-sm   md:text-base">
                        {record?.Balance?.ETH?.toFixed(4) || 0}
                      </div>
                      <div className="card-data my-1 text-sm   md:text-base">
                        {record?.Balance?.USDT?.toFixed(4) || 0}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="card-content flex justify-center items-center md:text-xs lg:text-md text-sm">
                  <div className="card-data">
                    <button
                      className="border-none mb-0 py-1"
                      onClick={() => handleDelete(record)}
                    >
                      <MdDeleteForever
                        size={30}
                        className="text-colorSecondary"
                      />
                    </button>
                  </div>
                </div> */}

                <div className="card-content flex justify-center items-center md:text-xs lg:text-md text-sm">
                  <div className="card-data">
                    <Popconfirm
                      title="Delete the API"
                      description="Are you sure to delete this API?"
                      okText={<p className="bg-colorSecondary px-2 rounded-lg ">Yes</p>}
                      cancelText="No"
                      onConfirm={() => handleDelete(record)}
                    >
                      <Button danger className="border-none">
                        {/* <button
                          className="border-none mb-0 py-1"
                          onClick={() => showDeleteConfirmation(record)}
                        > */}
                        <MdDeleteForever
                          size={30}
                          className="text-colorSecondary"
                        />
                        {/* </button> */}
                      </Button>
                    </Popconfirm>
                  </div>
                </div>

                {/* Delete Confirmation Modal */}
              </div>
            ))}
          </div>
        </>
      );
    }

  return (
    <>
      <div className="">
        <div className="w-full mt-5 ">
          <Table
            size="small"
            className="w-full py-0 table-newres  !h-auto"
            columns={columns}
            expandable={{
              expandedRowRender,
              defaultExpandedRowKeys: generateUniqueKeys(dataSource),
            }}
            dataSource={dataSource}
            pagination={true}
            // scroll={{
            //   x: 1500,
            //   y: 500,
            // }}
          />
        </div>
      </div>
    </>
  );
};
