import React, { useState } from 'react'
import { FaCircleExclamation } from "react-icons/fa6";
import { Form, Input, Select, message } from 'antd';
import SwipeableViews from "react-swipeable-views";
import { GiWorld } from "react-icons/gi";
import { ImProfile } from "react-icons/im";
import { FaCarAlt } from "react-icons/fa";
import Passport from '../Components/Passport';
import NationalCard from '../Components/NationalCard';
import DriverLicense from '../Components/DriverLicense';
import { Checkbox } from 'antd';
import axios from 'axios';
const { Option } = Select;

const KycNew = () => {
    const [index, setIndex] = useState(0);
    const [formData, setFormData] = useState()

    console.log('checkFromData0',)

    console.log("formDatamain", formData)

    const handleChangeIndex = (newIndex) => {
        setIndex(newIndex);
    };

    const Tab = [
        {
            name: "passport",
            icon: <GiWorld size={30} className=' text-gray-500' />
        },
        {
            name: 'National Card',
            icon: <ImProfile size={30} className=' text-gray-500' />
        },
        {
            name: "Driver's License",
            icon: <FaCarAlt size={30} className=' text-gray-500' />
        }
    ]

    const formComponent = [
        { component: <Passport data={{ formData, setFormData }} /> },
        { component: <NationalCard data={{ formData, setFormData }} /> },
        { component: <DriverLicense data={{ formData, setFormData }} /> },
    ]

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            +91
        </Form.Item>
    );

    const onFinish = (values) => {
        console.log('Successsss:', values);

        const jwt = localStorage.getItem('jwt')

        const postData = {
            ...formData,
            ...values,
            jwt: jwt,
            prefix: values?.prefix == undefined ? "" : values?.prefix,
            address2: values?.address2 == undefined ? "" : values?.address2,
            gender: values?.gender.toUpperCase(),
            tnc: true
        }

        console.log("postData", postData)

        if (!formData) {
            message.error('Complete Identity Verification')
        } else {
            const response = axios({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                url: process.env.REACT_APP_API_USER + 'kyc_verification',
                data: postData
            }).then(res => {
                message.success(res?.data?.Success)
            })
                .catch(err => {
                    console.log("kycresposne", err)
                    message.error(err?.response?.data?.Error)
                })
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };


    return (
        <div className=' container'>
            <div className='  bg-colorPrimary shadow-neumorphic1 border-gradient rounded-2xl'>
                <div className='p-5 md:p-10'>
                    <Form
                        name="trigger"
                        layout="vertical"
                        autoComplete="off"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div>
                            <p className=' text-xl font-medium'>Step 1 : Personal Details</p>
                            <p className=' text-sm'>Simple personal information, required for identification</p>
                            <p className=' text-xs text-green-500 flex items-center gap-1 mt-2'><FaCircleExclamation className=' text-green-500' size={16} />Please carefully fill out the form with your personal details. You can’t edit these details once you submitted the form.</p>
                            <div className=' grid grid-cols-1 md:grid-cols-2 gap-10 mt-10'>
                                <div>
                                    <Form.Item
                                        hasFeedback
                                        label="First Name"
                                        name="first_name"
                                        validateTrigger="onBlur"
                                        rules={[
                                            {
                                                // pattern: /^[A-Za-z]+$/,
                                                pattern: /^[A-Za-z\s]+$/,
                                                message: 'First name should only contain letters',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your First Name',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='First Name' className=' py-2' />
                                    </Form.Item>

                                    <Form.Item
                                        hasFeedback
                                        label="Email Address"
                                        name="email"
                                        validateDebounce={1000}
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Email Address' className=' py-2' />
                                    </Form.Item>

                                    <Form.Item
                                        hasFeedback
                                        label="Date of Birth (yyyy-mm-dd)"
                                        name="dob"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Date of Birth',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Date of Birth (yyyy-mm-dd)' className=' py-2' />
                                    </Form.Item>

                                    <Form.Item
                                        hasFeedback
                                        label="Address Line 1"
                                        name="address1"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Address Line 1',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Address Line 1' className=' py-2' />
                                    </Form.Item>


                                    <Form.Item
                                        hasFeedback
                                        label="Gender"
                                        name="gender"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your gender',
                                            },
                                        ]}
                                    >
                                        {/* <Input placeholder='Gender' className=' py-2' /> */}
                                        <Select
                                            defaultValue="Male"
                                            onChange={handleChange}
                                            options={[
                                                {
                                                    value: 'Male',
                                                    label: 'Male',
                                                },
                                                {
                                                    value: 'FEMALE',
                                                    label: 'FEMALE',
                                                },
                                                {
                                                    value: 'TRANS',
                                                    label: "Don't want to disclose",
                                                },
                                            ]}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        hasFeedback
                                        label="City of Residence"
                                        name="city"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your City of Residence',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='City of Residence' className=' py-2' />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        hasFeedback
                                        label="Last Name"
                                        name="last_name"
                                        validateTrigger="onBlur"
                                        rules={[
                                            {
                                                pattern: /^[A-Za-z\s]+$/,
                                                message: 'Last name should only contain letters',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your Last Name',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Last Name' className=' py-2' />
                                    </Form.Item>

                                    <Form.Item
                                        hasFeedback
                                        label="Nationality"
                                        name="country"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Nationality',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Nationality' className=' py-2' />
                                    </Form.Item>


                                    <Form.Item
                                        hasFeedback
                                        label="Phone Number"
                                        name="mobile"
                                        validateDebounce={1000}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your phone number!',
                                            },
                                            {
                                                pattern: /^[0-9]+$/,
                                                message: 'The input is not valid Phone number!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            addonBefore={prefixSelector}
                                            placeholder='Phone Number' />
                                    </Form.Item>

                                   
                                    <Form.Item
                                        hasFeedback
                                        label="Address Line 2 (optional)"
                                        name="address2"
                                        validateFirst
                                    >
                                        <Input placeholder='Address Line 2 (optional)' className=' py-2' />
                                    </Form.Item>

                                    <Form.Item
                                        hasFeedback
                                        label="Zip Code"
                                        name="zip_code"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Zip Code',
                                            },
                                            {
                                                pattern: /^[0-9]+$/,
                                                message: 'The input is not valid Phone umber!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Zip Code' className=' py-2 text-sm' />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className='mt-5'>
                            <p className='text-xl font-medium'>Step 2 : Identity Verify</p>
                            <p className='text-sm'>Upload documents to verify your identity.</p>
                            <p className='text-xs text-green-500 flex items-center gap-1 mt-2'><FaCircleExclamation className=' text-green-500' size={16} />Please upload any one of the following personal document (image or pdf file only).</p>

                            <div className=''>
                                <div>
                                    <div className='flex mt-4'>
                                        {
                                            Tab.map((item, Count) => {
                                                return (
                                                    <>
                                                        <div className=' w-1/3 ' onClick={() => { setIndex(Count) }}>
                                                            <div className={`flex items-center gap-40 py-5 px-5 rounded-t-xl ${index == Count ? " border-2 border-colorDark border-b-0 bg-cyan-200" : " border-b-2  border-colorDark"}`}>
                                                                <div className='font-medium'>{item?.icon}</div>
                                                                <div className='uppercase'>{item?.name}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div>
                                    <SwipeableViews
                                        index={index}
                                        onChangeIndex={handleChangeIndex}
                                        className="mx-0 md:mx-4"  >
                                        {
                                            formComponent.map(item => {
                                                return (
                                                    <div>
                                                        {item?.component}
                                                    </div>
                                                )
                                            })
                                        }
                                    </SwipeableViews>
                                </div>
                            </div>

                            <hr className=' border-colorDark border-[1px] mt-4' />

                            <Form.Item
                                name="agreement"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error('Should accept teams and conditions')),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    I Confirm That I Have Read, Understood And Agreed To The Privacy Policy And Terms & Conditions
                                </Checkbox>
                            </Form.Item>
                            <div>
                                <button className=' bg-green-500 py-2 mt-2 px-6 font-medium text-sm text-white rounded-md'>Submit Details</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div >
        </div >
    )
}

export default KycNew
