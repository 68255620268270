import React from 'react'
import './App.css';
import Routerpage from './Routes/Routerpage';


const App = () => {

  return (
    <>
      <div className="App">
         <Routerpage />
      </div>
    </>
  );
}

export default App
