import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthWrapper from "../Layout/AuthWrapper";
import Signup from "../Pages/Signup";
import Login from "../Pages/Login";
import Forgetpassword from "../Pages/Forgetpassword";
import Forgetusername from "../Pages/Forgetusername";
import Api_Setting from "../Pages/Api_Setting";
import Preffred_exchanges from "../Components/Preffred_exchanges";
import SelectedExchangs from "../Components/SelectedExchange";
import Spotconnectiondetails from "../Pages/Spotconnectiondetails";
import ConnectionLoder from "../Pages/ConnectionLoder";
import APi_details from "../Pages/APi_details";
import ChangePassword from "../Components/ChangePAssword";
import ConfirmForgetpassword from "../Pages/ConfirmForgetpassword";
import EmailVerify from "../Pages/EmailVerify";
import ConfirmUsername from "../Pages/ConfirmUsername";
import Dashboard from "../Layout/Dashborad";
import Profile from "../Pages/Profile";
import Transactionnew from "../Components/Transactionnew";
import Logs from "../Pages/Logs";
import Support from "../Pages/Support";
import Trades from "../Pages/Trades";
import Reportcopy from "../Pages/Reportcopy";
import ProtectedRoute from "./ProtectRouter";
import PaymentGateway from "../Components/PaymentGateway";
import FAQ from "../Components/FAQ";
import SwitchinBot from "../Pages/SwitchinBot";
import Rank from "../Pages/Rank";
import Bonus from "../Pages/Bonus";
import SupportPage from "../Pages/SupportPage";
import Packages from "../Components/Packages";
import LevelChart from "../Pages/LevelChart";
import IncomeChart from "../Pages/IncomeChart";
import TreeWithNestedChildren from "../Components/TreeBlank";
import ActiveSwitch from "../Pages/ActiveSwitch";
import Paymentmodel from "../Pages/Paymentmodel";
import Wallet from "../Pages/Wallet";
import MatrixTwo from "../Pages/MatrixTwo"
import TransactionBonus from "../Components/TransactionBonus";
import PagenotFound from "../Pages/PagenotFound";
import MainDashboard from "../Pages/MainDashboard";
import KycNew from "../Pages/KycNew";
import MatrixTwocopy from "../Pages/MatrixTwocopy";


const Routerpage = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<AuthWrapper />}>
            <Route index element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/404" element={<PagenotFound />} />
            <Route path="/forgetpassword" element={<Forgetpassword />} />
            <Route path="/forgetusername" element={<Forgetusername />} />
            <Route path="/email_verify" element={<EmailVerify />} />
            <Route path="/submit" element={<ConfirmUsername />} />

            <Route
              path="/update_password"
              element={<ConfirmForgetpassword />}
            />
          </Route>
         
          <Route
            path="/dashboard"
            // element={<Dashboard />}
            element={<ProtectedRoute Component={Dashboard} />}
          >
           
            {/* <Route
              index
              // element={<Mainpage />}
              element={<ProtectedRoute Component={Mainpage} />}
            /> */}
             <Route path="/dashboard/test" element={<LevelChart />} />
            <Route
              path="/dashboard/mainpage"
              element={<ProtectedRoute Component={MainDashboard} />}
            />
            <Route
              exact
              path="/dashboard/profile"
              element={<ProtectedRoute Component={Profile} />}
            />
            <Route
              exact
              path="/dashboard/KycNew"
              // element={<ProtectedRoute Component={Kyc} />}
              element={<ProtectedRoute Component={KycNew} />}
            />
            <Route
              exact
              path="/dashboard/rank&rewards"
              element={<ProtectedRoute Component={Rank} />}
            />
            <Route
              exact
              path="/dashboard/trades"
              element={<ProtectedRoute Component={Trades} />}
            />
            <Route
              exact
              path="/dashboard/bonus"
              element={<ProtectedRoute Component={Bonus} />}
            />

            <Route
              exact
              path="/dashboard/botmain"
              element={<ProtectedRoute Component={SwitchinBot} />}
            // element={<ProtectedRoute Component={Botmainpagecopy} />}
            // element=<Botmainpagecopy />
            />

            <Route
              exact
              path="/dashboard/APi_details"
              element={<ProtectedRoute Component={APi_details} />}
            ></Route>
            <Route
              exact
              path="/dashboard/wallet"
              element={<ProtectedRoute Component={Wallet} />}
            />
            <Route
              exact
              path="/dashboard/transactionbonus"
              element={<ProtectedRoute Component={TransactionBonus} />}
            />
            <Route
              exact
              path="/dashboard/FAQ"
              element={<ProtectedRoute Component={FAQ} />}
            ></Route>
            <Route
              exact
              path="/dashboard/report"
              element={<ProtectedRoute Component={Reportcopy} />}
            />
            <Route
              exact
              path="/dashboard/transactiontable"
              element={<ProtectedRoute Component={Transactionnew} />}
            />
            <Route
              exact
              path="/dashboard/logs"
              element={<ProtectedRoute Component={Logs} />}
            />
            <Route
              exact
              path="/dashboard/support"
              element={<ProtectedRoute Component={Support} />}
            // element=<Support />
            />
            <Route
              exact
              path="/dashboard/matrixchart"
              element={<ProtectedRoute Component={TreeWithNestedChildren} />}
            // element=<Support />
            />
            <Route
              exact
              path="/dashboard/levelchart"
              element={<ProtectedRoute Component={MatrixTwocopy} />}
            // element=<Support />
            />
            <Route
              exact
              path="/dashboard/MatrixTwo"
              element={<ProtectedRoute Component={MatrixTwo} />}
            // element=<Support />
            />
            <Route
              exact
              path="/dashboard/incomechart"
              element={<ProtectedRoute Component={IncomeChart} />}
            // element=<Support />
            />
            <Route
              exact
              path="/dashboard/supportpage"
              element={<ProtectedRoute Component={SupportPage} />}
            // element=<Support />
            />
            <Route
              exact
              path="/dashboard/subscriptions"
              element={<ProtectedRoute Component={Packages} />}
            // element=<Subscirptions />
            />
            {/* <Route
              exact
              path="/dashboard/profile"
              element={<ProtectedRoute Component={Setting} />}
            // element=<Setting />
            /> */}
            <Route
              exact
              path="/dashboard/change_password"
              element={<ProtectedRoute Component={ChangePassword} />}
            // element=<ChangePassword />
            />
            <Route
              exact
              path="/dashboard/activebot"
              element={<ProtectedRoute Component={ActiveSwitch} />}
            // element=<ActiveBot />
            />
            <Route
              exact
              path="/dashboard/payment"
              element={<ProtectedRoute Component={PaymentGateway} />}
            // element=<ActiveBot />
            />

            {/* <Route
              exact
              path="/dashboard/Unstructured"
              element={<ProtectedRoute Component={UnstructuredTreeDemo} />}
            // element=<Fuel />
            /> */}
            <Route
              exact
              path="/dashboard/api_settings"
              element={<ProtectedRoute Component={Api_Setting} />}
            // element=<Api_Setting />
            >
              <Route
                index
                exact
                element={<ProtectedRoute Component={Preffred_exchanges} />}
              // element=<Preffred_exchanges />
              />
              <Route
                exact
                path="/dashboard/api_settings/SelectedExchange"
                element={<ProtectedRoute Component={SelectedExchangs} />}
              // element=<SelectedExchangs />
              />
              <Route
                exact
                path="/dashboard/api_settings/Spotconnectiondetails"
                element={<ProtectedRoute Component={Spotconnectiondetails} />}
              // element=<Spotconnectiondetails />
              />
              <Route
                exact
                path="/dashboard/api_settings/ConnectionLoder"
                element={<ProtectedRoute Component={ConnectionLoder} />}
              // element=<ConnectionLoder />
              />
            </Route>
            <Route
              exact
              path="/dashboard/deposit"
              element={<ProtectedRoute Component={Paymentmodel} />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default Routerpage;
