import React, { useEffect, useState } from 'react';
import Tree from "react-d3-tree";
import { useCenteredTree } from "../Components/NodeLabel";
import axios from "axios";
import { toast } from "react-toastify";
import { Spin, Tooltip } from 'antd';

const containerStyles = {
  width: "100vw",
  height: "100vh"
};

const MatrixTwocopy = () => {

  const [translate, containerRef] = useCenteredTree();
  const [downline, setDownline] = useState([]);
  const [clickedUsers, setClickedUsers] = useState([]);
  const [profile, setProfile] = useState([]);
  const [details, setdetails] = useState([]);
  const [imagesview, setImagesView] = useState("");
  const [secNested, setSecNested] = useState([])
  const [level, setLevel] = useState(1)
  const [loading, setLoading] = useState(false);
  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        await Promise.all([View_Level()]);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);


  const View_Profile = async () => {
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
      data: postdata,
    }).then(async (res) => {
      const response = await res?.data?.Success;
      setProfile(response)
    }).catch((err) => {
      toast.error(err?.response?.data?.Error);
    });
  };

  const View_Level = async (matrixName) => {
    setLoading(true);
    try {
      const postdata = {
        jwt: jwt,
        matrix_name: matrixName
      };

      const response = await axios.post(
        process.env.REACT_APP_API_Level + "view_downline",
        postdata,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const downlineData = response.data.Success;
      const mapRes = downlineData.map((item, index) =>
        ({ name: item, id: index + 2, pid: 1, title: `Level 1`, img: imagesview, level1: 1 }));
      setDownline(mapRes);
      await Promise.all(downlineData.map(async (name) => {
        await View_Details(name);
        const children = await View_ActiveLevel(name);
        const parentNode = clickedUsers[name];

        if (parentNode && !parentNode.children) {
          setClickedUsers((prevClickedUsers) => ({
            ...prevClickedUsers,
            [name]: {
              ...prevClickedUsers[name],
              children: children,
            },
          }));
        }

      }));
    } catch (err) {
      console.log("Error fetching matrix_downline data:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    View_Level()
    View_Profile()
  }, [])

  const View_ActiveLevel = async (name, id, level, matrixName) => {
    setSecNested([]);
    const postdata = {
      jwt: jwt,
      username: name,
    };
    const nextLevel = level + 1;

    try {
      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_Level + "view_downlines_downline",
        data: postdata,
      });

      const responseData = response.data.Success;
      const childrenNodes = responseData.map((item, index) => ({
        name: item,
        id: index + 1,
        pid: id,
        title: `Level ${nextLevel}`,
        img: imagesview,
        level1: nextLevel,
      }));

      if (!clickedUsers[name]?.details) {
        await View_Details(name, id);
      }

      const isExpanded = clickedUsers[name]?.isExpanded || false;

      setClickedUsers((prevClickedUsers) => ({
        ...prevClickedUsers,
        [name]: {
          ...prevClickedUsers[name],
          children: isExpanded ? childrenNodes : prevClickedUsers[name]?.children || [],
          isExpanded: isExpanded,
        },
      }));

      if (isExpanded && nextLevel <= 15) {
        childrenNodes.forEach(async (child) => {
          await View_ActiveLevel(child.name, child.id, nextLevel, matrixName);
          if (!clickedUsers[child.name]?.details) {
            await View_Details(child.name, child.id);
          }
        });
      }

      return childrenNodes;
    } catch (err) {
      console.log("Error fetching downlines_downline data:", err);
    }
  };
  const View_Details = async (name, id) => {
    setdetails([])
    const postdata = {
      jwt: jwt,
      username: name,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_Level + "view_downlines_details",
      data: postdata,
    }).then(async (response) => {
      const responseData = response.data.Success;

      if (responseData) {
        setClickedUsers((prevClickedUsers) => ({
          ...prevClickedUsers,
          [name]: {
            ...prevClickedUsers[name],
            details: responseData,
            hasData: true,
          },
        }));
        setdetails(responseData);
      }

    }).catch((err) => {
      console.log(err?.response?.data?.Error);
    });
  };

  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps
  }) => {
    const userDetails = clickedUsers[nodeDatum.name]?.details || {};

    const totalEarning =
      (userDetails.Total_Level_Earning ? userDetails.Total_Level_Earning : 0) +
      (userDetails.Total_Rank_Reward ? userDetails.Total_Rank_Reward : 0) +
      (userDetails.Total_Matching_Income ? userDetails.Total_Matching_Income : 0) +
      (userDetails.Total_Matrix_Earning ? userDetails.Total_Matrix_Earning : 0);

    if (nodeDatum.level === 1 && loading) {
      return (
        <g>
          <foreignObject {...foreignObjectProps}>
            <div className='bg-white border-gradient h-40 flex justify-center items-center'>
              <Spin size="large" />
            </div>
          </foreignObject>
        </g>
      );
    }

    if (nodeDatum.level >= 1) {
      return (
        <g>
          <foreignObject {...foreignObjectProps}>
            <Tooltip
              placement="right"
              title={
                <div>
                  <p>Total Packages: {userDetails.Total_Active_Subscription_Volume ? userDetails.Total_Active_Subscription_Volume : "0"}</p>
                  <p>Current Packages: {userDetails.Total_Active_Subscription ? userDetails.Total_Active_Subscription : "0"}</p>
                  <p>Total Earning: {totalEarning}</p>
                  <p>Total Team: {userDetails.Total_Team ? userDetails.Total_Team : "0"}</p>
                </div>
              }
            >
              <div className='bg-white border-gradient '>
                <div className="text-center ">
                  <div className='text-start mx-2'> Level {nodeDatum.level}</div>
                  <h3 className='text-xl'>{nodeDatum.name} </h3>
                  {userDetails && nodeDatum.level > 0 && (
                    <div>
                      <p>Rank: {userDetails.Rank}</p>
                      <p>Level: {userDetails.Level}</p>
                      <p>Total Direct: {userDetails.Total_Direct ? userDetails.Total_Direct : "0"}</p>
                    </div>
                  )}
                  {nodeDatum.children && (
                    <>
                     <button className='border border-black mt-2 rounded-b-lg' style={{ width: "100%" }} onClick={() => handleNodeToggle(nodeDatum, toggleNode)}>
                          {nodeDatum.isExpanded ? "Collapse" : "Expand"}
                        </button>
                      {/* {nodeDatum.children.length > 0 ? (
                        <button className='border border-black mt-2 rounded-b-lg' style={{ width: "100%" }} onClick={() => handleNodeToggle(nodeDatum, toggleNode)}>
                          {nodeDatum.isExpanded ? "Collapse" : "Expand"}
                        </button>
                      ) : (
                        <div className="text-center mt-2 text-gray-500">No Children</div>
                      )} */}
                      {nodeDatum.children.map((childNode, index) => (
                        <div key={index}>
                          {childNode.hasData && (
                            <>

                              {childNode.children && childNode.children.length > 0 ? (
                                <button className='border border-black bg-white' style={{ width: "100%" }} onClick={() => handleNodeToggle(childNode, toggleNode)}>
                                  {childNode.isExpanded ? "Collapse" : "Expand"}
                                </button>
                              ) : (
                                <div className="text-center mt-2 text-gray-500">No Downline</div>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </Tooltip>
          </foreignObject>
        </g>
      );
    }

    return (
      <g>
        <foreignObject {...foreignObjectProps}>
          <div className='bg-white border-gradient '>
            <div className="text-center ">
              <div className='text-start mx-2'> Level {nodeDatum.level}</div>
              <h3 className='text-xl'>{nodeDatum.name} </h3>
              {userDetails && nodeDatum.level > 0 && (
                <div>
                  <p>Rank: {userDetails.Rank}</p>
                  <p>Level: {userDetails.Level}</p>
                  <p>Total Direct: {userDetails.Total_Direct ? userDetails.Total_Direct : "0"}</p>
                </div>
              )}
              {/* {nodeDatum.children && (
                <>
                  {nodeDatum.children.length > 0 && (
                  <button className='border border-black mt-2 rounded-b-lg' style={{ width: "100%" }} onClick={() => handleNodeToggle(nodeDatum, toggleNode)}>
                    {nodeDatum.isExpanded ? "Collapse" : "Expand"}
                  </button>
                )}
                  {nodeDatum.children.map((childNode, index) => (
                    <div key={index}>
                      {childNode.hasData && (
                        <button className='border border-black bg-white' style={{ width: "100%" }} onClick={() => handleNodeToggle(childNode, toggleNode)}>
                          {childNode.isExpanded ? "Collapse" : "Expand"}
                        </button>
                      )}
                    </div>
                  ))}
                </>
              )} */}
              {nodeDatum.children && nodeDatum.children.length > 0 ? (
                <>
                  <button className='border border-black mt-2 rounded-b-lg' style={{ width: "100%" }} onClick={() => handleNodeToggle(nodeDatum, toggleNode)}>
                    {nodeDatum.isExpanded ? "Collapse" : "Expand"}
                  </button>
                  {nodeDatum.children.map((childNode, index) => (
                    <div key={index}>
                      {childNode.hasData && (
                        <>
                        {childNode.children && childNode.children.length > 0 ? (
                          <button className='border border-black bg-white' style={{ width: "100%" }} onClick={() => handleNodeToggle(childNode, toggleNode)}>
                            {childNode.isExpanded ? "Collapse" : "Expand"}
                          </button>
                        ) : (
                          <div className="text-center mt-2 text-gray-500">No Downline</div>
                        )}
                      </>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <div className="border border-black rounded-b-lg text-center mt-2 text-gray-500">No Downline</div>
              )}
            </div>
          </div>
        </foreignObject>
      </g>
    );
  };


  const createNestedDataNodes = async (data, parentId, level) => {
    const nestedDataNodes = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (typeof value === "object") {
          const nestedNodes = await createNestedDataNodes(value, parentId, level + 1);
          nestedDataNodes.push(...nestedNodes);
        } else {
          nestedDataNodes.push({
            name: `${key}: ${value}`,
            id: parentId + nestedDataNodes.length + 1,
            pid: parentId,
            title: `Level ${level}`,
            img: imagesview,
            level1: level,
          });
        }
      }
    }
    return nestedDataNodes;
  };


  const nodeSize = { x: 200, y: 200 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -0.5 * nodeSize.x,
    y: -0.5 * nodeSize.y
  };


  const createOrgChartNode = (item, level) => {
    if (level > 15) return null;

    return {
      name: item.name,
      title: `Level ${level}`,
      img: item.img,
      isExpanded: level < 15 && (clickedUsers[item.name]?.isExpanded || false), // Expand only if level is less than 8
      hasData: item.hasData,
      details: details[item.name],
      children: level < 15 && clickedUsers[item.name]?.children?.map((childItem) =>
        createOrgChartNode(childItem, level + 1)
      ).filter(Boolean),
      level: level
    };
  };


  const createOrgChart = () => {
    const orgChartNodes = downline.map((item) => createOrgChartNode(item, 1, details));
    return {
      name: profile.Username,
      children: orgChartNodes,
    };
  };

  const orgChartJson = createOrgChart();

  const handleNodeToggle = async (nodeDatum, toggleNode) => {
    const { name, id, level, pid } = nodeDatum;
    const updatedClickedUsers = { ...clickedUsers };
    const updatedNode = updatedClickedUsers[name];

    if (updatedNode) {
      updatedNode.isExpanded = !updatedNode.isExpanded;

      try {
        if (updatedNode.isExpanded && !updatedNode.dataFetched) {
          const childrenNodes = await View_ActiveLevel(name, id, level);

          setClickedUsers((prevClickedUsers) => ({
            ...prevClickedUsers,
            [name]: {
              ...prevClickedUsers[name],
              children: childrenNodes,
              dataFetched: true,
            },
          }));

          if (!updatedNode.details) {
            const detailData = await View_Details(name, id);
            setClickedUsers((prevClickedUsers) => ({
              ...prevClickedUsers,
              [name]: {
                ...prevClickedUsers[name],
                details: detailData,
              },
            }));
          }

          const parentNode = updatedClickedUsers[pid];
          if (parentNode && !parentNode.isExpanded && !parentNode.dataFetched) {
            const parentDetailData = await View_Details(parentNode.name, pid);
            setClickedUsers((prevClickedUsers) => ({
              ...prevClickedUsers,
              [parentNode.name]: {
                ...prevClickedUsers[parentNode.name],
                details: parentDetailData,
                dataFetched: true,
              },
            }));
          }
        }
      } catch (error) {
        console.log("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    }

    toggleNode(nodeDatum);
  };


  return (
    <div style={containerStyles} ref={containerRef}>
      {/* {loading && <Spin size="large" />} */}
      <Tree

        depthFactor={300}
        separation={{ nonSiblings: 2, siblings: 2 }}
        data={orgChartJson}
        translate={translate}
        nodeSize={nodeSize}
        renderCustomNodeElement={(rd3tProps) =>
          renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
        }
        orientation="vertical"
      // onClick={handleNodeToggle} 
      />
    </div>

  );
};

export default MatrixTwocopy;
