import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import ActiveBotTable from "../Components/ActiveBotTable";
import { IoGrid } from "react-icons/io5";
import { FaList } from "react-icons/fa";

const useOutsideClick = (ref, setShowOptions) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setShowOptions]);
};

const ActiveBot = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showOptions1, setShowOptions1] = useState(false);
  const [filterData, setfilterData] = useState([]);
  const [filterbotData, setfilterBotData] = useState([]);
  const [typedata, setTypeData] = useState([]);
  const [apiname, setApiname] = useState([]);
  const [exchange, setexchange] = useState([]);
  const [botname, setbotname] = useState([]);
  const [filter, setFilter] = useState("FUTURES");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  
  console.log("filter ==>>", filter);

   const { handleGridClick, handleListClick } = props.data;


  const [selectedBotType, setSelectedBotType] = useState("FUTURES");

  useEffect(() => {
    setSelectedOptions1(apiname);
  }, [apiname]);

  useEffect(() => {
    setSelectedOptions(filterData);
  }, [filterData]);

  const handleFilter = (filterType) => {
    const jwt = localStorage.getItem("jwt");
    let postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      page_name: "ActiveBot",
      filter_type: filterType,
    };

    if (filterType === "API_Name") {
      postData.filter_type = "API_Name";
    } else if (filterType === "Exchange") {
      postData.filter_type = "Exchange";
    } else if (filterType === "Symbol") {
      postData.filter_type = "Symbol";
    } else if (filterType === "VarBotID") {
      postData.filter_type = "VarBotID";
    } else if (filterType === "Exchange_Type") {
      postData.filter_type = "Exchange_Type";
    } else if (filterType === "Type") {
      postData.filter_type = "Type";
    } else if (filterType === "BotName") {
      postData.filter_type = "BotName";
    } else {
    }

    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_FIL + "filter",
      data: postData,
    })
      .then((res) => {

        console.log("res.data.Success" , res.data.Success);

        if (filterType === "Symbol") {
          setfilterData(res?.data.Success || []);
          const updatedSelectedOptions = selectedOptions.filter((option) =>
            (res?.data.Success || []).includes(option)
          );
          setSelectedOptions(updatedSelectedOptions);
        } else if (filterType === "BotName") {
          setfilterBotData(res?.data.Success || []);
        } else if (filterType === "Exchange_Type") {
          // setFilter(res.data.Success);
          setFilter([res.data.Success[0]]);
        } else if (filterType === "Type") {
          setTypeData(res.data.Success);
        } else if (filterType === "API_Name") {
          setApiname(res.data.Success);
          const updatedSelectedOptions = selectedOptions1.filter((option) =>
            (res?.data.Success || []).includes(option)
          );
          setSelectedOptions1(updatedSelectedOptions);
        } else if (filterType === "VarBotID") {
          setbotname(res.data.Success);
        } else if (filterType === "Exchange") {
          setexchange(res.data.Success);
        }
        toast(res?.data?.Success);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleFilter("Symbol");
    handleFilter("Exchange_Type");
    handleFilter("BotName");
    handleFilter("VarBotID");
    handleFilter("Exchange");
    handleFilter("API_Name");
    handleFilter("Type");
    // setFilter(["SPOT", "FUTURES"]);
  }, []);

  const [activeButton, setActiveButton] = useState("FUTURES");
  // const handleFilterClick = (exchangeType) => {
  //   if (exchangeType === "ALL") {
  //     setFilter(["SPOT", "FUTURES"]);
  //     setActiveButton("ALL");
  //   } else {
  //     setFilter([exchangeType]);
  //     setActiveButton(exchangeType);
  //   }
  // };

  // const handleFilterClick = (filterType) => {
  //   setFilter(filterType);
  // };

  const handleFilterByBotType = (botType) => {
    setSelectedBotType(botType);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const isMobile = useMediaQuery({ maxWidth: 1050 });

  const pageSize = isMobile ? 10 : 22;

  const pageSizeOptions = [10, 20, 30, 50, 100];

  const item = [
    {
      name: "Future",
      click: "FUTURES",
    },
    {
      name: "Spot",
      click: "SPOT",
    },
    
  ];

 
 const handleFilterClick = (exchangeType) => {
   setFilter([exchangeType]);
   setActiveButton(exchangeType);
  //  if (exchangeType === "FUTURES") {
  //    setFilter("FUTURES");
  //    setActiveButton("FUTURES");
  //  } else {
  //    setFilter([exchangeType]);
  //    setActiveButton(exchangeType);
  //  }
 };

useEffect(() => {
  handleFilterClick("FUTURES"); // Set "Futures" as the initial filter type
}, []);

  return (
    <>
      <div>
        <div className="container px-6 my-8 flex justify-between">
          <div className=" flex gap-8">
            <div
              className={`active-tab border-gradientbottom text-black font-semibold`}
              onClick={handleGridClick}
              style={{ cursor: "pointer" }}
            >
              ACTIVE BOT
            </div>
            <div onClick={handleListClick} style={{ cursor: "pointer" }}>
              REPORT
            </div>
          </div>

          <div className="flex gap-8">
            {item.map((item) => {
              return (
                <button
                  onClick={() => handleFilterClick(item?.click)}
                  className={`border-4 shadow-xl py-1 px-8  ${
                    activeButton === item?.click
                      ? "border-gradient1 border-4 border-solid rounded-full"
                      : " border-gray-200 rounded-xl"
                  }`}
                >
                  {item?.name}
                </button>
              );
            })}
          </div>
        </div>

        <ActiveBotTable
          data={[
            filter,
            exchange,
            typedata,
            apiname,
            botname,
            filterbotData,
            filterData,
          ]}
          type={filter}
          coin={selectedOptions}
          selectedBotID={selectedOptions1}
        />
      </div>
    </>
  );
};

export default ActiveBot;
